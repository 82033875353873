import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import cx from 'classnames';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';
import RetryableErrorMessage from 'common/Message/RetryableError';
import './styles.scss';

function SummaryCard({ data, error, isLoading, onSelect, title, withBars }) {
  const [lookbackDays, setLookbackDays] = useState(30);
  const DEMO_NAME_MAX_CHARS = 30;
  const barColors = ['top-first-item', 'top-second-item', 'top-third-item'];
  const labelColors = [
    'top-first-label',
    'top-second-label',
    'top-third-label',
  ];

  const handleOnSelect = (e) => {
    const selectedLookbackDays = e.target.value;
    onSelect(e.target.value);
    setLookbackDays(selectedLookbackDays);
  };

  const getDemoLabel = (demoName = '') => {
    if (demoName.length > DEMO_NAME_MAX_CHARS) {
      return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{demoName}</Tooltip>}>
          <span>{demoName.substring(0, DEMO_NAME_MAX_CHARS)}...</span>
        </OverlayTrigger>
      );
    }

    return <span>{demoName}</span>;
  };

  const reload = () => onSelect(lookbackDays);

  return (
    <Card style={{ width: '22rem' }}>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between">
          <Card.Title
            className="pr-2 pl-2"
            style={{ fontSize: '14px', fontWeight: '600' }}
          >
            {title}
          </Card.Title>
          <Form.Group>
            <Form.Control
              as="select"
              className="lookback-select"
              name="role"
              type="role"
              onChange={handleOnSelect}
            >
              <option value={30}>Last 30 days</option>
              <option value={60}>Last 60 days</option>
              <option value={90}>Last 90 days</option>
            </Form.Control>
          </Form.Group>
        </div>
        <hr className="row-divider" />
        {isLoading && (
          <div className="d-flex justify-content-center mt-4  ">
            <Spinner animation="border" />
          </div>
        )}
        <div className="d-flex flex-column">
          {!isLoading && error && (
            <RetryableErrorMessage
              message={`Something went wrong when fetching the ${title}`}
              onReload={reload}
            />
          )}
          {!isLoading && !error &&
            data &&
            data.map((item, index, arr) => (
              <div key={item.id}>
                <div className="mt-2 mb-2 p-2 row-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pr-2">{getDemoLabel(item.label)}</div>
                    <div>
                      <span
                        className={withBars ? labelColors[index] : 'big-number'}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                  {withBars && (
                    <ProgressBar
                      now={item.percentage}
                      className={cx(barColors[index], 'progress-indicator')}
                    />
                  )}
                </div>
                {index < arr.length - 1 && <hr className="row-divider" />}
              </div>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
}

SummaryCard.propTypes = {
  data: arrayOf(shape({})).isRequired,
  error: bool.isRequired,
  isLoading: bool.isRequired,
  onSelect: func.isRequired,
  title: string.isRequired,
  withBars: bool,
};

SummaryCard.defaultProps = {
  withBars: false,
};

export default SummaryCard;
