import { debounce } from 'lodash';
import http from 'utils/Http';

const buildUrl = (suffix) => `api/v1/guided_tours${suffix ? `${suffix}` : ''}`;

class GuidedTourCRUDService {
  constructor() {
    this.debouncedEdit = debounce(this.edit, 1000);
  }

  static mapGuidedTourTo = (guidedTour) => {
    return {
      id: guidedTour.tourId,
      tour: guidedTour.tour,
    };
  };

  static mapGuidedTourFrom = (serverGuidedTour) => {
    return {
      tourId: serverGuidedTour.id,
      tour: serverGuidedTour.props.tour,
    };
  };

  edit = async (guidedTour, responseHandlers) => {
    try {
      const { data: updatedTour } = await http.put({
        url: buildUrl(`/${guidedTour.tourId}`),
        body: GuidedTourCRUDService.mapGuidedTourTo(guidedTour),
      });
      responseHandlers.onSuccess(updatedTour);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  byId = async (guidedTourId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/${guidedTourId}`),
      });

      const guidedTour = GuidedTourCRUDService.mapGuidedTourFrom(response);
      responseHandlers.onSuccess(guidedTour);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new GuidedTourCRUDService();
