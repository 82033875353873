const Texts = {
  total: 'All Demos',
  IN_USE: 'In Use',
  COMPLETE: 'Complete',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
  updatedTitle: 'Demo updated',
  demoScript: 'Demo Script',
  updatedDescription: (item, action) =>
    `Demo "${item.name}" has been ${action || 'updated'}.`,
};

export default Texts;
