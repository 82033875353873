import { useLayoutEffect } from 'react';

function useWindowResize(cb, deps) {
  useLayoutEffect(() => {
    function listener() {
      cb(window.innerWidth, window.innerHeight);
    }
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, deps);
}

export default useWindowResize;
