import React from 'react';
import appConfig from 'app/Config';
import './styles.scss';
import { EVENTS } from '../MessagePublisher';
import EventSnippets from './EventSnippets';

const iframe = (domain) => `<body>
  <iframe
    src="${domain}/embed?app_id=<app_id_value>&client_id=<client_id_value>&client_name=<client_name_value>"
    height="500"
    width="500"
    title="Demo Library"
  />
</body>
`;
const env = appConfig.get('env');

function Docs() {
  const domain =
    env === 'prod'
      ? 'https://embed.summasoft.dev'
      : 'https://qa-embed.summasoft.dev';

  return (
    <div>
      <div className="m-2">
        <h2> Demo Iframe Docs </h2>
      </div>
      <div className="mx-4">
        <h4>Parameters:</h4>
        <ul>
          <li>app_id: provided by our team</li>
          <li>client_id: comes from salesforce client</li>
          <li>clien_name: comes from salesforce client</li>
        </ul>
        <br />
        <h4>Iframe integration e.g.</h4>
        <p>replace {'"<>"'} with real values </p>
        <pre className="p-2">
          <code>{iframe(domain)}</code>
        </pre>
      </div>
      <div className="mx-4">
        <h4>Events:</h4>
        <h5>Handling The Events:</h5>
        <p>
          The iframe uses the <code>window.postMessage()</code> API to
          communicate to the parent window. These events can be handled with:
        </p>
        <pre className="p-2">
          <code>{EventSnippets.eventListener}</code>
        </pre>
        <p>
          The following events are published from the iframe, and their name can
          be accessed at <code>event.data.message</code> (property always
          included):
        </p>
        <div>
          <h5>{EVENTS.INIT_ERROR}</h5>
          <p>
            Posted when the iframe can not be initialized due to a configuration
            error or an unexpected error.
          </p>
          <pre className="p-2">
            <code>{EventSnippets.initError}</code>
          </pre>
        </div>
        <div>
          <h5>{EVENTS.PUBLISHED_DEMO_SUCCESS}</h5>
          <p>Posted when a demo has been published successfully.</p>
          <pre className="p-2">
            <code>{EventSnippets.publishedDemo}</code>
          </pre>
        </div>
        <div>
          <h5>Handling Example:</h5>
          <pre className="p-2">
            <code>{EventSnippets.handleExample(domain)}</code>
          </pre>
        </div>
      </div>
    </div>
  );
}

export default Docs;
