import http from 'utils/Http';

import routerMap from 'app/RouterMap';
import safeInvoke from 'utils/safeInvoke';

class DataViewsSerrvice {
  static mapDemosFrom = (demos = []) => {
    return demos.map((option) => {
      return {
        id: option.id,
        name: option.name,
        script_url: option.script_url,
        demo_unique_url:
          option.demo_unique_url &&
          routerMap.experience.getPath(option.demo_unique_url),
        client_id: option.client_id,
        hash_id: option.demo_unique_url,
        shared: option.shared,
      };
    });
  };

  demosSharedWithClient = async (clientId, qs, responseHandlers) => {
    const queryParams = qs || '';
    try {
      const { data: response } = await http.get({
        url: `data/demos_with_client_shared/${clientId}${queryParams}`,
      });

      const mappedDemos = DataViewsSerrvice.mapDemosFrom(response.data);

      safeInvoke(responseHandlers.onSuccess, {
        data: mappedDemos,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      safeInvoke(responseHandlers.onError, error);
    }
  };
}

export default new DataViewsSerrvice();
