import { get } from 'lodash';
import http from 'utils/Http';
import session from 'utils/Session';
import Texts from './texts';

const Authenticate = async (loginFormType, formikBag, { onSuccess }) => {
  try {
    const { data: loginResponse } = await http.post({
      url: 'login/',
      body: loginFormType,
    });

    session.save(get(loginResponse, 'token', {}));
    session.setUser(get(loginResponse, 'user', {}));
    http.setToken(get(loginResponse, 'token.token', ''));

    const { data: accessResponse } = await http.get({
      url: 'app_access',
    });

    http.registerLoginInterceptors();
    session.setAccess(accessResponse);
    onSuccess({ login: loginResponse, access: accessResponse });
  } catch (err) {
    if (get(err, 'response.status') === 401) {
      formikBag.setErrors({
        invalidCredentials: Texts.invalidCredentials,
      });
    } else {
      formikBag.setErrors({
        internalServerError: Texts.internalServerError,
      });
    }
  } finally {
    formikBag.setSubmitting(false);
  }
};

export default Authenticate;
