const Texts = {
  usernameCol: 'Username',
  emailCol: 'Email',
  roleCol: 'Role',
  dateCol: 'Date Created',
  searchPlaceholder: 'Search for users...',
  createUserTrigger: 'Add User',
  updatedTitle: 'User updated',
  updatedDescription: (item) => `User "${item.username}" has been updated.`,
  createdTitle: 'User created',
  createdDescription: (item) => `User "${item.username}" has been created.`,
  deleteFailedTitle: "Couldn't process the remove operation",
  deleteFailedDescription: 'Something went wrong',
  deleteTitle: 'User deleted',
  deleteDescription: 'User has been successfully removed from the application ',
  deleteUserTitle: 'Delete User',
  deleteUserDescription: 'The user will be deleted',
  admin: 'Admin',
  user: 'User',
  third_party_app: 'Third Party App',
};

export default Texts;
