import React from 'react';
import { oneOfType, node, string } from 'prop-types';
import { ChevronDown, PersonCircle } from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash';
import session from 'utils/Session';
import PrimitiveButton from 'common/Buttons/Primitive';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import routerMap from 'app/RouterMap';

import Texts from './texts';
import './styles.scss';

function Header({ page, username }) {
  const logout = () => {
    session.clear();
  };

  const history = useHistory();

  const goToUserProfile = () => {
    history.push(routerMap.userProfile.getPath());
  };

  return (
    <div className="d-flex justify-content-between align-items-center py-3">
      <div className="page-info-header-wrapper">
        {isString ? <h3>{page}</h3> : page}
      </div>
      <FloatingMenuState>
        {({ show, open }) => (
          <>
            <PrimitiveButton
              className="user-info-header-wrapper d-flex justify-content-between align-items-center pr-2 "
              onClick={open}
            >
              <div className="d-flex align-items-center p-2">
                <PersonCircle className="user-avatar mr-4" />
                {username}
              </div>
              <ChevronDown className="user-menu-arrow" />
            </PrimitiveButton>
            {show && (
              <FloatingMenu id="demo-floating-menu">
                <Card className="py-1">
                  <FloatinMenuOption onClick={goToUserProfile}>
                    {Texts.myProfile}
                  </FloatinMenuOption>
                  <FloatinMenuOption onClick={logout}>
                    {Texts.logout}
                  </FloatinMenuOption>
                </Card>
              </FloatingMenu>
            )}
          </>
        )}
      </FloatingMenuState>
    </div>
  );
}

Header.propTypes = {
  page: oneOfType([string, node]).isRequired,
  username: string.isRequired,
};

export default Header;
