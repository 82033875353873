import { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import reportsService from 'app/Services/ReportsService';
import useReportsState from './useReportsState';

function usePageviews(clientId, demoId, startDate, endDate) {
  const demosPageviews = useReportsState(reportsService.demosPageviews);

  const shouldShowByFilters = useMemo(() => {
    const clientAndDemoSelected = clientId && demoId;

    return clientAndDemoSelected;
  }, [clientId, demoId]);

  const shouldShowByData = useMemo(() => {
    const pageviews = get(demosPageviews, 'metrics.pageviews', []);
    return pageviews.length > 0;
  }, [demosPageviews.metrics]);

  useEffect(() => {
    if (shouldShowByFilters) {
      demosPageviews.filter(clientId, demoId, startDate, endDate);
    }
  }, [shouldShowByFilters, clientId, demoId, startDate, endDate]);

  const load = () => {
    demosPageviews.load(clientId, demoId, startDate, endDate);
  };

  return {
    data: demosPageviews.metrics,
    isLoading: demosPageviews.isLoading,
    error: demosPageviews.error,
    show: shouldShowByFilters && shouldShowByData,
    load,
  };
}

export default usePageviews;
