import React from 'react';
import { DashCircle } from 'react-bootstrap-icons';
import { string, func, shape, number } from 'prop-types';
import cx from 'classnames';

import { Draggable } from 'react-beautiful-dnd';
import PrimitiveButton from 'common/Buttons/Primitive';
import Texts from './texts';
import './styles.scss';

const itemsClasses = 'step-option cursor-pointer px-2 py-3';
const inlineStepDecor = 'd-inline-block mr-2 icon-option';
const inlineStepNumber = cx(inlineStepDecor, 'step-number');

function DraggableStep({
  activeStep,
  draggableId,
  index,
  value,
  onSelected,
  onDelete,
}) {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="hotspot-option"
        >
          <PrimitiveButton
            key={draggableId}
            value={value}
            className={cx(
              itemsClasses,
              'no-cursor',
              activeStep === value.id && 'active',
            )}
            onClick={onSelected}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-description">
                <div className={inlineStepNumber}>{index + 1}</div>
                <span>{value.description || Texts.noGuide}</span>
              </div>
              <div>
                <PrimitiveButton onClick={onDelete} value={value}>
                  <DashCircle className="delete-step" />
                </PrimitiveButton>
              </div>
            </div>
          </PrimitiveButton>
        </div>
      )}
    </Draggable>
  );
}

DraggableStep.propTypes = {
  activeStep: string.isRequired,
  draggableId: string.isRequired,
  index: number.isRequired,
  onDelete: func.isRequired,
  onSelected: func.isRequired,
  value: shape({}).isRequired,
};

DraggableStep.defaultProps = {};

export default DraggableStep;
