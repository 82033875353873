import { get, omit } from 'lodash';

import uuid from 'utils/uuid';
import { add, move } from 'utils/arrays';

import { PLACEMENT, HOTSPOT_EVENTS, DEFAULT_FORMAT } from '../constants';

class TourMap {
  constructor(flows, flowIds, steps, hotspots, format) {
    const headFlowId = get(flowIds, '[0]', null);
    const headStepId = get(flows, `${headFlowId}.stepIds[0]`, null);
    const step = get(steps, headStepId, {});
    const headHotspotId = step.hotspotId || null;

    this._format = format;
    this._hotspots = hotspots;
    this._flows = flows;
    this._flowIds = flowIds;
    this._steps = steps;
    this._head = {
      flowId: headFlowId,
      stepId: headStepId,
      hotspotId: headHotspotId,
    };
  }

  /**
   * @returns {
   *  fonttype: string,
   *  fontcolor: string,
   *  fontsize: string,
   *  bgcolor: string,
   * }
   */
  getFormat = () => this._format;

  getFlows = () => this._flows;

  getFlowIds = () => this._flowIds;

  getSteps = () => this._steps;

  getHotspots = () => this._hotspots;

  getHead = () => this._head;

  getCurrentFlow = () => get(this._flows, this._head.flowId, {});

  getCurrentStep = () => get(this._steps, this._head.stepId, {});

  getCurrentHotspot = () => {
    const step = this.getCurrentStep();
    return get(this._hotspots, step.hotspotId, {});
  };

  getHeadItems = () => {
    const flow = this.getCurrentFlow();
    const step = this.getCurrentStep();
    const hotspot = this.getCurrentHotspot();

    return {
      flow,
      step,
      hotspot,
    };
  };

  changeHead = (newHead = {}) => {
    const stepId = newHead.stepId;
    const step = this._steps[stepId] || {};
    const hotspotId = step.hotspotId || null;
    this._head = {
      flowId: newHead.flowId,
      stepId,
      hotspotId,
    };

    return this.getHeadItems();
  };

  changeHeadByStep = (step) => {
    const hotspotId = step.hotspotId;
    const newHeadItems = this.changeHead({
      flowId: step.flowId,
      stepId: step.id,
      hotspotId,
    });

    return newHeadItems;
  };

  changeHeadByFlow = ({ id }) => {
    const stepId = this._flows[id].stepIds[0];
    const { hotspotId } = this._steps[stepId] || {};
    const newHeadItems = this.changeHead({
      flowId: id,
      stepId,
      hotspotId,
    });
    return newHeadItems;
  };

  addFlow = (newFlow) => {
    const generatedId = uuid();
    const nextHeadStep = get(newFlow, 'stepIds[0]', null);
    const flow = {
      name: newFlow.name,
      id: generatedId,
      stepIds: [],
      intro: null,
      conclusion: null,
      arrowNavigation: false,
    };
    this._flows = {
      ...this._flows,
      [generatedId]: flow,
    };
    this._flowIds = add(this._flowIds, generatedId);
    this.changeHead({ flowId: generatedId, stepId: nextHeadStep });

    return flow;
  };

  updateFlow = (updatedFlow) => {
    this._flows[updatedFlow.id] = updatedFlow;
    return updatedFlow;
  };

  deleteFlow = (flowToDelete) => {
    const updatedFlowIds = this._flowIds.filter(
      (flowId) => flowId !== flowToDelete.id,
    );
    const updatedFlows = omit(this._flows, flowToDelete.id);
    if (flowToDelete.id === get(this._head, 'flowId')) {
      const nextHeadFlowId = updatedFlowIds[0];
      this.changeHeadByFlow({ id: nextHeadFlowId });
    }
    this._flows = updatedFlows;
    this._flowIds = updatedFlowIds;
  };

  addHotspot = (hotspot) => {
    const step = this._steps[this._head.stepId];
    if (step.hotspotId !== null) return this._hotspots[step.hotspotId];
    const { x, y, description, width, height } = hotspot;
    const id = uuid();
    const newHotspot = {
      id,
      stepId: step.id,
      x,
      y,
      width,
      height,
      description,
      event: HOTSPOT_EVENTS.CLICK,
      placement: PLACEMENT.TOP,
      header: null,
      footer: null,
    };
    const updatedStep = { ...step, hotspotId: id };
    this._steps[step.id] = updatedStep;
    this._hotspots[id] = newHotspot;
    return newHotspot;
  };

  updateHotspot = (hotspot) => {
    const updatedStep = {
      ...this._steps[hotspot.stepId],
      description: hotspot.description,
    };
    this._steps[hotspot.stepId] = updatedStep;
    this._hotspots[hotspot.id] = hotspot;
    return hotspot;
  };

  updateHotspotData = (hotspot) => {
    const currentHotspot = this._hotspots[hotspot.id];
    const updatedStep = {
      ...this._steps[hotspot.stepId],
      description: hotspot.description,
    };
    this._steps[hotspot.stepId] = updatedStep;
    this._hotspots[hotspot.id] = {
      ...currentHotspot,
      description: hotspot.description,
      placement: hotspot.placement,
      event: hotspot.event,
      transitionTime: parseInt(hotspot.transitionTime, 10),
      header: hotspot.header && hotspot.header.trim(),
      footer: hotspot.footer && hotspot.footer.trim(),
    };
    return this._hotspots[hotspot.id];
  };

  addStep = (step) => {
    const { imageUrl } = step;
    const { flowId } = this._head;
    const matchFlow = this._flows[flowId];
    const id = uuid();
    const newStep = {
      id,
      flowId,
      imageUrl,
      hotspotId: null,
    };
    const updatedFlow = {
      ...matchFlow,
      stepIds: add(matchFlow.stepIds, newStep.id),
    };
    this._steps[newStep.id] = newStep;
    this._flows[flowId] = updatedFlow;
    this.changeHead({ flowId, stepId: newStep.id });
    return newStep;
  };

  updateStep = (step) => {
    this._steps[step.id] = step;
    return step;
  };

  deleteStep = (step) => {
    const stepFlow = this._flows[step.flowId] || {};
    const { stepIds = [] } = stepFlow;
    const updatedFlowSteps = stepIds.filter((stepId) => stepId !== step.id);

    const filteredSteps = omit(this._steps, step.id);
    const filteredHotspots = omit(this._hotspots, step.hotspotId);

    const nextHeadStepId =
      step.id === this._head.stepId ? stepIds[0] : this._head.stepId;

    const updatedFlow = { ...stepFlow, stepIds: updatedFlowSteps };

    this._hotspots = filteredHotspots;
    this._steps = filteredSteps;
    this._flows[updatedFlow.id] = updatedFlow;
    this.changeHead({ flowId: updatedFlow.id, stepId: nextHeadStepId });

    return this._steps;
  };

  rearrangeStep = (stepId, sourceIndex, newIndex) => {
    const { flowId } = this._head;
    const currentFlow = this._flows[flowId];
    const stepsIds = currentFlow.stepIds;

    const updatedFlow = {
      ...currentFlow,
      stepIds: move(stepsIds, stepId, sourceIndex, newIndex),
    };

    this._flows[flowId] = updatedFlow;
  };

  updateFormat = (format) => {
    this._format = format;
  };

  mergeHotspot = (hotspot) => {
    const currentHotspot = this._hotspots[hotspot.id];

    this._hotspots[hotspot.id] = {
      ...currentHotspot,
      ...hotspot,
    };

    return this._hotspots[hotspot.id];
  };
}

TourMap.toTourMap = (serverTour) => {
  const flows = get(serverTour, 'flows', {});
  const flowIds = get(serverTour, 'flowIds', []);
  const steps = get(serverTour, 'steps', {});
  const hotspots = get(serverTour, 'hotspots', {});
  const format = get(serverTour, 'format', DEFAULT_FORMAT);

  const newTourMap = new TourMap(flows, flowIds, steps, hotspots, format);

  return newTourMap;
};

export default TourMap;
