import React from 'react';
import { func, string } from 'prop-types';
import cx from 'classnames';
import BaseImage from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import useToggleState from 'utils/useToggleState';
import './styles.scss';

function Image({ className, src, onLoad, ...rest }, ref) {
  const loadedToggle = useToggleState(false, {
    listeners: { onToggle: onLoad },
  });

  return (
    <div
      ref={ref}
      className={cx(
        'image-with-loader-container',
        !loadedToggle.isActive &&
          'd-flex align-items-center justify-content-center',
        className,
      )}
    >
      {!loadedToggle.isActive && (
        <Spinner animation="border" variant="primary" />
      )}

      <BaseImage
        {...rest}
        onLoad={loadedToggle.turnOn}
        className={cx(
          'image-with-loader',
          loadedToggle.isActive ? 'fade-in' : 'hidden',
        )}
        src={src}
      />
    </div>
  );
}

Image.propTypes = {
  className: string,
  onLoad: func,
  src: string.isRequired,
};

Image.defaultProps = {
  className: null,
  onLoad: null,
};

export default React.forwardRef(Image);
