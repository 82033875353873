/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import { useHistory } from 'react-router-dom';
import { ThreeDots as ThreeDotsIcon } from 'react-bootstrap-icons';
import { get } from 'lodash';
import { to_MMDDYYYYHHMMA } from 'utils/dates';
import useModalState from 'utils/useModalState';
import Shell from 'app/Shells/Management';
import routerMap from 'app/RouterMap';
import DataTable from 'common/DataTable';
import SortableHeader from 'common/DataTable/SortableHeader';
import SearchInput from 'common/Inputs/Search';
import { useToasts } from 'common/ActionToast';
import OverlayLink from 'common/Overlay/OverlayLink';
import DemoWizard from 'app/Forms/DemoWizard';
import PublishDemoForm from 'app/Forms/PublishDemo';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
// eslint-disable-next-line camelcase
import session from 'utils/Session';
import {
  canBeArchived,
  canBeUnarchived,
  canBePublished,
  canBeCompleted,
} from 'utils/business/demo';
import Texts from './texts';
import './styles.scss';
import useDemoLibraryState from './useDemoLibraryState';

function LibraryView() {
  const demoModal = useModalState();
  const publishDemoModal = useModalState();
  const toasts = useToasts();
  const history = useHistory();
  const [activeFilterKey, setActiveFilterKey] = useState('total');
  const demoLibraryState = useDemoLibraryState({
    listeners: {
      onSave: (resultDemo, isEdit, action) => {
        if (isEdit) {
          demoModal.close();
          toasts.onAdd(
            resultDemo.id,
            Texts.updatedTitle,
            Texts.updatedDescription(resultDemo, action),
          );
        } else {
          history.push(routerMap.demo.getPath(resultDemo.id));
        }
      },
    },
  });

  const handleFilter = (selectedStatus) => {
    const searchTerm = selectedStatus !== 'total' ? selectedStatus : undefined;
    demoLibraryState.filter({ status: searchTerm });
    setActiveFilterKey(selectedStatus);
  };

  const handleRowClick = (demo) => {
    history.push(routerMap.demo.getPath(demo.id));
  };

  return (
    <Shell page="Demo Library">
      {toasts.elements}
      <DemoWizard
        onCancel={demoModal.close}
        onSubmit={demoLibraryState.addDemo}
        show={demoModal.show}
        demo={demoModal.value}
      />
      {publishDemoModal.show && (
        <PublishDemoForm
          onCancel={publishDemoModal.close}
          onSuccess={publishDemoModal.close}
          onSubmit={demoLibraryState.publish}
          show={publishDemoModal.show}
          demo={publishDemoModal.value}
        />
      )}
      <div id="demo-library">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div>
              <SearchInput
                placeholder="Search for demos, tags, users"
                name="demoSearch"
                onChange={demoLibraryState.search}
                value={demoLibraryState.query.qs}
              />
            </div>
            <div>
              {!demoLibraryState.query.isLoading && (
                <Nav activeKey={activeFilterKey} onSelect={handleFilter}>
                  {Object.entries(
                    get(demoLibraryState.query, 'result.count', {}),
                  ).map(([key, value]) => (
                    <Nav.Item key={key}>
                      <Nav.Link
                        className="library-filter"
                        eventKey={key}
                      >{`${Texts[key]} (${value})`}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              )}
            </div>
          </div>

          <div>
            <Button size="md" onClick={demoModal.open}>
              Add Demo
            </Button>
          </div>
        </div>

        <div>
          <DataTable
            onLimitChange={demoLibraryState.changeLimit}
            onPageChange={demoLibraryState.changePage}
            onReload={demoLibraryState.reload}
            total={get(demoLibraryState, 'query.result.total', 0)}
            page={demoLibraryState.query.page}
            limit={demoLibraryState.query.limit}
            isFetching={demoLibraryState.query.isLoading}
            hasError={demoLibraryState.query.error}
          >
            <thead>
              <tr>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="name"
                  current={demoLibraryState.query.sort}
                >
                  Demo Name
                </SortableHeader>
                <th>Demo Script</th>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="status"
                  current={demoLibraryState.query.sort}
                >
                  Status
                </SortableHeader>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="addedBy"
                  current={demoLibraryState.query.sort}
                >
                  Added By
                </SortableHeader>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="dateAdded"
                  current={demoLibraryState.query.sort}
                >
                  Date Added
                </SortableHeader>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="shares"
                  current={demoLibraryState.query.sort}
                >
                  Shares
                </SortableHeader>
                <SortableHeader
                  onChange={demoLibraryState.changeSort}
                  value="visits"
                  current={demoLibraryState.query.sort}
                >
                  Visits
                </SortableHeader>
                <th />
              </tr>
            </thead>
            {!demoLibraryState.query.isLoading && (
              <tbody>
                {demoLibraryState.allDemos.map((demo) => (
                  <tr key={demo.id}>
                    <td
                      className="bold"
                      onClick={() => handleRowClick(demo)}
                      role="presentation"
                    >
                      <span className="link">{demo.name}</span>
                      {demo.tags.map((tag) => (
                        <Badge
                          key={`${demo.id}-${tag.id}`}
                          className="demo-tag"
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </td>
                    <td>
                      <OverlayLink
                        displayText={Texts.demoScript}
                        key={demo.id}
                        url={demo.script_url}
                      />
                    </td>
                    <td>
                      <span
                        className={`status-indicator status-${demo.status.toLowerCase()}`}
                      />
                      {Texts[demo.status]}
                    </td>
                    <td>{demo.user.username}</td>
                    <td>{to_MMDDYYYYHHMMA(demo.date_added)}</td>
                    <td>{demo.shares}</td>
                    <td>{demo.visits}</td>
                    <td>
                      <FloatingMenuState>
                        {({ show, open }) => (
                          <>
                            <ThreeDotsIcon
                              onClick={open}
                              className="cursor-pointer"
                            />
                            {show && (
                              <FloatingMenu id="demo-floating-menu">
                                <Card className="py-1">
                                  <FloatinMenuOption
                                    onClick={demoModal.open}
                                    value={demo}
                                  >
                                    Edit
                                  </FloatinMenuOption>
                                  {canBePublished(demo) && (
                                    <FloatinMenuOption
                                      onClick={publishDemoModal.open}
                                      value={demo}
                                    >
                                      Share
                                    </FloatinMenuOption>
                                  )}
                                  {session.isAdmin() && canBeCompleted(demo) && (
                                    <FloatinMenuOption
                                      onClick={demoLibraryState.completeDemo}
                                      value={demo.id}
                                    >
                                      Complete
                                    </FloatinMenuOption>
                                  )}
                                  {canBeArchived(demo) && (
                                    <FloatinMenuOption
                                      onClick={demoLibraryState.archiveDemo}
                                      value={demo.id}
                                    >
                                      Archive
                                    </FloatinMenuOption>
                                  )}
                                  {canBeUnarchived(demo) && (
                                    <FloatinMenuOption
                                      onClick={demoLibraryState.unarchivedDemo}
                                      value={demo.id}
                                    >
                                      Unarchive
                                    </FloatinMenuOption>
                                  )}
                                </Card>
                              </FloatingMenu>
                            )}
                          </>
                        )}
                      </FloatingMenuState>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </DataTable>
        </div>
      </div>
    </Shell>
  );
}

export default LibraryView;
