const texts = {
  tourLabel: 'Tour Steps',
  addStepPlaceholder: 'Add a new Step to start the flow.',
  addFlowPlaceholder: 'Add a new Flow to start.',
  errorTitle: 'Sorry, something went wrong',
  errorDescription: "Couldn't save your changes.",
  updatedTitle: 'Saved',
  updatedDescription: 'The changes have been saved.',
  deleteFlowTitle: 'Delete Current Flow',
  deleteFlowDescription: 'The current flow will be deleted.',
};

export default texts;
