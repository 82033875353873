import React from 'react';
import { string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function OverlayLink({ displayText, placement, url }) {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{url}</Tooltip>}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {displayText}
      </a>
    </OverlayTrigger>
  );
}

OverlayLink.propTypes = {
  displayText: string.isRequired,
  placement: string,
  url: string.isRequired,
};

OverlayLink.defaultProps = {
  placement: 'top',
};

export default OverlayLink;
