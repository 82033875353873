import React from 'react';
import { string, func } from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function RetryableErrorMessage({ onReload, message }) {
  return (
    <div className="error d-flex justify-content-center align-items-center">
      <Alert className="text-center" variant="danger">
        <div className="mb-1">{message}</div>
        <Button onClick={onReload}> Try again </Button>
      </Alert>
    </div>
  );
}

RetryableErrorMessage.propTypes = {
  message: string,
  onReload: func.isRequired,
};

RetryableErrorMessage.defaultProps = {
  message: 'Something went wrong while fetching the data',
};

export default RetryableErrorMessage;
