import React from 'react';
import { string } from 'prop-types';
import { BoxArrowRight } from 'react-bootstrap-icons';
import clipboard from 'clipboard-polyfill';

import PrimitiveButton from 'common/Buttons/Primitive';

import './styles.scss';

function ActionableUrl({ label, url }) {
  const openInNewTab = () => {
    window.open(url);
  };

  const copyUrlToClipboard = () => {
    clipboard.writeText(url);
  };

  return (
    <div className="mb-2 d-flex content-justify-between">
      <div className="w-25 d-flex justify-content-end mr-4">
        <span>{`${label}:`}</span>
      </div>
      <div className="mr-4">
        <PrimitiveButton role="button" onClick={openInNewTab}>
          {`${url.substring(0, 25)}...`}
          <BoxArrowRight color="royalblue" />
        </PrimitiveButton>
      </div>
      <div>
        <PrimitiveButton className="link" onClick={copyUrlToClipboard}>
          Copy Link
        </PrimitiveButton>
      </div>
    </div>
  );
}

ActionableUrl.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
};

export default ActionableUrl;
