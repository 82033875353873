/**
 * Import npm dependencies
 */
import axios from 'axios';

import session from 'utils/Session';
import { isFunction } from 'lodash';
import appConfig from 'app/Config';

const API = appConfig.get('domain');

class Http {
  constructor() {
    this._session = session;
    this._axios = axios.create({
      baseURL: API,
      headers: {
        Authorization: `Bearer ${this._session.get('token')}`,
      },
    });
    this._interceptors = {
      response401: async (error) => {
        const refreshToken = this._session.get('refreshToken');
        if (error.response && error.response.status === 401 && refreshToken) {
          try {
            const response = await this._axios.post('refresh_token', {
              refresh_token: refreshToken,
            });
            const responseSession = response.data;

            this._session.save(responseSession);
            this.setToken(responseSession.token);

            return this.axios();
          } catch (err) {
            this._session.clear();

            const lastPage = window.location.pathname + window.location.search;
            this._session.setLastPage(lastPage);

            return null;
          }
        }
        return Promise.reject(error);
      },
    };
  }

  registerLoginInterceptors = () => {
    this._interceptors.requestRefresh = async () => {
      const shouldRefresh = this._session.shouldRefreshToken();

      if (shouldRefresh) {
        try {
          const tokenResponse = await this._axios.post('refresh_token', {
            refresh_token: this._session.get('refreshToken'),
          });
          const responseSession = tokenResponse.data;

          this._session.save(responseSession);
          this.setToken(responseSession.token);

          return null;
        } catch (err) {
          this._session.clear();

          const lastPage = window.location.pathname + window.location.search;
          this._session.setLastPage(lastPage);

          return null;
        }
      }
      return null;
    };
  };

  setToken = (token) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${token}`,
    });
  };

  setHeader = ({ key, value }) => {
    this._axios.defaults.headers[key] = value;
  };

  _template = (method) => ({ url, body, options = {} }) => {
    const httpParams = [];
    const config = {
      ...options,
    };
    httpParams.push(url);
    if (body) {
      httpParams.push(body);
    }
    httpParams.push(config);
    if (
      isFunction(this._interceptors.requestRefresh) &&
      isFunction(this._interceptors.response401)
    ) {
      return this._interceptors
        .requestRefresh()
        .then(() => {
          return this._axios[method](...httpParams).catch(
            this._interceptors.response401,
          );
        })
        .catch(this._interceptors.response401);
    }
    return this._axios[method](...httpParams).catch(
      this._interceptors.response401,
    );
  };

  get = this._template('get');

  post = this._template('post');

  put = this._template('put');

  patch = this._template('patch');

  delete = this._template('delete');
  // ToDo: adds interceptors under demand
  // registerInterceptor = (receptors) => {
  // }
}

const http = new Http();

export default http;
