import http from 'utils/Http';

const buildUrl = (suffix) => `api/v1/filters${suffix ? `${suffix}` : ''}`;

class FiltersService {
  demos = async (clientId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/demos${clientId ? `?client=${clientId}` : ''}`),
      });
      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  clients = async (demoId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/clients${demoId ? `?demo=${demoId}` : ''}`),
      });
      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  tags = async (_, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/tags`),
      });
      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new FiltersService();
