/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from 'react-bootstrap-icons';
import routerMap from 'app/RouterMap';
import { to_DD_MMMM_YYYY } from 'utils/dates';
import PrimitiveButton from 'common/Buttons/Primitive';
import './styles.scss';

function PublishedMetadata({ name, date, author }) {
  const history = useHistory();

  const goBack = () => {
    history.push(routerMap.library.getPath());
  };

  return (
    <div className="d-flex align-items-center">
      <PrimitiveButton onClick={goBack} className="p-1 mr-2">
        <ChevronLeft />
      </PrimitiveButton>
      <div className="publish-metadata">
        <span className="project-name d-block">{name}</span>
        <span className="sub-detail d-block">{`Added by ${author} on ${to_DD_MMMM_YYYY(
          date,
        )}`}</span>
      </div>
    </div>
  );
}

export default PublishedMetadata;
