/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import ProjectsService from 'app/Services/ProjectsService';
import Texts from './texts';
import useSyncSource from './useSyncSource';

function ThirdPartySources({ toasts }) {
  const syncMarvelApp = useSyncSource(ProjectsService.sync, {
    onSuccess: () => {
      toasts.onAdd(
        `marvel-app-refresh${new Date().getTime()}`,
        Texts.marvelAppTitle,
        `${Texts.successfulSync(Texts.marvelAppTitle)}`,
      );
    },
    onError: () => {
      toasts.onAdd(
        `marvel-app-refresh${new Date().getTime()}`,
        Texts.marvelAppTitle,
        `${Texts.errorSync(Texts.marvelAppTitle)}`,
      );
    },
  });

  return (
    <div>
      <div className="d-flex flex-column w-50 card p-4">
        <h5 className="mb-0">{Texts.sourcesTitle}</h5>
        <hr />
        <div>
          <SourceItem
            isSyncing={syncMarvelApp.isSyncing}
            name={Texts.marvelAppTitle}
            onSync={syncMarvelApp.sync}
          />
        </div>
      </div>
    </div>
  );
}

function SourceItem({ name, isSyncing, onSync }) {
  return (
    <div className="d-flex align-items-center justify-content-between mb-2">
      <div>{name}</div>
      <div>
        {isSyncing ? (
          Texts.syncInProgressLabel
        ) : (
          <Button onClick={onSync}>{Texts.syncLabel}</Button>
        )}
      </div>
    </div>
  );
}

SourceItem.propTypes = {
  isSyncing: bool.isRequired,
  name: string.isRequired,
  onSync: func.isRequired,
};

ThirdPartySources.propTypes = {
  toasts: shape({
    onAdd: func.isRequired,
  }).isRequired,
};

export default ThirdPartySources;
