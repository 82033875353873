import { useState, useEffect } from 'react';

import ClientsService from 'app/Services/ClientsService';
import useMutation from 'utils/useMutation';
import useToggleState from 'utils/useToggleState';

function useLoadClient(client) {
  const loaded = useToggleState();
  const [loadedClient, setLoadedClient] = useState(undefined);
  const [error, setError] = useState({
    hasError: false,
    message: undefined,
  });

  const handleError = (message) => {
    loaded.turnOff();
    setError({
      hasError: true,
      message,
    });
  };

  const loadClientMutation = useMutation(ClientsService.fromExternal, {
    onSuccess: (serverClient) => setLoadedClient(serverClient),
    onError: () => {
      loaded.turnOff();
      handleError('Error loading client');
    },
  });

  useEffect(() => {
    if (!client.external_id) {
      handleError('client_id must be provided');
      return;
    }

    if (!client.name) {
      handleError('client_name must be provided');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (loadedClient) {
      loaded.turnOn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedClient]);

  const loadClient = () => loadClientMutation.mutate(client);

  return {
    loadClient,
    client: loadedClient,
    loaded: loaded.isActive,
    error,
  };
}

export default useLoadClient;
