import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { func } from 'prop-types';
import projectsService from 'app/Services/ProjectsService';
import ScreenSearch from 'app/Views/ScreenSearch';
import AddDemoSteps from '../../constants';

import './styles.scss';

function DemoSelection({ next, onDemoSelect }) {
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState(null);

  const nextStep = ({ meta: project }) => {
    onDemoSelect({
      name: project.name,
      click_demo_url: project.prototype_url,
      external_id: project.external_id,
      thumbnail_url: project.thumbnail_url,
      product_type: project.product_type,
    });
    next(AddDemoSteps.DEMO_DETAILS);
  };

  const toScreen = (demo) => {
    return {
      hide: false,
      imageUrl: demo.thumbnail_url,
      name: demo.name,
      product_type: demo.product_type,
      meta: demo,
    };
  };

  useEffect(() => {
    projectsService.getProjectsDropdown({
      onSuccess: (projects) => {
        setData(projects);
        setIsReady(true);
      },
    });
  }, []);

  return (
    <div>
      <Form noValidate>
        <Modal.Body className="demo-selection-modal">
          {!isReady && (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          )}
          {isReady && (
            <ScreenSearch
              elementClassName="demo-selection-screen-list"
              screens={data.map((demo) => toScreen(demo))}
              onSelected={nextStep}
            />
          )}
        </Modal.Body>
      </Form>
    </div>
  );
}

DemoSelection.propTypes = {
  next: func.isRequired,
  onDemoSelect: func.isRequired,
};

export default DemoSelection;
