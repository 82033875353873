import { useState } from 'react';

function useReportsState(service) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [metrics, setMetrics] = useState({});

  const load = (clientId, demoId, startDate, endDate) => {
    setIsLoading(true);
    service(clientId, demoId, startDate, endDate, {
      onSuccess: (data) => {
        setMetrics(data);
        setIsLoading(false);
        setError(false);
      },
      onError: () => {
        setIsLoading(false);
        setError(true);
      },
    });
  };

  const filter = (clientId, demoId, startDate, endDate) => {
    load(clientId, demoId, startDate, endDate);
  };

  return {
    metrics,
    isLoading,
    error,
    load,
    filter,
  };
}

export default useReportsState;
