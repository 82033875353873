import http from 'utils/Http';

import routerMap from 'app/RouterMap';
import { handleFormErrors } from './utils';

const buildUrl = (suffix) =>
  `api/v1/published_demos${suffix ? `${suffix}` : ''}`;

class PublishedDemoCRUD {
  static mapPublishedFrom = (serverPublished) => {
    return {
      id: serverPublished.id,
      client_name: serverPublished.props.client_name,
      experience_type: serverPublished.props.experience_type,
      demo_unique_url: routerMap.experience.getPath(
        serverPublished.props.demo_unique_url,
      ),
      enabled: serverPublished.props.enabled,
    };
  };

  static mapListFrom = (publishedDemos = []) => {
    return publishedDemos.map((option) => {
      return {
        id: option.id,
        experience_type: option.experience_type,
        client_name: option.client_name,
        demo_unique_url: routerMap.experience.getPath(option.demo_unique_url),
        demo: option.demo,
        user: option.user,
        tags: option.tags,
        date_shared: option.date_shared,
        guidedTour: option.guidedTour,
        visits: option.visits,
        pageviews: option.pageviews,
        avg_session_duration: option.avg_session_duration,
        unique_visitors: option.unique_visitors,
        client: option.client,
        enabled: option.enabled,
        hash_id: option.demo_unique_url,
      };
    });
  };

  create = async (publishDemoForm, formikBag, responseHandlers) => {
    try {
      const { data: publishedDemo } = await http.post({
        url: buildUrl(),
        body: publishDemoForm,
      });
      const mappedPublishedDemo = PublishedDemoCRUD.mapPublishedFrom(
        publishedDemo,
      );
      responseHandlers.onSuccess(mappedPublishedDemo);
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  edit = async (publishedDemoForm, formikBag, responseHandlers) => {
    try {
      const { data: updatedPublishedDemo } = await http.put({
        url: buildUrl(`/${publishedDemoForm.id}`),
        body: publishedDemoForm,
      });
      const mappedPublishedDemo = PublishedDemoCRUD.mapPublishedFrom(
        updatedPublishedDemo,
      );
      responseHandlers.onSuccess(mappedPublishedDemo);
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  delete = async (demoId, responseHandlers) => {
    try {
      await http.delete({
        url: buildUrl(`/${demoId}`),
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  allByDemo = async (demoId, query, responseHandlers) => {
    const queryParams = query || '';
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/demo/${demoId}${queryParams}`),
      });

      const mappedDemos = PublishedDemoCRUD.mapListFrom(response.data);

      responseHandlers.onSuccess({
        data: mappedDemos,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  archive = async (demoId, responseHandlers) => {
    try {
      await http.put({
        url: buildUrl(`/archive/${demoId}`),
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  refreshTour = async (publishedDemoId, responseHandlers) => {
    try {
      const { data: publishedDemo } = await http.post({
        url: buildUrl(`/${publishedDemoId}/refresh_tour`),
      });
      responseHandlers.onSuccess(publishedDemo);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  update = async (publishedDemo, responseHandlers) => {
    try {
      const { data: updatedPublishedDemo } = await http.put({
        url: buildUrl(`/${publishedDemo.id}`),
        body: publishedDemo,
      });
      const mappedPublishedDemo = PublishedDemoCRUD.mapPublishedFrom(
        updatedPublishedDemo,
      );
      responseHandlers.onSuccess(mappedPublishedDemo);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new PublishedDemoCRUD();
