import React, { useLayoutEffect, useState } from 'react';
import { any, string, bool } from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import './styles.scss';

export function useToasts() {
  const [toasts, setToasts] = useState([]);

  const onHide = (id) => {
    const toastsList = toasts.slice().filter((item) => item.id === id);
    setToasts(toastsList);
  };

  const onAdd = (id, title, description) => {
    const toastsList = toasts.slice();
    toastsList.push({
      id,
      title,
      description,
    });
    setToasts(toastsList);
  };

  return {
    onAdd,
    elements: (
      <div className="toasts-container">
        {toasts.map((toast) => (
          <ActionToast
            id={toast.id}
            key={toast.id}
            title={toast.title}
            onHide={onHide}
            description={toast.description}
          />
        ))}
      </div>
    ),
  };
}

function ActionToast({ id, title, description, onHide }) {
  const [show, setShow] = useState(false);
  useLayoutEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
      onHide(id);
    }, 5000);
  }, []);

  const handleClose = () => {
    setShow(false);
    onHide();
  };

  return (
    <Toast show={show} onClose={handleClose} animation>
      <Toast.Header>
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{description}</Toast.Body>
    </Toast>
  );
}

ActionToast.propTypes = {
  description: string.isRequired,
  id: any.isRequired,
  onHide: bool.isRequired,
  title: string.isRequired,
};

export default ActionToast;
