const Texts = {
  changePasswordTitle: 'Change Password',
  currentPasswordLabel: 'Current Password',
  newPasswordLabel: 'New Password',
  newPasswordConfirmationLabel: 'New Password Confirmation',
  changePasswordLabel: 'Change Password',
  minPasswordLength: 'Password must be at least 6 characters long',
  passwordMustMatch: 'Password must match',
  requiredError: (name) => `${name} is required`,
};

export default Texts;
