const stylejss = ({ background, color, fontSize, fontFamily }) => `
  .hotspot-preview .popover {
    background-color: transparent;
    font-size: ${fontSize};
    font-family: ${fontFamily};
  }
  .bs-popover-auto[x-placement^=right]>.arrow::after,.bs-popover-right>.arrow::after {
    border-right-color: ${background};
  }
  .bs-popover-auto[x-placement^=left]>.arrow::after, .bs-popover-left>.arrow::after {
    border-left-color: ${background};
  }
  .bs-popover-auto[x-placement^=top]>.arrow::after, .bs-popover-top>.arrow::after {
    border-top-color: ${background};
  }
  .bs-popover-auto[x-placement^=bottom]>.arrow::after, .bs-popover-bottom>.arrow::after {
    border-bottom-color: ${background};
  }
  .hotspot-preview .popover-body {
    color: ${color} !important;
  }
  .hotspot-preview .hotspot-header {
    border-bottom: 1px solid ${color};
  }
  .hotspot-preview .hotspot-footer {
    border-top: 1px solid ${color};
  }`;

export default stylejss;
