import { changeRGBAOpacity } from 'utils/rgba';

const stylejss = ({ background, color }) => {
  const opacity = changeRGBAOpacity(background, 0.7);
  return `
    .hotspot-resizable-container {
      border: solid 1px ${background};
      background: ${opacity};
    }
    .hotspot-resizable-container .hotspot-edit-button {
      color: ${color};
      background-color: ${background};
    }
  `;
};
export default stylejss;
