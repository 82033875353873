import { bool, shape, string } from 'prop-types';

const Screen = shape({
  hide: bool,
  imageUrl: string,
  name: string,
  meta: shape({}),
});

export default Screen;
