import { useState, useEffect } from 'react';

function useFilterLoader(autoLoad, service) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);

  const loadOptions = () => {
    setIsLoading(true);
    service(undefined, {
      onSuccess: (data) => {
        setOptions(data);
        setIsLoading(false);
        setError(false);
      },
      onError: () => {
        setIsLoading(false);
        setError(true);
      },
    });
  };

  useEffect(() => {
    if (autoLoad) {
      loadOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    options,
    isLoading,
    error,
  };
}

export default useFilterLoader;
