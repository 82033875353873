const Texts = {
  authCheckLabel: 'Checking...',
  loginButton: 'Login',
  keepMeLabel: 'Keep me logged in',
  passwordLabel: 'Password',
  usernameLabel: 'Username',
  passwordError: 'Password is required',
  usernameError: 'Username is required',
  invalidCredentials: 'Username/Password is not valid',
};

export default Texts;
