import accountService from 'app/Services/AccountService';
import safeInvoke from 'utils/safeInvoke';
import session from 'utils/Session';

function useAccountState({ listeners = {} }) {
  const { onPasswordChanged } = listeners || {};
  const user = session.getUser();

  const changePassword = (changePasswordForm, formikBag) => {
    accountService.changePassword(changePasswordForm, formikBag, {
      onSuccess: () => {
        safeInvoke(onPasswordChanged, user);
      },
    });
  };

  return {
    user,
    changePassword,
  };
}

export default useAccountState;
