/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
import { useState } from 'react';
import editorService from 'app/Services/DemoEditorService';
import useMutation from 'utils/useMutation';
import './styles.scss';

function useAddEditor(demoId, cb) {
  const [value, setValue] = useState(undefined);

  const addEditorsMutation = useMutation(editorService.add, {
    onSuccess: (result) => {
      if (cb) cb(result);
    },
  });

  const handleSubmit = () => {
    const values = (value || []).map((option) => option.value);
    if (values) {
      addEditorsMutation.mutate({ demoId, editorsIds: values });
    }
  };

  const handleChange = (change) => {
    setValue(change);
  };

  return {
    inProcess: addEditorsMutation.inProcess,
    isEmpty: !value,
    error: addEditorsMutation.error,
    change: handleChange,
    submit: handleSubmit,
  };
}

export default useAddEditor;
