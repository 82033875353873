const Texts = {
  pageTitle: 'Profile',
  changePassword: 'Change',
  accountInfomationTitle: 'Account Information',
  passwordChangedTitle: 'Password Changed',
  passwordChangedDescription: 'Your password has been changed.',
  usernameLabel: 'Username',
  emailLabel: 'Email',
  roleLabel: 'Role',
  accountStartDateLabel: 'Account Start Date',
  changePasswordLabel: 'Change Password',
};

export default Texts;
