/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
import React from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import {
  PencilSquare,
  ThreeDots as ThreeDotsIcon,
} from 'react-bootstrap-icons';
import { get } from 'lodash';
import { to_MMDDYYYYHHMMA, to_HH_MM_SS } from 'utils/dates';
import useModalState from 'utils/useModalState';
import { DemoStatus, TourType } from 'utils/constants/demo';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import AreYouSure from 'common/Dialogs/AreYouSure';
import DataTable from 'common/DataTable';
import SortableHeader from 'common/DataTable/SortableHeader';
import { useToasts } from 'common/ActionToast';
import OverlayLink from 'common/Overlay/OverlayLink';
import SwitchValue from 'common/Inputs/SwitchValue';
import Shell from 'app/Shells/Management';
import routerMap from 'app/RouterMap';
import PublishDemoForm from 'app/Forms/PublishDemo';
import DemoEditorView from 'app/Views/DemoEditors';
import session from 'utils/Session';
import {
  canBePublished,
  canBeCompleted,
  canEditPublished,
  canEditTemplate,
  isGuided,
} from 'utils/business/demo';
import useDemoState from './useDemoState';
import PublishedMetadata from './components/PublishedMetadata';
import DemoStats from './components/DemoStats';
import Texts from './texts';
import './styles.scss';

function PublishedDemosView() {
  const modalState = useModalState();
  const toasts = useToasts();
  const demoState = useDemoState();
  const demo = demoState.demo;

  const showArchive = get(demoState, 'query.result.data', []).length === 0;

  const openWindow = (type, tourId) => {
    window.open(
      routerMap.editor.getPath(
        demo.id,
        tourId,
        demo.name,
        type,
        demo.product_type,
      ),
    );
  };

  const onGoToTemplate = () => {
    const templateId = demo.template.id;
    openWindow(TourType.TEMPLATE, templateId);
  };

  const onGoToTour = (guidedTour) => {
    openWindow(TourType.TOUR, guidedTour.id);
  };

  const handleOnPublished = () => {
    modalState.close();
    demoState.reload();
  };

  const refreshTourConfirmation = useModalState();

  const onRefreshTour = (publishedDemo) => {
    demoState.refreshTour(publishedDemo, {
      onSuccess: (updatedPublishedDemo) => {
        demoState.updatePublishedDemoInData({
          id: updatedPublishedDemo.id,
          guidedTour: {
            id: get(updatedPublishedDemo, 'guidedTour.id'),
            tour: get(updatedPublishedDemo, 'guidedTour.props.tour'),
          },
        });
        toasts.onAdd(
          publishedDemo.id,
          Texts.refreshTourSuccessTitle,
          Texts.refreshTourSuccessDescription,
        );
      },
      onError: () => {
        toasts.onAdd(
          publishedDemo.id,
          Texts.refreshTourErrorTitle,
          Texts.refreshTourErrorDescription,
        );
      },
    });
    refreshTourConfirmation.close();
  };

  const handleToggleEnabled = async (publishedDemo) => {
    demoState.updatePublishedDemo(
      { id: publishedDemo.id, enabled: !publishedDemo.enabled },
      {
        onSuccess: () => {},
        onError: () => {
          toasts.onAdd(
            publishedDemo.id,
            Texts.updatePublishedDemoErrorTitle,
            Texts.updatePublishedDemoErrorDescription,
          );
        },
      },
    );
  };

  const areSameVersion = (publishedDemo) => {
    const tourVersion = get(publishedDemo, 'guidedTour.tour.version');
    return demoState.templateVersion === tourVersion;
  };

  const getExperienceTypeText = (publishedDemo) => {
    let experienceTypeText = Texts[publishedDemo.experience_type];

    if (isGuided(publishedDemo) && areSameVersion(publishedDemo)) {
      experienceTypeText += ' (T)';
    }

    return experienceTypeText;
  };

  return (
    <Shell
      page={
        demo ? (
          <PublishedMetadata
            name={demo.name}
            author={get(demo, 'user.username')}
            date={demo.date_added}
          />
        ) : (
          ''
        )
      }
    >
      {toasts.elements}
      {modalState.show && (
        <PublishDemoForm
          onCancel={modalState.close}
          onSuccess={handleOnPublished}
          onSubmit={demoState.savePublishedDemo}
          show={modalState.show}
          publishedDemo={modalState.value}
          demo={demo}
        />
      )}
      {refreshTourConfirmation.show && (
        <AreYouSure
          message={Texts.refreshTourDescription}
          onAccept={onRefreshTour}
          onCancel={refreshTourConfirmation.close}
          show={refreshTourConfirmation.show}
          title={Texts.refreshTourTitle}
          value={refreshTourConfirmation.value}
        />
      )}
      <div id="demo-details" className="mt-4">
        {demo && (
          <DemoStats
            demoId={demo.id}
            imageUrl={demo.thumbnail_url}
            link={demo.click_demo_url}
          />
        )}
        <div className="d-flex justify-content-between align-items-center my-4">
          <div>
            {canEditTemplate(demo) && (
              <Button
                size="md"
                variant="secondary"
                className="p-2 d-flex align-items-center"
                onClick={onGoToTemplate}
              >
                <span className="mr-2">{Texts.tourTemplateButton}</span>{' '}
                <PencilSquare />
              </Button>
            )}
          </div>
          <div>
            {get(demo, 'meta.canInviteEditor') && (
              <DemoEditorView demoId={get(demoState, 'demo.id')} />
            )}
            {showArchive && (
              <Button
                className="mr-3"
                variant="secondary"
                size="md"
                onClick={demoState.toggleArchive}
              >
                {demo &&
                  (demo.status === DemoStatus.ARCHIVED
                    ? Texts.unarchiveLabel
                    : Texts.archiveLabel)}
              </Button>
            )}
            {session.isAdmin() && canBeCompleted(demo) && (
              <Button size="md" onClick={demoState.completeDemo}>
                {Texts.COMPLETE}
              </Button>
            )}
            {canBePublished(demo) && (
              <Button size="md" onClick={modalState.open}>
                {Texts.publishButton}
              </Button>
            )}
          </div>
        </div>
        <div className="mt-4">
          <DataTable
            onLimitChange={demoState.changeLimit}
            onPageChange={demoState.changePage}
            onReload={demoState.reload}
            total={get(demoState, 'query.result.total', 0)}
            page={demoState.query.page}
            limit={demoState.query.limit}
            isFetching={demoState.query.isLoading}
            hasError={demoState.query.error}
          >
            <thead>
              <tr>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="clientName"
                  current={demoState.query.sort}
                >
                  Client Name
                </SortableHeader>
                <th>Demo Unique URL</th>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="type"
                  current={demoState.query.sort}
                >
                  Type
                </SortableHeader>
                <th>Shared By</th>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="sharedDate"
                  current={demoState.query.sort}
                >
                  Date Shared
                </SortableHeader>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="visits"
                  current={demoState.query.sort}
                >
                  Visits
                </SortableHeader>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="pageviews"
                  current={demoState.query.sort}
                >
                  Page Views
                </SortableHeader>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="avgSession"
                  current={demoState.query.sort}
                >
                  Avg. Session Duration
                </SortableHeader>
                <SortableHeader
                  onChange={demoState.changeSort}
                  value="uniqueVisits"
                  current={demoState.query.sort}
                >
                  Unique Visitors
                </SortableHeader>
                <th>Enabled</th>
                <th />
              </tr>
            </thead>
            {!demoState.query.isLoading && (
              <tbody>
                {demoState.publishedDemos.map((publishedDemo) => (
                  <tr key={publishedDemo.id}>
                    <td className="bold">
                      {publishedDemo.client.name}
                      {publishedDemo.tags.map((tag) => (
                        <Badge
                          key={`${publishedDemo.id}-${tag.id}`}
                          className="demo-tag"
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </td>
                    <td>
                      <OverlayLink
                        displayText={publishedDemo.hash_id}
                        url={publishedDemo.demo_unique_url}
                      />
                    </td>
                    <td>{getExperienceTypeText(publishedDemo)}</td>
                    <td>{publishedDemo.user.username}</td>
                    <td>{to_MMDDYYYYHHMMA(publishedDemo.date_shared)}</td>
                    <td>{publishedDemo.visits}</td>
                    <td>{publishedDemo.pageviews}</td>
                    <td>{to_HH_MM_SS(publishedDemo.avg_session_duration)}</td>
                    <td>{publishedDemo.unique_visitors}</td>
                    <td>
                      <SwitchValue
                        value={publishedDemo}
                        id={publishedDemo.id}
                        checked={publishedDemo.enabled}
                        disabled={!session.isAdmin()}
                        onChange={handleToggleEnabled}
                      />
                    </td>
                    <td>
                      <FloatingMenuState>
                        {({ show, open }) => (
                          <>
                            <ThreeDotsIcon
                              onClick={open}
                              className="cursor-pointer"
                            />
                            {show && (
                              <FloatingMenu>
                                <Card className="py-1">
                                  {canEditPublished(publishedDemo, demo) && (
                                    <>
                                      <FloatinMenuOption
                                        onClick={modalState.open}
                                        value={publishedDemo}
                                      >
                                        Edit
                                      </FloatinMenuOption>
                                      {isGuided(publishedDemo) && (
                                        <FloatinMenuOption
                                          onClick={onGoToTour}
                                          value={publishedDemo.guidedTour}
                                        >
                                          Edit Tour
                                        </FloatinMenuOption>
                                      )}
                                      {isGuided(publishedDemo) &&
                                        !areSameVersion(publishedDemo) && (
                                          <FloatinMenuOption
                                            onClick={
                                              refreshTourConfirmation.open
                                            }
                                            value={publishedDemo}
                                          >
                                            Refresh Tour
                                          </FloatinMenuOption>
                                        )}
                                    </>
                                  )}
                                  <FloatinMenuOption
                                    onClick={demoState.urlToClipboard}
                                    value={publishedDemo}
                                  >
                                    Copy Link
                                  </FloatinMenuOption>
                                </Card>
                              </FloatingMenu>
                            )}
                          </>
                        )}
                      </FloatingMenuState>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </DataTable>
        </div>
      </div>
    </Shell>
  );
}

export default PublishedDemosView;
