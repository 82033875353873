import { useCallback } from 'react';
import debounce from 'debounce-promise';

function useAsyncLoad(searchService, { limit = 5 } = {}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedService = useCallback(
    debounce(async (inputValue) => {
      return searchService(`?q=${inputValue}&limit=${limit}`);
    }, 500),
    [],
  );
  return debouncedService;
}

export default useAsyncLoad;
