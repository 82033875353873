import { get } from 'lodash';

const embeddablesRouterMap = {
  init: {
    path: '/embed',
    getPath: () => '/embed',
  },
  docs: {
    path: '/embed/docs',
    getPath: () => '/embed/docs',
  },
  demos: {
    path: '/embed/demos',
    getPath: (clientId) => `/embed/demos?client_id=${clientId}`,
  },
  publishedDemoDetails: {
    path: '/embed/published-demo-details',
    getPath: (demo, publishedDemo) => {
      const demoName = `demo_name=${get(demo, 'name', '')}`;
      const uniqueUrl = `unique_url=${get(
        publishedDemo,
        'demo_unique_url',
        '',
      )}`;
      const clientName = `client_name=${get(publishedDemo, 'client.name', '')}`;

      return `/embed/published-demo-details?${demoName}&${uniqueUrl}&${clientName}`;
    },
  },
};

export default embeddablesRouterMap;
