import { useState, useEffect } from 'react';

function useFilterLoader(service, mapper) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const load = (filterId) => {
    setIsLoading(true);
    service(filterId, {
      onSuccess: (data) => {
        setOptions(mapper(data));
        setIsLoading(false);
      },
      onError: () => {
        console.log('error loading options');
      },
    });
  };

  useEffect(() => {
    load();
  }, []);

  return {
    options,
    isLoading,
    load,
  };
}

export default useFilterLoader;
