const Texts = {
  title: 'Reset your password',
  continueButton: 'Continue',
  successTitle: 'Your password has been reset successfully!',
  successDescription: 'Your new password has been sent to your email address.',
  description:
    'If you have forgotten your password, enter either your username or email below and press Continue',
};

export default Texts;
