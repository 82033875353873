import { useState } from 'react';
import { isEmpty, isPlainObject } from 'lodash';

const INITIAL_MODAL = {
  show: false,
  value: undefined,
};
function useModalState({
  show: initialShow,
  value: initialValue,
} = INITIAL_MODAL) {
  const [modal, setModal] = useState({
    show: initialShow,
    value: initialValue,
  });

  const handleOpenModal = (value) => {
    const isInvalidValue = isEmpty(value) || !isPlainObject(value);
    setModal({
      show: true,
      value: isInvalidValue ? undefined : value,
    });
  };

  const handleCloseModal = () => {
    setModal({
      show: false,
      value: undefined,
    });
  };

  return {
    show: modal.show,
    value: modal.value,
    open: handleOpenModal,
    close: handleCloseModal,
  };
}

export default useModalState;
