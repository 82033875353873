import React, { useState } from 'react';
import { func, shape, bool } from 'prop-types';
import { Rnd } from 'react-rnd';
import { Pencil } from 'react-bootstrap-icons';

import { merge } from 'utils/immutable';
import PrimitiveButton from 'common/Buttons/Primitive';
import ThemeStyle from '../../ThemeStyle';
import stylejss from './stylejss';
import './styles.scss';

function Hotspot({
  hotspot,
  onEditHotspot,
  onUpdate,
  preview: HotspotPreview,
}) {
  const [isResizing, setIsResizing] = useState(false);

  const handleDrag = (event, d) => {
    event.stopPropagation();
    const updated = {
      ...hotspot,
      x: d.x,
      y: d.y,
    };
    onUpdate(updated);
  };

  const handleResizeStart = (event) => {
    event.stopPropagation();
    setIsResizing(true);
  };

  const handleResize = (event, direction, ref, delta, position) => {
    event.stopPropagation();
    setIsResizing(false);

    const updated = merge(
      {
        width: ref.style.width,
        height: ref.style.height,
        x: position.x,
        y: position.y,
      },
      hotspot,
    );
    onUpdate(updated);
  };

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleEditClick = () => {
    onEditHotspot(hotspot);
  };

  return (
    <ThemeStyle style={stylejss}>
      <Rnd
        bounds="parent"
        className="hotspot-resizable-container"
        onClick={handleClick}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResize}
        onDragStop={handleDrag}
        size={{ width: hotspot.width, height: hotspot.height }}
        position={{ x: hotspot.x, y: hotspot.y }}
      >
        <PrimitiveButton
          onClick={handleEditClick}
          className="hotspot-edit-button p-1 d-flex justify-content-center align-items-center"
        >
          <Pencil className="cursor-pointer" />
        </PrimitiveButton>
        {!isResizing && HotspotPreview}
      </Rnd>
    </ThemeStyle>
  );
}

Hotspot.propTypes = {
  hotspot: shape({}),
  onEditHotspot: func.isRequired,
  onUpdate: func.isRequired,
  preview: bool,
};

Hotspot.defaultProps = {
  hotspot: null,
  preview: false,
};

export default Hotspot;
