const Texts = {
  archiveLabel: 'Archive',
  unarchiveLabel: 'Unarchive',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
  COMPLETE: 'Complete',
  publishButton: 'Publish Demo',
  tourTemplateButton: 'Edit Tour Template',
  GUIDED: 'Guided',
  NON_GUIDED: 'Non-Guided',
  publishedTitle: 'Demo Published',
  refreshTourTitle: 'Refresh Tour',
  refreshTourDescription:
    'This will change the tour of this unique demo to match the Tour Template. Any intentional differences in the tour will be lost.',
  refreshTourSuccessTitle: 'Tour Updated',
  refreshTourSuccessDescription: 'The tour has been updated.',
  refreshTourErrorTitle: 'Error Refreshing Tour',
  refreshTourErrorDescription: 'The tour could not be updated.',
  updatePublishedDemoErrorTitle: 'Error Updating Published Demo',
  updatePublishedDemoErrorDescription:
    'The published demo could not be updated',
  publishedDescription: (name) => `Published demo for "${name}"`,
};

export default Texts;
