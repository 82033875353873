import appConfig from 'app/Config';
import { TourType } from 'utils/constants/demo';

const experienceURL = appConfig.get('experienceURL');

const routerMap = {
  dash: {
    path: '/',
    getPath: () => '/',
  },
  login: {
    path: '/login',
    getPath: () => '/login',
  },
  resetPassword: {
    path: '/reset-password',
    getPath: () => '/reset-password',
  },
  forgotUsername: {
    path: '/forgot-username',
    getPath: () => '/forgot-username',
  },
  library: {
    path: '/demos',
    getPath: () => '/demos',
  },
  reports: {
    path: '/reports',
    getPath: () => '/reports',
  },
  demo: {
    path: '/demos/:id',
    getPath: (id) => `/demos/${id}`,
  },
  editor: {
    path: '/demos/:id/experience-editor',
    getPath: (demoId, tourId, demoName, type, productType) =>
      `/demos/${demoId}/experience-editor?name=${demoName}&type=${type}&tour_id=${tourId}&product_type=${productType}`,
  },
  settings: {
    path: '/settings',
    getPath: () => '/settings',
  },
  experience: {
    getPath: (uniqueUrl) => `${experienceURL}/${uniqueUrl}`,
  },
  playTour: {
    getPath: (tourType, tourId, productType) => {
      const productQS = productType ? `?product_type=${productType}` : '';
      const route = tourType === TourType.TEMPLATE ? 'templates' : 'tours';
      return `${experienceURL}/${route}/${tourId}${productQS}`;
    },
  },
  userProfile: {
    path: '/profile',
    getPath: () => `/profile`,
  },
};

export default routerMap;
