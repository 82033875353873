import React, { useContext, useMemo } from 'react';
import { func, number, shape, string } from 'prop-types';
import { ChatSquareDotsFill, StopwatchFill } from 'react-bootstrap-icons';
import Popover from 'react-bootstrap/Popover';
import { Rnd } from 'react-rnd';
import {
  HOTSPOT_EVENTS,
  PLACEMENT,
} from 'app/Views/ExperienceEditor/constants';
import ThemeContext from 'app/Views/ExperienceEditor/ThemeStyle/ThemeContext';

const ARROW_SIZE = 10;

function ResizableDescription({
  description,
  event,
  footer,
  header,
  placement,
  size,
  updateSize,
}) {
  const { theme } = useContext(ThemeContext);

  const handleResize = (e, direction, ref) => {
    e.stopPropagation();
    updateSize({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    });
  };

  const eventIcon = useMemo(() => {
    const ICON_MAP = {
      [HOTSPOT_EVENTS.CLICK]: null,
      [HOTSPOT_EVENTS.COMMENTARY]: (
        <ChatSquareDotsFill className="pr-2 hotspot-icon" />
      ),
      [HOTSPOT_EVENTS.TIMER]: <StopwatchFill className="pr-2 hotspot-icon" />,
    };

    return ICON_MAP[event];
  }, [event]);

  const allowedResize = useMemo(() => {
    const allAllowed = { top: true, bottom: true, right: true, left: true };

    const ALLOWED_MAP = {
      [PLACEMENT.TOP]: { ...allAllowed, bottom: false },
      [PLACEMENT.BOTTOM]: { ...allAllowed, top: false },
      [PLACEMENT.LEFT]: { ...allAllowed, right: false },
      [PLACEMENT.RIGHT]: { ...allAllowed, left: false },
    };

    return ALLOWED_MAP[placement];
  }, [placement]);

  return (
    <Rnd
      bounds=".hotspot-layer"
      className="preview-resizable-container"
      size={{
        width: size.width || '100%',
        height: size.height || 'auto',
      }}
      minWidth={245}
      maxWidth={800}
      minHeight={60}
      maxHeight={800}
      onResizeStop={handleResize}
      disableDragging
      style={{ backgroundColor: theme.background }}
      enableResizing={allowedResize}
    >
      <Popover.Content>
        <div
          className="popover-content"
          style={{ height: size.height && size.height - ARROW_SIZE }}
        >
          <div className="hotspot-header pb-1">
            {eventIcon}
            {header || 'Step'}
          </div>
          <div className="hotspot-description">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
          {footer && <div className="hotspot-footer pt-1">{footer}</div>}
        </div>
      </Popover.Content>
    </Rnd>
  );
}

ResizableDescription.propTypes = {
  description: string,
  event: string.isRequired,
  footer: string,
  header: string,
  placement: string.isRequired,
  size: shape({
    width: number,
    height: number,
  }),
  updateSize: func.isRequired,
};

ResizableDescription.defaultProps = {
  description: null,
  footer: null,
  header: null,
  size: {
    width: undefined,
    height: undefined,
  },
};

export default ResizableDescription;
