import http from 'utils/Http';

import { handleFormErrors } from './utils';

const SendUsername = async (form, formikBag, { onSuccess }) => {
  try {
    await http.post({
      url: 'forgot-username',
      body: form,
    });
    onSuccess();
  } catch (error) {
    handleFormErrors(error, formikBag);
  } finally {
    formikBag.setSubmitting(false);
  }
};

export default SendUsername;
