import React from 'react';
import { func } from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';

import Texts from './texts';

const validationSchema = yup
  .object({
    username: yup.string(),
    email: yup.string().email(Texts.emailError),
  })
  .test('atLeastOne', null, (values) => {
    if (values.email || values.username) {
      return null;
    }
    return new yup.ValidationError(Texts.requiredError, null, 'atLeastOne');
  });

function ResetPasswordForm({ onCancel, onSubmit }) {
  return (
    <Formik
      initialValues={{ username: '', email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
      }) => {
        return (
          <Form noValidate>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="username">{Texts.usernameLabel}</Form.Label>
              <Form.Control
                name="username"
                type="text"
                disabled={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (touched.username || touched.atLeastOne) &&
                  (errors.username || errors.atLeastOne)
                }
              />
              {touched.username && errors.username && (
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <span>or</span>
            <Form.Group>
              <Form.Label htmlFor="password">{Texts.emailLabel}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                disabled={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (touched.email || touched.atLeastOne) &&
                  (errors.email || errors.atLeastOne)
                }
              />
              {touched.email && errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {errors.atLeastOne && (
              <SubmitErrorMessage>{errors.atLeastOne}</SubmitErrorMessage>
            )}

            {!isSubmitting && errors.invalidCredentials && (
              <SubmitErrorMessage>
                {errors.invalidCredentials}
              </SubmitErrorMessage>
            )}
            {!isSubmitting && errors.internalServerError && (
              <SubmitErrorMessage>
                {errors.internalServerError}
              </SubmitErrorMessage>
            )}
            <AcceptCancelButton
              isProcessing={isSubmitting}
              variant="primary"
              type="submit"
              cancelLabel={Texts.cancelButton}
              onCancel={onCancel}
              onAccept={handleSubmit}
            >
              {Texts.submitButton}
            </AcceptCancelButton>
          </Form>
        );
      }}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

export default ResetPasswordForm;
