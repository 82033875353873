import React, { useEffect, useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import Select from 'react-select';
import filtersService from 'app/Services/FiltersService';
import useFilterLoader from './useFilterLoader';

function ClientsDemosSelect({ client, demo, onClientChange, onDemoChange }) {
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [selectedDemo, setSelectedDemo] = useState(undefined);

  const clientsFilter = useFilterLoader(filtersService.clients, (clients) =>
    clients.map((option) => ({
      label: option.name,
      value: option.id,
    })),
  );

  const demosFilter = useFilterLoader(filtersService.demos, (demos) =>
    demos.map((option) => ({
      label: option.name,
      value: option.id,
    })),
  );

  const toClientDemo = (option) => {
    const clientDemo = option
      ? { id: option.value, name: option.label }
      : undefined;
    return clientDemo;
  };

  const setClient = (option) => {
    const value = option ? option.value : undefined;

    demosFilter.load(value);
    setSelectedClient(option);
  };

  const handleClientChange = (option) => {
    setClient(option);
    onClientChange(toClientDemo(option), toClientDemo(selectedDemo));
  };

  const setDemo = (option) => {
    const value = option ? option.value : undefined;

    clientsFilter.load(value);
    setSelectedDemo(option);
  };

  const handleDemoChange = (option) => {
    setDemo(option);
    onDemoChange(toClientDemo(selectedClient), toClientDemo(option));
  };

  useEffect(() => {
    if (client) {
      const option = { value: client.id, label: client.name };
      setClient(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (demo) {
      const option = { value: demo.id, label: demo.name };
      setDemo(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo]);

  return (
    <>
      <div className="w-25 mr-4">
        <Select
          value={selectedClient}
          placeholder="All Clients"
          options={clientsFilter.options}
          onChange={handleClientChange}
          isClearable
        />
      </div>
      <div className="w-25 mr-4">
        <Select
          value={selectedDemo}
          placeholder="All Demos"
          options={demosFilter.options}
          onChange={handleDemoChange}
          isClearable
        />
      </div>
    </>
  );
}

const ClientDemo = shape({
  id: number.isRequired,
  name: string.isRequired,
});

ClientsDemosSelect.propTypes = {
  client: ClientDemo,
  demo: ClientDemo,
  onClientChange: func.isRequired,
  onDemoChange: func.isRequired,
};

ClientsDemosSelect.defaultProps = {
  client: undefined,
  demo: undefined,
};

export default ClientsDemosSelect;
