import React from 'react';
import { func, shape } from 'prop-types';
import { DropletHalf, PencilSquare } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import SimpleSelect from 'common/Inputs/SimpleSelect';

import Colorpicker from 'common/Inputs/Colorpicker';
import { FONT_SIZE_OPTIONS, FONT_TYPE_OPTIONS } from './constants';

import './styles.scss';

function FormatMenu({ value, onChange }) {
  const handlePropChange = (prop, newValue) => {
    const updated = {
      ...value,
      [prop]: newValue,
    };
    onChange(updated);
  };
  const handleFontSizeChange = (change) => handlePropChange('fontSize', change);
  const handleFontTypeChange = (change) =>
    handlePropChange('fontFamily', change);
  const handleBackgroundColorChange = (change) =>
    handlePropChange('background', change);
  const handleFontColorChange = (change) => handlePropChange('color', change);

  return (
    <div className="format-menu pb-2">
      <div>
        <Form.Group className="">
          <Form.Label htmlFor="select">Font</Form.Label>
          <SimpleSelect
            placeholder="Type"
            options={FONT_TYPE_OPTIONS}
            value={value.fontFamily}
            onChange={handleFontTypeChange}
          />
        </Form.Group>
        <Form.Group className="">
          <Form.Label htmlFor="fontsize">Font Size</Form.Label>
          <SimpleSelect
            placeholder="Size"
            options={FONT_SIZE_OPTIONS}
            value={value.fontSize}
            onChange={handleFontSizeChange}
          />
        </Form.Group>
      </div>
      <div>
        <div className="color-input-wrapper">
          <Colorpicker
            onChange={handleBackgroundColorChange}
            value={value.background}
            trigger={<DropletHalf />}
          />
          <Colorpicker
            onChange={handleFontColorChange}
            value={value.color}
            trigger={<PencilSquare />}
          />
        </div>
      </div>
    </div>
  );
}

FormatMenu.propTypes = {
  onChange: func.isRequired,
  value: shape({}),
};

FormatMenu.defaultProps = {
  value: undefined,
};

export default FormatMenu;
