export const FONT_FORMAT =
  'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats';
export const FONT_SIZE_FORMAT = '8pt 10pt 12pt 14pt 16pt 18pt 24pt';
export const FONT_SIZE_OPTIONS = FONT_SIZE_FORMAT.split(' ').map((option) => ({
  label: option,
  value: option,
}));
export const FONT_TYPE_OPTIONS = FONT_FORMAT.split('; ').map((option) => ({
  label: option.split('=')[0],
  value: option.split('=')[1],
}));
