import generatePasswords from 'password-generator';
import http from 'utils/Http';
import { handleFormErrors } from './utils';

const buildUrl = (suffix) => `api/v1/users${suffix ? `${suffix}` : ''}`;

class UserCRUD {
  static mapListFrom = (users = []) => {
    return users.map((option) => {
      const role = option.roles[0] || {};

      return {
        id: option.id,
        created_at: option.created_at,
        email: option.email,
        is_active: option.is_active,
        username: option.username,
        role: role.role_name,
      };
    });
  };

  create = async (userForm, formikBag, responseHandlers) => {
    try {
      const { data: newUser } = await http.post({
        url: buildUrl(),
        body: {
          ...userForm,
          password: generatePasswords(12, false),
        },
      });
      responseHandlers.onSuccess(newUser);
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  edit = async (userForm, formikBag, responseHandlers) => {
    try {
      const { data: newUser } = await http.put({
        url: buildUrl(`/${userForm.id}`),
        body: userForm,
      });
      responseHandlers.onSuccess(newUser);
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  delete = async (userId, responseHandlers) => {
    try {
      await http.delete({
        url: buildUrl(`/${userId}`),
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  all = async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(query),
      });
      const mappedUsers = UserCRUD.mapListFrom(response.data);
      responseHandlers.onSuccess({
        data: mappedUsers,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new UserCRUD();
