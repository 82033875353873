import { useState, useEffect } from 'react';
import metricsService from 'app/Services/MetricsService';

function useDemoStatsState(demoId) {
  const [metrics, setMetrics] = useState({});

  const loadMetrics = () => {
    metricsService.publishedDemos(demoId, {
      onSuccess: (metricsData) => {
        setMetrics(metricsData);
      },
      onError: () => {
        console.log('error getting metrics');
      },
    });
  };

  useEffect(() => {
    loadMetrics();
  }, []);

  return {
    metrics,
  };
}

export default useDemoStatsState;
