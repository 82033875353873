/* eslint-disable camelcase */
import http from 'utils/Http';
import { get } from 'lodash';
import { to_YYYY_MM_DD } from 'utils/dates';
import routerMap from 'app/RouterMap';

const buildUrl = (suffix) => `api/v1/reports${suffix ? `${suffix}` : ''}`;

class ReportsService {
  static mapPublishedDemosReport = (report = {}) => {
    const publishedDemos = report.publishedDemos.map((option) => {
      return {
        id: option.id,
        demo_unique_url: routerMap.experience.getPath(option.demo_unique_url),
        name: option.name,
        pageviews: option.pageviews,
        pageviews_percentage: option.pageviews_percentage,
        unique_pageviews: option.unique_pageviews,
        unique_pageviews_percentage: option.unique_pageviews_percentage,
        avg_time_on_page: option.avg_time_on_page,
        entrances: option.entrances,
        entrances_percentage: option.entrances_percentage,
        bounce_rate: option.bounce_rate,
        exit_rate: option.exit_rate,
        page_value: option.page_value,
        page_value_percentage: option.page_value_percentage,
        client_name: option.client_name,
        hash_id: option.demo_unique_url,
      };
    });

    return {
      totals: report.totals,
      publishedDemos,
    };
  };

  buildQuery = (clientId, demoId, startDate, endDate) => {
    let query = '';

    if (clientId) {
      query = `?client=${clientId}`;
    }

    if (demoId) {
      query = `?demo=${demoId}`;
    }

    if (startDate && endDate) {
      const formattedStartDate = to_YYYY_MM_DD(startDate);
      const formattedEndDate = to_YYYY_MM_DD(endDate);
      query += query
        ? `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
        : `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    }

    return query;
  };

  publishedDemosReport = async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/published_demos${query}`),
      });

      const report = ReportsService.mapPublishedDemosReport(
        get(response, 'data.report', {}),
      );

      responseHandlers.onSuccess({
        data: report,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  static mapTopDemosPageviews = (topDemosPageviews = []) => {
    const mappedTopDemosPageviews = topDemosPageviews.map((option) => {
      return {
        demo_id: option.demo_id,
        demo_name: option.demo_name,
        total_pageviews: option.total_pageviews,
      };
    });

    return mappedTopDemosPageviews;
  };

  topDemosPageviews = async (
    clientId,
    demoId,
    startDate,
    endDate,
    responseHandlers,
  ) => {
    const query = this.buildQuery(clientId, demoId, startDate, endDate);

    try {
      const { data: response } = await http.get({
        url: buildUrl(`/demos/top_pageviews${query}`),
      });

      const data = get(response, 'data.top_demos', []);
      const mappedTopDemosPageviews = ReportsService.mapTopDemosPageviews(data);

      responseHandlers.onSuccess(mappedTopDemosPageviews);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  static mapDemosPageviews = (demosPageviews = {}) => {
    const pageviews = get(demosPageviews, 'pageviews', []);
    const mappedPageviews = pageviews.map((option) => {
      return {
        date: option.date,
        total_pageviews: option.total_pageviews,
      };
    });

    return {
      internval: demosPageviews.interval,
      pageviews: mappedPageviews,
    };
  };

  demosPageviews = async (
    clientId,
    demoId,
    startDate,
    endDate,
    responseHandlers,
  ) => {
    const formattedStartDate = to_YYYY_MM_DD(startDate);
    const formattedEndDate = to_YYYY_MM_DD(endDate);
    const query = `?client=${clientId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

    try {
      const { data: response } = await http.get({
        url: buildUrl(`/demos/${demoId}/pageviews${query}`),
      });

      ReportsService.mapDemosPageviews(response.data);
      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  static mapTopClientsPageviews = (topClientsPageviews = []) => {
    const mappedTopClientsPageviews = topClientsPageviews.map((option) => {
      return {
        client_id: option.client_id,
        client_name: option.client_name,
        total_pageviews: option.total_pageviews,
      };
    });

    return mappedTopClientsPageviews;
  };

  topClientsPageviews = async (
    clientId,
    demoId,
    startDate,
    endDate,
    responseHandlers,
  ) => {
    const query = this.buildQuery(clientId, demoId, startDate, endDate);
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/clients/top_pageviews${query}`),
      });

      const data = get(response, 'data.top_clients', []);
      const mappedTopClientsPageviews = ReportsService.mapTopClientsPageviews(
        data,
      );

      responseHandlers.onSuccess(mappedTopClientsPageviews);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new ReportsService();
