import http from 'utils/Http';
import { get } from 'lodash';

const buildUrl = (suffix) => `api/v1/projects${suffix ? `${suffix}` : ''}`;

class ProjectsService {
  static mapScreens = (screens = []) => {
    const mappedScreens = screens.map((option) => ({
      imageUrl: option.url,
      name: option.display_name,
    }));
    return mappedScreens;
  };

  all = async (demoId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/screens/${demoId}`),
      });

      const screens = get(response, 'data.screens', []);
      const mappedScreens = ProjectsService.mapScreens(screens);

      responseHandlers.onSuccess(mappedScreens);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  static mapProjects = (projects = []) => {
    const mappedProjects = projects.map((option) => ({
      name: option.name,
      prototype_url: option.prototype_url,
      external_id: option.external_id,
      product_type: option.product_type,
      thumbnail_url: option.thumbnail_url,
    }));

    return mappedProjects;
  };

  getProjectsDropdown = async (responseHandlers) => {
    try {
      const res = await http.get({
        url: buildUrl(),
      });
      const projects = get(res, 'data.data', []);
      const mappedProjects = ProjectsService.mapProjects(projects);
      responseHandlers.onSuccess(mappedProjects);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  sync = async (responseHandlers) => {
    try {
      await http.post({ url: buildUrl('/sync') });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new ProjectsService();
