import React from 'react';
import { any, func } from 'prop-types';
import Base from 'react-bootstrap/Button';

function Button({ onClick, value, ...rest }) {
  const handleClick = () => onClick(value);

  return <Base onClick={handleClick} {...rest} />;
}

Button.propTypes = {
  onClick: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

Button.defaultProps = {
  value: undefined,
};

export default Button;
