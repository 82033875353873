import React from 'react';
import { bool, func, oneOfType, number, shape, string } from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';

function AreYouSure({ show, onCancel, onAccept, message, title, value }) {
  const handleOnAccept = () => {
    onAccept(value);
  };

  return (
    <Modal size="md" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <AcceptCancelButton
          isProcessing={false}
          acceptLabel="Accept"
          onCancel={onCancel}
          onAccept={handleOnAccept}
        />
      </Modal.Footer>
    </Modal>
  );
}

AreYouSure.propTypes = {
  message: string.isRequired,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
  show: bool,
  title: string,
  value: oneOfType([number, string, shape]),
};

AreYouSure.defaultProps = {
  title: 'Are You Sure?',
  show: false,
  value: undefined,
};

export default AreYouSure;
