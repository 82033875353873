import React, { useLayoutEffect, useState } from 'react';
import { bool, func, node, shape } from 'prop-types';
import ThemeContext from './ThemeContext';

const DEFAULT = {
  background: undefined,
  color: undefined,
  fontSize: undefined,
  fontFamily: undefined,
};

function ThemeProvider({ load, children, onChange, initial }) {
  const [theme, setTheme] = useState(DEFAULT);

  const handleChange = (newTheme) => {
    setTheme(newTheme);
    onChange(newTheme);
  };

  useLayoutEffect(() => {
    if (load) {
      setTheme(initial);
    }
  }, [load]);

  return (
    <ThemeContext.Provider value={{ theme, onChange: handleChange }}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: node.isRequired,
  // eslint-disable-next-line react/require-default-props
  initial: shape({}),
  load: bool.isRequired,
  onChange: func.isRequired,
};

export default ThemeProvider;
