import React from 'react';
import { string, bool } from 'prop-types';
import Form from 'react-bootstrap/Form';
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons';

function ExpandedMenuItem({ active, label }) {
  return (
    <div className="p-2 border-top border-bottom d-flex justify-content-between align-items-center">
      <Form.Label className="mb-0">{label}</Form.Label>
      <span>{!active ? <ChevronRight /> : <ChevronDown />}</span>
    </div>
  );
}

ExpandedMenuItem.propTypes = {
  active: bool.isRequired,
  label: string.isRequired,
};

export default ExpandedMenuItem;
