import { get } from 'lodash';
import session from 'utils/Session';

import { DemoStatus, ExperienceType } from '../constants/demo';

export const canBeArchived = (demo) =>
  demo && demo.status !== DemoStatus.ARCHIVED;

export const canBeUnarchived = (demo) =>
  demo && demo.status === DemoStatus.ARCHIVED;

export const canBeCompleted = (demo) =>
  demo && demo.status === DemoStatus.DRAFT;

export const canBePublished = (demo) =>
  demo &&
  (demo.status === DemoStatus.IN_USE || demo.status === DemoStatus.COMPLETE);

export const isCompleted = (demo) =>
  demo && demo.status === DemoStatus.COMPLETE;

export const isInUse = (demo) => demo.status === DemoStatus.IN_USE;

export const canEditTemplate = (demo) => {
  return get(demo, 'meta.canWriteDemo') && (isCompleted(demo) || isInUse(demo));
};

export const isGuided = (publishedDemo) =>
  publishedDemo.experience_type === ExperienceType.GUIDED;

export const isEditor = (editors) => {
  const { id } = session.getUser();
  return editors.some((editor) => editor.user.id === id);
};

export const canWriteTourTemplate = (demo) => {
  if (!demo) {
    return false;
  }
  return session.isAdmin() || isEditor(demo.editors);
};

export const canEditPublished = (publishedDemo, demo) => {
  if (!publishedDemo || !demo) {
    return false;
  }

  const { user: owner } = publishedDemo;
  const { id: userId } = session.getUser();
  return owner.id === userId || get(demo, 'meta.canWriteDemo');
};

export const getPermissionByUser = (demo) => {
  const permissions = {
    canEditTemplate: canWriteTourTemplate(demo, session),
    canInviteEditor: session.isAdmin(),
  };

  return permissions;
};
