import React from 'react';
import { string, node } from 'prop-types';
import Texts from './texts';
import './styles.scss';

function Sidebar({
  title,
  flowInput: FlowInput,
  stepsInput: StepsInput,
  statusComponent: StatusComponent,
  previewModeComponent: PreviewModeComponent,
}) {
  return (
    <div className="tour-step-sidebar border-left border-secondary">
      <div className="tour-step-title px-2 py-3 d-flex align-items-center  justify-content-between">
        <span>{title}</span>
        <div className="d-flex align-items-center">
          <span className="mr-2">{Texts.previewLabel}</span>
          {PreviewModeComponent}
        </div>
      </div>
      <div className="sidebar-content">
        {FlowInput}
        {StepsInput}
      </div>
      <div className="sidebar-footer pb-3">{StatusComponent}</div>
    </div>
  );
}

Sidebar.propTypes = {
  flowInput: node.isRequired,
  previewModeComponent: node.isRequired,
  statusComponent: node.isRequired,
  stepsInput: node.isRequired,
  title: string.isRequired,
};

export default Sidebar;
