import React from 'react';
import { func, string } from 'prop-types';
import { ChatSquareDotsFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import cx from 'classnames';

import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';

import { PLACEMENT, PlacementOptions } from '../../constants';
import './styles.scss';

const rotatePerPlacement = {
  [PLACEMENT.RIGHT]: 'rotate-right',
  [PLACEMENT.LEFT]: 'rotate-left',
  [PLACEMENT.BOTTOM]: 'rotate-bottom',
};

function PlacementInput({ label, name, onChange, value }) {
  const activeStyle = rotatePerPlacement[value];

  const handleChange = (newValue) => {
    onChange({ target: { name, value: newValue }, type: 'change' });
  };
  return (
    <FloatingMenuState>
      {({ show, open, close }) => (
        <>
          <Button
            onClick={open}
            className="d-flex align-items-center"
            variant="outline-info"
            size="sm"
          >
            <div className="placement-input d-flex justify-content-center p-1 rounded">
              <ChatSquareDotsFill className={cx(activeStyle)} />
            </div>
            <span className="ml-1">{label}</span>
          </Button>
          {show && (
            <FloatingMenu>
              <Card className="py-1">
                {PlacementOptions.map((option) => (
                  <FloatinMenuOption
                    className="d-flex align-items-center placement-menu-option"
                    key={option}
                    value={option}
                    onClick={(newValue) => {
                      handleChange(newValue);
                      close();
                    }}
                  >
                    <ChatSquareDotsFill
                      className={cx(
                        rotatePerPlacement[option],
                        ' placement-menu-option-icon',
                      )}
                    />
                    <span className="ml-2 option-text">
                      {option.toUpperCase()}
                    </span>
                  </FloatinMenuOption>
                ))}
              </Card>
            </FloatingMenu>
          )}
        </>
      )}
    </FloatingMenuState>
  );
}

PlacementInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default PlacementInput;
