const Texts = {
  submitButton: 'Continue',
  cancelButton: 'Go Back',
  emailLabel: 'Email',
  usernameLabel: 'Username',
  requiredError: 'An email or username is required.',
  emailError: 'You must provide a valid email.',
};

export default Texts;
