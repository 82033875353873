import React from 'react';
import { bool, func, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProcessingButton from 'common/Buttons/ProcessingButton';

function AcceptCancelButton({
  disabled,
  acceptLabel,
  cancelLabel,
  isProcessing,
  onAccept,
  onCancel,
}) {
  return (
    <Form.Row className="justify-content-end">
      <Button variant="secondary" className="mr-3" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <ProcessingButton
        disabled={disabled}
        isProcessing={isProcessing}
        variant="primary"
        type="submit"
        onClick={onAccept}
      >
        {acceptLabel}
      </ProcessingButton>
    </Form.Row>
  );
}

AcceptCancelButton.propTypes = {
  acceptLabel: string,
  cancelLabel: string,
  disabled: bool,
  isProcessing: bool.isRequired,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
};

AcceptCancelButton.defaultProps = {
  acceptLabel: 'Accept',
  cancelLabel: 'Cancel',
  disabled: false,
};

export default AcceptCancelButton;
