import { useState, useEffect } from 'react';
import { get } from 'lodash';
import demoService from 'app/Services/DemoCRUDService';
import useSearchState from 'utils/useSearchState';
import safeInvoke from 'utils/safeInvoke';

function useDemoLibraryState({ listeners = {} }) {
  const { onSave, onSaveError } = listeners || {};
  const search = useSearchState(demoService.all, ['q'], undefined, {
    sort: [{ field: 'visits', order: 'asc' }],
  });
  const getAllDemos = () => get(search, 'query.result.data', []);
  const [allDemos, setAllDemos] = useState(getAllDemos());

  useEffect(() => {
    search.change('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllDemos(getAllDemos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.query.result]);

  const addDemo = (demoForm, formikBag) => {
    const isEdit = Boolean(demoForm.id);
    const serviceMethod = isEdit ? 'edit' : 'create';
    demoService[serviceMethod](demoForm, formikBag, {
      onSuccess: (demoResponse) => {
        safeInvoke(onSave, demoResponse, isEdit);
        search.reload();
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  const deleteDemo = (demoId) => {
    demoService.delete(demoId, {
      onSuccess: () => {
        search.reload();
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  const archiveDemo = (demoId) => {
    demoService.archive(demoId, {
      onSuccess: (demoResponse) => {
        safeInvoke(onSave, demoResponse, true, 'archived');
        search.reload();
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  const unarchivedDemo = (demoId) => {
    demoService.unarchive(demoId, {
      onSuccess: (demoResponse) => {
        safeInvoke(onSave, demoResponse, true, 'unarchived');
        search.reload();
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  const completeDemo = (demoId) => {
    demoService.complete(demoId, {
      onSuccess: (demoResponse) => {
        safeInvoke(onSave, demoResponse, true, 'completed');
        search.reload();
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  return {
    allDemos,
    query: search.query,
    publish: demoService.publish,
    addDemo,
    deleteDemo,
    archiveDemo,
    unarchivedDemo,
    completeDemo,
    search: search.change,
    changePage: search.changePage,
    changeSort: search.changeSort,
    changeLimit: search.changeLimit,
    filter: search.changeFilter,
  };
}

export default useDemoLibraryState;
