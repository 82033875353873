/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { bool, func, node } from 'prop-types';

function Expandable({ trigger, content, expanded, onToggle }) {
  const handleToggle = () => {
    return onToggle(!expanded);
  };

  return (
    <>
      <div className="trigger" onClick={handleToggle}>
        {trigger}
      </div>
      {expanded && <div className="content">{content}</div>}
    </>
  );
}

Expandable.propTypes = {
  content: node.isRequired,
  expanded: bool,
  onToggle: func.isRequired,
  trigger: node.isRequired,
};

Expandable.defaultProps = {
  expanded: false,
};

export default Expandable;
