/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Button from 'react-bootstrap/Button';
import { get } from 'lodash';

import Shell from 'app/Shells/Management';
import ChangePasswordForm from 'app/Forms/ChangePassword';
import { useToasts } from 'common/ActionToast';
import useModalState from 'utils/useModalState';
import { to_MMDDYYYY } from 'utils/dates';
import ThirdPartySources from './components/ThirdPartySources';
import useAccountState from './useAccountState';
import Texts from './texts';
import './styles.scss';

function UserProfileView() {
  const changePasswordModal = useModalState();
  const toasts = useToasts();
  const accountState = useAccountState({
    listeners: {
      onPasswordChanged: (user) => {
        changePasswordModal.close();
        toasts.onAdd(
          user.id,
          Texts.passwordChangedTitle,
          Texts.passwordChangedDescription,
        );
      },
    },
  });

  return (
    <Shell page={Texts.pageTitle}>
      {toasts.elements}
      <ChangePasswordForm
        onCancel={changePasswordModal.close}
        onSubmit={accountState.changePassword}
        show={changePasswordModal.show}
      />
      <div id="user-profile">
        <div className="d-flex flex-column w-50 card p-4 mb-4">
          <h5 className="mb-0">{Texts.accountInfomationTitle}</h5>
          <hr />
          <div className="d-flex justify-content-between mb-4">
            <div>{Texts.usernameLabel}</div>
            <div>{get(accountState, 'user.username', '')}</div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <div>{Texts.emailLabel}</div>
            <div>{get(accountState, 'user.email', '')}</div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <div>{Texts.roleLabel}</div>
            <div>{get(accountState, 'user.roles[0].role_name', '')}</div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <div>{Texts.accountStartDateLabel}</div>
            <div>{to_MMDDYYYY(get(accountState, 'user.created_at', ''))}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>{Texts.changePasswordLabel}</div>
            <div>
              <Button onClick={changePasswordModal.open}>
                {Texts.changePassword}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ThirdPartySources toasts={toasts} />
    </Shell>
  );
}

export default UserProfileView;
