import React from 'react';
import { bool, func, shape } from 'prop-types';
import { Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import * as yup from 'yup';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';

import Texts from './texts';

const validationSchema = yup.object({
  value: yup.string().required(),
});

const INITIAL = {
  value: null,
  __isNew__: true,
};

function CreateFlowForm({ value, show, onSuccess, onCancel }) {
  const close = () => {
    onCancel();
  };

  const handleOnSubmit = (form) => {
    const submittedForm = form;
    onSuccess(submittedForm);
    close();
  };

  return (
    <Modal show={show} onHide={close} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{Texts.title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label
                        htmlFor="description"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span className="mr-3">{Texts.nameLabel}</span>
                      </Form.Label>
                      <Form.Control
                        name="value"
                        type="text"
                        isInvalid={touched.value && errors.value}
                        value={values.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.value && errors.value && (
                        <Form.Control.Feedback type="invalid">
                          {Texts.nameError}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <AcceptCancelButton
                  acceptLabel={Texts.editLabel}
                  onCancel={close}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

CreateFlowForm.propTypes = {
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
  show: bool,
  value: shape({}),
};

CreateFlowForm.defaultProps = {
  show: false,
  value: INITIAL,
};

export default CreateFlowForm;
