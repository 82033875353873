import http from 'utils/Http';

import routerMap from 'app/RouterMap';
import { DemoStatus } from 'utils/constants/demo';
import safeInvoke from 'utils/safeInvoke';
import { handleFormErrors } from './utils';

const buildUrl = (suffix) => `api/v1/demos${suffix ? `${suffix}` : ''}`;

class DemoCRUD {
  static mapPublishedFrom = (serverPublished) => {
    return {
      id: serverPublished.id,
      experience_type: serverPublished.props.experience_type,
      demo_unique_url: routerMap.experience.getPath(
        serverPublished.props.demo_unique_url,
      ),
      client: serverPublished.client,
      user: serverPublished.user,
    };
  };

  static mapDetailFrom = ({
    publishedDemos,
    tags = [],
    user,
    ...serverDemo
  }) => {
    const mappedPublished = publishedDemos
      ? publishedDemos.map(DemoCRUD.mapPublishedFrom)
      : [];

    const demo = {
      id: serverDemo.id,
      name: serverDemo.props.name,
      script_url: serverDemo.props.script_url,
      click_demo_url: serverDemo.props.click_demo_url,
      thumbnail_url: serverDemo.props.thumbnail_url,
      date_added: serverDemo.props.date_added,
      template: serverDemo.templates ? serverDemo.templates[0] : {},
      status: serverDemo.props.status,
      product_type: serverDemo.props.product_type,
      user,
      publishedDemos: mappedPublished,
      tags,
      meta: serverDemo.meta,
    };
    return demo;
  };

  static mapListFrom = (demos = []) => {
    return demos.map((option) => {
      return {
        id: option.id,
        name: option.name,
        script_url: option.script_url,
        click_demo_url: option.click_demo_url,
        date_added: option.date_added,
        product_type: option.product_type,
        status: option.status,
        shares: option.shares,
        visits: option.visits,
        user: option.user,
        tags: option.tags,
      };
    });
  };

  static mapTo = (demo) => {
    return {
      id: demo.id,
      name: demo.name,
      click_demo_url: demo.click_demo_url,
      external_id: demo.external_id,
      thumbnail_url: demo.thumbnail_url,
      tags: demo.tags,
      product_type: demo.product_type,
    };
  };

  create = async (demoForm, formikBag, responseHandlers) => {
    try {
      const { data: newDemo } = await http.post({
        url: buildUrl(),
        body: demoForm,
      });
      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(newDemo));
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  edit = async (demoForm, formikBag, responseHandlers) => {
    try {
      const { data: updatedDemo } = await http.put({
        url: buildUrl(`/${demoForm.id}`),
        body: demoForm,
      });
      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(updatedDemo));
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  delete = async (demoId, responseHandlers) => {
    try {
      await http.delete({
        url: buildUrl(`/${demoId}`),
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  all = async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(query),
      });

      const mappedDemos = DemoCRUD.mapListFrom(response.data);

      responseHandlers.onSuccess({
        data: mappedDemos,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  byId = async (demoId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/${demoId}`),
      });

      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(response));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  archive = async (demoId, responseHandlers) => {
    try {
      const demo = await http.put({
        url: buildUrl(`/archive/${demoId}`),
      });
      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(demo.data));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  unarchive = async (demoId, responseHandlers) => {
    try {
      const demo = await http.put({
        url: buildUrl(`/unarchive/${demoId}`),
      });
      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(demo.data));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  toggleArchive = (demo, responseHandlers) => {
    if (demo.status === DemoStatus.ARCHIVED) {
      this.unarchive(demo.id, responseHandlers);
    } else {
      this.archive(demo.id, responseHandlers);
    }
  };

  complete = async (demoId, responseHandlers) => {
    try {
      const demo = await http.put({
        url: buildUrl(`/complete/${demoId}`),
      });
      responseHandlers.onSuccess(DemoCRUD.mapDetailFrom(demo.data));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  publish = async (publishDemosForm, formikBag, responseHandlers) => {
    try {
      const { data: serverPublishedDemos } = await http.post({
        url: buildUrl(`/${publishDemosForm.demo_id}/publish`),
        body: publishDemosForm,
      });
      responseHandlers.onSuccess(
        serverPublishedDemos.map(DemoCRUD.mapPublishedFrom),
      );
    } catch (error) {
      if (formikBag) {
        handleFormErrors(error, formikBag);
      } else {
        safeInvoke(responseHandlers.onError, error);
      }
    } finally {
      if (formikBag) {
        formikBag.setSubmitting(false);
      }
    }
  };
}

export default new DemoCRUD();
