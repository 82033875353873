const Texts = {
  demoNameLabel: 'Demo Name',
  demoScriptUrlLabel: 'Demo Script URL',
  clickDemoUrlLabel: 'Click Demo URL',
  readyToPublishLabel: 'Is Available?',
  tagsLabel: 'Tags',
  acceptLabel: 'Add Demo',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  editTitle: 'Edit Demo',
  productTypeLabel: 'Project Type',
  productTypeError: 'You need to check one project type',
  createTitle: 'Add to Demo Library',
  requiredError: (name) => `${name} is required`,
  invalidEmail: 'Invalid email',
  invalidUrl: 'Invalid URL',
};

export default Texts;
