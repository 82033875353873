import { useState, useEffect } from 'react';
import { get } from 'lodash';

import demoService from 'app/Services/DemoCRUDService';
import dataViewsService from 'app/Services/DataViewsService';
import safeInvoke from 'utils/safeInvoke';
import useSearchState from 'utils/useSearchState';

function useDemosState(clientId) {
  const search = useSearchState(
    (qs, handler) =>
      dataViewsService.demosSharedWithClient(clientId, qs, handler),
    ['q'],
    undefined,
    {
      sort: [{ field: 'name', order: 'asc' }],
      limit: 5,
    },
  );
  const getAllDemos = () => get(search, 'query.result.data', []);
  const [allDemos, setAllDemos] = useState(getAllDemos());

  useEffect(() => {
    search.change('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllDemos(getAllDemos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.query.result]);

  const publish = (demo, handlers) => {
    const demoToPublish = {
      demo_id: demo.id,
      experience_types: { GUIDED: true },
      client: { id: clientId },
    };

    demoService.publish(demoToPublish, undefined, {
      onSuccess: (publishedDemos) => {
        const publishedDemo = get(publishedDemos, '[0]', {});
        safeInvoke(handlers.onSuccess, publishedDemo);
      },
      onError: handlers.onError,
    });
  };

  return {
    allDemos,
    query: search.query,
    publish,
    search: search.change,
    changePage: search.changePage,
    changeSort: search.changeSort,
    changeLimit: search.changeLimit,
    filter: search.changeFilter,
  };
}

export default useDemosState;
