import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { get } from 'lodash';
import EllipsisAxisTick from './components/EllipsisAxisTick';

function TopBarChart({ barDataKey, data, dataKeyY, onBarClick }) {
  const handleBarOnClick = (barData) => {
    onBarClick(barData);
  };

  const handleBarChartOnClick = (chartData) => {
    const payload = get(chartData, 'activePayload[0].payload');

    if (payload) {
      handleBarOnClick(payload);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={320}>
      <BarChart
        data={data}
        margin={{ top: 10, right: 20, left: 15, bottom: 0 }}
        layout="vertical"
        onClick={handleBarChartOnClick}
      >
        <CartesianGrid horizontal={false} />
        <XAxis type="number" />
        <YAxis
          dataKey={dataKeyY}
          type="category"
          width={105}
          tick={<EllipsisAxisTick />}
        />
        <Tooltip />
        <Legend />
        <Bar
          name="Pageviews"
          dataKey={barDataKey}
          fill="#4b9bff"
          maxBarSize={30}
          onClick={handleBarOnClick}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

TopBarChart.propTypes = {
  barDataKey: string.isRequired,
  data: arrayOf(shape({})).isRequired,
  dataKeyY: string.isRequired,
  onBarClick: func.isRequired,
};

export default TopBarChart;
