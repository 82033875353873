import React from 'react';
import { bool, func, string } from 'prop-types';
import Button from 'react-bootstrap/Button';

function EditorPlaceholder({ onClick, label, show }) {
  if (!show) return null;
  return (
    <div className="tour-screen-content placeholder d-flex justify-content-center align-items-center">
      <Button
        onClick={onClick}
        variant="outline-secondary"
        className="step-placeholder shadow-lg rounded d-flex justify-content-center align-items-center"
      >
        <h1>{label}</h1>
      </Button>
    </div>
  );
}

EditorPlaceholder.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  show: bool.isRequired,
};

export default EditorPlaceholder;
