import React from 'react';
import Image from 'react-bootstrap/Image';
import {
  ClipboardData,
  Gear,
  GearFill,
  Layers,
  LayersFill,
  Tv,
  TvFill,
} from 'react-bootstrap-icons';
import { useHistory, useLocation } from 'react-router-dom';

import session from 'utils/Session';
import routerMap from 'app/RouterMap';

import logo from 'logo.svg';

import NavItem from '../NavItem';
import Texts from './texts';
import './styles.scss';

function Sidebar() {
  const location = useLocation();
  const history = useHistory();
  const isAdmin = session.isAdmin();

  const redirect = (newLocation) => {
    history.push(newLocation);
  };
  const activeRoot = `/${location.pathname.split('/')[1]}`;

  return (
    <div>
      <div className="pt-4 pb-1 demo-library-app-side-logo-wrapper ">
        <Image src={logo} className="demo-library-app-side-logo" />
        <h3 className="mt-1 demo-library-app-side-title">Demo Library</h3>
      </div>
      <div className="d-flex flex-column demo-library-app-side-menu my-2 pt-3">
        <NavItem
          activeNav={activeRoot}
          icon={Tv}
          activeIcon={TvFill}
          label={Texts.dash}
          onClick={redirect}
          value={routerMap.dash.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={Layers}
          activeIcon={LayersFill}
          label={Texts.demoLibrary}
          onClick={redirect}
          value={routerMap.library.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={ClipboardData}
          activeIcon={ClipboardData}
          label={Texts.reports}
          onClick={redirect}
          value={routerMap.reports.getPath()}
        />
        {isAdmin && (
          <NavItem
            activeNav={activeRoot}
            icon={Gear}
            activeIcon={GearFill}
            label={Texts.settings}
            onClick={redirect}
            value={routerMap.settings.getPath()}
          />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
