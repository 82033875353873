import { useState, useEffect } from 'react';

function useCardState(service) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const loadMetrics = (lookbackDays = 30) => {
    setIsLoading(true);
    service(lookbackDays, {
      onSuccess: (data) => {
        setMetrics(data);
        setIsLoading(false);
        setError(false);
      },
      onError: () => {
        setIsLoading(false);
        setError(true);
      },
    });
  };

  useEffect(() => {
    loadMetrics();
  }, []);

  return {
    metrics,
    isLoading,
    error,
    loadMetrics,
  };
}

export default useCardState;
