/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ChevronLeft, CollectionPlay } from 'react-bootstrap-icons';
import { func, string } from 'prop-types';

import PrimitiveButton from 'common/Buttons/Primitive';

import Texts from './texts';
import './styles.scss';

function Header({ onBack, onPlayTour, name }) {
  return (
    <div className="d-flex align-items-center justify-content-between text-white font-weight-bold">
      <PrimitiveButton className="button cursor-pointer" onClick={onBack}>
        <ChevronLeft size={22} />
        <span className="ml-2 align-middle">{Texts.goback}</span>
      </PrimitiveButton>
      <div>
        <h3 className="editor-demo-title">{name}</h3>
      </div>
      <PrimitiveButton className="button cursor-pointer" onClick={onPlayTour}>
        <span className="mr-2 align-middle">{Texts.playTour}</span>
        <CollectionPlay size={22} />
      </PrimitiveButton>
    </div>
  );
}

Header.propTypes = {
  name: string.isRequired,
  onBack: func.isRequired,
  onPlayTour: func.isRequired,
};

export default Header;
