import http from 'utils/Http';

const buildUrl = (suffix) => `api/v1/metrics${suffix ? `${suffix}` : ''}`;

class MetricsService {
  demosCount = async (responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl('/demos'),
      });
      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  publishedDemos = async (demoId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/demo/${demoId}`),
      });

      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  topPageviews = async (lookbackDays, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/top-pageviews?lookbackDays=${lookbackDays}`),
      });

      responseHandlers.onSuccess(response.data.top_pageviews);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  topPageviewsPerSession = async (lookbackDays, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(
          `/top-pageviews-per-session?lookbackDays=${lookbackDays}`,
        ),
      });

      responseHandlers.onSuccess(response.data.top_pageviews_per_session);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  topShares = async (lookbackDays, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/top-shares?lookbackDays=${lookbackDays}`),
      });

      responseHandlers.onSuccess(response.data.top_shares);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  kpiMetrics = async (clientId, demoId, responseHandlers) => {
    let query = '';

    if (clientId && demoId) {
      query = `?client=${clientId}&demo=${demoId}`;
    } else if (clientId) {
      query = `?client=${clientId}`;
    } else if (demoId) {
      query = `?demo=${demoId}`;
    }

    try {
      const { data: response } = await http.get({
        url: buildUrl(`/kpi_metrics${query}`),
      });

      responseHandlers.onSuccess(response.data);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new MetricsService();
