/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { get } from 'lodash';
import userService from 'app/Services/UserCRUDService';
import UserForm from 'app/Forms/User';
import Shell from 'app/Shells/Management';
import AreYouSure from 'common/Dialogs/AreYouSure';
import DataTable from 'common/DataTable';
import SortableHeader from 'common/DataTable/SortableHeader';
import { useToasts } from 'common/ActionToast';
import SearchInput from 'common/Inputs/Search';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import { ThreeDots as ThreeDotsIcon } from 'react-bootstrap-icons';
import useSearchState from 'utils/useSearchState';
import useModalState from 'utils/useModalState';
// eslint-disable-next-line camelcase
import { to_MMDDYYYY } from 'utils/dates';
import Texts from './texts';
import './styles.scss';

function UserManagementView() {
  const modal = useModalState();
  const deleteUserConfirmation = useModalState();
  const search = useSearchState(userService.all, ['q'], undefined, {
    sort: [{ field: 'username', order: 'asc' }],
  });
  const toasts = useToasts();

  useEffect(() => {
    search.change('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (userForm, formikBag) => {
    const isUpdate = Boolean(userForm.id);
    const serviceMethod = isUpdate ? 'edit' : 'create';
    userService[serviceMethod](userForm, formikBag, {
      onSuccess: (resultUser) => {
        modal.close();
        search.reload();
        toasts.onAdd(
          resultUser.id,
          isUpdate ? Texts.updatedTitle : Texts.createdTitle,
          isUpdate
            ? Texts.updatedDescription(resultUser.props)
            : Texts.createdDescription(resultUser.props),
        );
      },
    });
  };

  const handleDelete = (user) => {
    const userId = user.id;
    userService.delete(userId, {
      onSuccess: () => {
        search.reload();
        toasts.onAdd(userId, Texts.deleteTitle, Texts.deleteDescription);
      },
      onError: () => {
        toasts.onAdd(
          userId,
          Texts.deleteFailedTitle,
          Texts.deleteFailedDescription,
        );
      },
    });
    deleteUserConfirmation.close();
  };

  return (
    <Shell page="Settings">
      {toasts.elements}
      <UserForm
        onCancel={modal.close}
        onSubmit={handleFormSubmit}
        user={modal.value}
        show={modal.show}
      />
      {deleteUserConfirmation.show && (
        <AreYouSure
          message={Texts.deleteUserDescription}
          onAccept={handleDelete}
          onCancel={deleteUserConfirmation.close}
          show={deleteUserConfirmation.show}
          title={Texts.deleteUserTitle}
          value={deleteUserConfirmation.value}
        />
      )}
      <div id="user-management">
        <div className="d-flex justify-content-between">
          <div>
            <SearchInput
              placeholder={Texts.searchPlaceholder}
              name="userSearch"
              onChange={search.change}
              value={search.query.qs}
            />
          </div>

          <Button size="md" onClick={modal.open}>
            {Texts.createUserTrigger}
          </Button>
        </div>
        <div>
          <DataTable
            onLimitChange={search.changeLimit}
            onPageChange={search.changePage}
            onReload={search.reload}
            total={get(search, 'query.result.total', 0)}
            page={search.query.page}
            limit={search.query.limit}
            isFetching={search.query.isLoading}
            hasError={search.query.error}
          >
            <thead>
              <tr>
                <SortableHeader
                  onChange={search.changeSort}
                  value="username"
                  current={search.query.sort}
                >
                  {Texts.usernameCol}
                </SortableHeader>
                <th>{Texts.emailCol}</th>
                <SortableHeader
                  onChange={search.changeSort}
                  value="role"
                  current={search.query.sort}
                >
                  {Texts.roleCol}
                </SortableHeader>
                <SortableHeader
                  onChange={search.changeSort}
                  value="created_at"
                  current={search.query.sort}
                >
                  {Texts.dateCol}
                </SortableHeader>
                <th />
              </tr>
            </thead>
            {!search.query.isLoading && (
              <tbody>
                {get(search, 'query.result.data', []).map((user) => (
                  <tr key={user.id}>
                    <td className="font-weight-bold" data-id={user.id}>
                      {user.username}
                    </td>
                    <td className="link">{user.email}</td>
                    <td>{Texts[user.role]}</td>
                    <td>{to_MMDDYYYY(user.created_at)}</td>
                    <td>
                      <FloatingMenuState>
                        {({ show, open }) => (
                          <>
                            <ThreeDotsIcon
                              onClick={open}
                              className="cursor-pointer"
                            />
                            {show && (
                              <FloatingMenu>
                                <Card className="py-1">
                                  <FloatinMenuOption
                                    onClick={modal.open}
                                    value={user}
                                  >
                                    Edit
                                  </FloatinMenuOption>
                                  <FloatinMenuOption
                                    className="text-danger"
                                    value={user}
                                    onClick={deleteUserConfirmation.open}
                                  >
                                    Delete
                                  </FloatinMenuOption>
                                </Card>
                              </FloatingMenu>
                            )}
                          </>
                        )}
                      </FloatingMenuState>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </DataTable>
        </div>
      </div>
    </Shell>
  );
}

export default UserManagementView;
