import React, { useState } from 'react';
import { Formik } from 'formik';
import { bool, func, shape } from 'prop-types';
import { get } from 'lodash';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import Checkbox from 'common/Inputs/Checkboxes/Checkbox';
import SubmitErrorMessage from 'common/Message/SubmitError';
import SwitchCase from 'common/SwitchCase';
import { ExperienceType } from 'utils/constants/demo';
import useFilterLoader from 'utils/useFilterLoader';
import filtersService from 'app/Services/FiltersService';
import MultiCreatableSelect from 'common/Inputs/MultiCreatableSelect';
import Texts from './texts';
import './styles.scss';
import Summary from './components/Summary';

const validationSchema = yup.object({
  client: yup
    .object({ name: yup.string() })
    .nullable()
    .test('clientCustomTest', null, (obj) => {
      if (obj && obj.name !== undefined) {
        return true;
      }

      return new yup.ValidationError(
        Texts.requiredError(Texts.clientNameLabel),
        null,
        'client',
      );
    }),
  experience_types: yup
    .object({
      [ExperienceType.GUIDED]: yup.boolean(),
      [ExperienceType.NON_GUIDED]: yup.boolean(),
    })
    .test('experienceTypesCustomTest', null, (obj) => {
      if (
        obj.GUIDED ||
        obj.NON_GUIDED ||
        (obj.GUIDED === undefined && obj.NON_GUIDED === undefined)
      ) {
        return true;
      }

      return new yup.ValidationError(
        'At least one Demo Type must be selected',
        null,
        'experience_types',
      );
    }),
});

const DISPLAYS = {
  FORM: 'FORM',
  SUMMARY: 'SUMMARY',
};

function PublishDemoForm({
  onCancel,
  onSubmit,
  onSuccess,
  publishedDemo,
  demo,
  show,
}) {
  const [display, setDisplay] = useState(DISPLAYS.FORM);
  const [result, setResult] = useState(undefined);

  const handleFinish = () => {
    onSuccess();
  };

  const handleFormSubmit = (publishedDemoForm, formikBag) => {
    const demoToPublish = {
      id: publishedDemoForm.id,
      demo_id: demo.id,
      tags: publishedDemoForm.tags,
      experience_types: publishedDemoForm.experience_types,
      client: publishedDemoForm.client,
    };
    formikBag.setSubmitting(false);
    onSubmit(demoToPublish, formikBag, {
      onSuccess: (published) => {
        const isEdit = demoToPublish.id !== null;

        if (isEdit) {
          handleFinish();
        } else {
          setResult(published);
          setDisplay(DISPLAYS.SUMMARY);
        }
      },
    });
  };

  const clientOptionsLoader = useFilterLoader(
    !publishedDemo.id,
    filtersService.clients,
  );
  const clientOptions = get(clientOptionsLoader, 'options', []);

  const tagOptionsLoader = useFilterLoader(true, filtersService.tags);
  const tagOptions = get(tagOptionsLoader, 'options', []);

  return (
    <Modal
      size={display === DISPLAYS.SUMMARY ? 'md' : 'sm'}
      show={show}
      onHide={display === DISPLAYS.SUMMARY ? handleFinish : onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {display === DISPLAYS.SUMMARY
            ? Texts.summaryTitle(get(demo, 'name'))
            : Texts.createTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={publishedDemo}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          handleSubmit,
          touched,
          errors,
          isSubmitting,
          values,
          setFieldValue,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <SwitchCase caseValue={DISPLAYS.SUMMARY} current={display}>
                  <Summary
                    published={result}
                    clientName={get(result, '[0].client.name')}
                  />
                </SwitchCase>
                <SwitchCase caseValue={DISPLAYS.FORM} current={display}>
                  <Form.Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="client">
                          {Texts.clientNameLabel}
                        </Form.Label>
                        <MultiCreatableSelect
                          isClearable
                          isDisabled={!!publishedDemo.id}
                          labelField="name"
                          name="client"
                          options={clientOptions}
                          placeholder="Clients"
                          setFieldValue={setFieldValue}
                          value={get(values, 'client')}
                          valueField="id"
                        />
                        {errors.client && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="display-feedback"
                          >
                            {errors.client}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>{Texts.demoNameLabel}</Form.Label>
                        <Form.Control
                          name="demo_name"
                          type="text"
                          value={demo.name}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="tags">
                          {Texts.tagsLabel}
                        </Form.Label>
                        <MultiCreatableSelect
                          allowMultipleOptions
                          labelField="name"
                          name="tags"
                          options={tagOptions}
                          setFieldValue={setFieldValue}
                          value={values.tags}
                          valueField="id"
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  {values.experience_types && (
                    <Form.Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>{Texts.demoTypes}</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="">
                        <Checkbox
                          className="d-flex flex-row-reverse experience-checkbox"
                          name="experience_types"
                          onChange={setFieldValue}
                          value={values.experience_types}
                          itemLabel={Texts[ExperienceType.GUIDED]}
                          option={ExperienceType.GUIDED}
                          id={ExperienceType.GUIDED}
                        />
                        <Checkbox
                          className="d-flex flex-row-reverse experience-checkbox"
                          name="experience_types"
                          onChange={setFieldValue}
                          value={values.experience_types}
                          itemLabel={Texts[ExperienceType.NON_GUIDED]}
                          option={ExperienceType.NON_GUIDED}
                          id={ExperienceType.NON_GUIDED}
                        />
                      </Col>
                      {touched.experience_types && errors.experience_types && (
                        <div className="d-block px-1 pb-1 invalid-feedback">
                          {errors.experience_types}
                        </div>
                      )}
                    </Form.Row>
                  )}
                </SwitchCase>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                {display === DISPLAYS.SUMMARY ? (
                  <Button onClick={handleFinish}>{Texts.finish}</Button>
                ) : (
                  <AcceptCancelButton
                    isProcessing={isSubmitting}
                    acceptLabel={
                      publishedDemo.id ? Texts.editLabel : Texts.acceptLabel
                    }
                    onCancel={onCancel}
                    onAccept={handleSubmit}
                  />
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

PublishDemoForm.propTypes = {
  demo: shape({}),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  onSuccess: func.isRequired,
  publishedDemo: shape({}),
  show: bool,
};

PublishDemoForm.defaultProps = {
  show: false,
  publishedDemo: {
    id: null,
    tags: [],
    experience_types: {
      GUIDED: false,
      NON_GUIDED: false,
    },
    client: undefined,
  },
  demo: {
    id: null,
    name: null,
  },
};

export default PublishDemoForm;
