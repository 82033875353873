import { get } from 'lodash';
import http from 'utils/Http';
import session from 'utils/Session';

const AuthenticateAppService = async (appId, handlers) => {
  try {
    const { data: loginResponse } = await http.post({
      url: 'auth_app/',
      body: { app_id: appId },
    });

    session.save(get(loginResponse, 'token', {}));
    session.setUser(get(loginResponse, 'user', {}));
    http.setToken(get(loginResponse, 'token.token', ''));

    const { data: accessResponse } = await http.get({
      url: 'app_access',
    });

    http.registerLoginInterceptors();
    session.setAccess(accessResponse);
    handlers.onSuccess({ login: loginResponse, access: accessResponse });
  } catch (err) {
    handlers.onError();
  }
};

export default AuthenticateAppService;
