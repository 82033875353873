import { EVENTS } from '../MessagePublisher';

const EventsSnippets = {
  initError: `// event.data
{
  message: '${EVENTS.INIT_ERROR}',
  error: "description of the error"
}
`,

  eventListener: `window.addEventListener('message', (event) => {
  // handling of the event
}, false);
`,

  publishedDemo: `// event.data
{
  message: '${EVENTS.PUBLISHED_DEMO_SUCCESS}',
  data: {
    experience_url: "URL of the published demo",
    demo: "name of the demo",
    client: {
      id: "id of the client",
      name: "name of the client"
    },
  }
}
`,

  handleExample: (domain) => `<script>
  window.addEventListener('message', (event) => {
    // Verifying the origin of the message
    if (event.origin === '${domain}') {
      switch (event.data.message) {
        case '${EVENTS.INIT_ERROR}':
          handleInitError(event.data.error);
          break;
        case '${EVENTS.PUBLISHED_DEMO_SUCCESS}':
          handlePublishedDemoSuccess(event.data.data);
          break;
        default:
          break;
      }
    }
  }, false);

  function handleInitError(error) {
    console.log(\`Init error: \${error}\`);
  }

  function handlePublishedDemoSuccess(data) {
    console.log(\`url: \${data.experience_url}\`);
    console.log(\`clientId: \${data.client.id}\`);
  }
</script>
`,
};

export default EventsSnippets;
