import React from 'react';
import { bool, node, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';

function ProcessingButton({
  children,
  className,
  processLabel,
  isProcessing,
  ...rest
}) {
  return (
    <Button
      {...rest}
      disabled={isProcessing}
      className={cx(className)}
      type="submit"
    >
      {isProcessing ? processLabel : children}
    </Button>
  );
}

ProcessingButton.propTypes = {
  children: node.isRequired,
  // eslint-disable-next-line react/require-default-props
  className: string,
  isProcessing: bool.isRequired,
  processLabel: string,
};

ProcessingButton.defaultProps = {
  processLabel: 'Saving...',
};

export default ProcessingButton;
