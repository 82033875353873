import guidedTourTemplateService from 'app/Services/GuidedTourTemplateCRUDService';
import guidedTourService from 'app/Services/GuidedTourCRUDService';
import { TourType } from 'utils/constants/demo';

function getTourService(type) {
  let tourService;

  switch (type) {
    case TourType.TEMPLATE:
      tourService = guidedTourTemplateService;
      break;
    case TourType.TOUR:
      tourService = guidedTourService;
      break;
    default:
      break;
  }

  return tourService;
}

export default getTourService;
