import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import reportsService from 'app/Services/ReportsService';
import useReportsState from './useReportsState';

function useDemosTopPageviews(clientId, demoId, startDate, endDate) {
  const demosTopPageviews = useReportsState(reportsService.topDemosPageviews);

  const shouldShowByFilters = useMemo(() => {
    const noFilter = !clientId && !demoId;
    const filteringByClient = !!clientId && !demoId;

    return noFilter || filteringByClient;
  }, [clientId, demoId]);

  const shouldShowByData = useMemo(() => {
    return !isEmpty(demosTopPageviews.metrics);
  }, [demosTopPageviews.metrics]);

  useEffect(() => {
    if (shouldShowByFilters) {
      demosTopPageviews.filter(clientId, demoId, startDate, endDate);
    }
  }, [shouldShowByFilters, clientId, demoId, startDate, endDate]);

  const load = () => {
    demosTopPageviews.load(clientId, demoId, startDate, endDate);
  };

  return {
    data: demosTopPageviews.metrics,
    isLoading: demosTopPageviews.isLoading,
    error: demosTopPageviews.error,
    show: shouldShowByFilters && shouldShowByData,
    load,
  };
}

export default useDemosTopPageviews;
