/**
 *  Import node modules
 */
import { useState } from 'react';
import { isFunction } from 'lodash';

function useToggleState(initial = false, { listeners = {} } = {}) {
  const [isActive, setToggle] = useState(initial);

  const changeToggle = (status) => {
    setToggle(status);
    if (isFunction(listeners.onToggle)) {
      listeners.onToggle(status);
    }
  };

  const handleSetToggle = (status) => changeToggle(status);

  const handleToggle = () => changeToggle(!isActive);

  const turnOn = () => changeToggle(true);
  const turnOff = () => changeToggle(false);

  return {
    isActive,
    onToggle: handleToggle,
    setToggle: handleSetToggle,
    turnOn,
    turnOff,
  };
}

export default useToggleState;
