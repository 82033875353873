import React from 'react';
import Alert from 'react-bootstrap/Alert';

function SubmitError(props) {
  return (
    <div className="d-flex justify-content-center">
      <Alert {...props} className="mt-2 d-inline" variant="danger" />
    </div>
  );
}
export default SubmitError;
