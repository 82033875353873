import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import ActionableUrl from '../ActionableUrl';
import Texts from './texts';

function Summary({ clientName, published }) {
  return (
    <div>
      <div className="mb-2">{`Client: ${clientName}`}</div>
      <div className="mb-2">
        <h6>Demo URL(s) below:</h6>
        {published.map((item) => (
          <ActionableUrl
            key={Texts[item.experience_type]}
            label={Texts[item.experience_type]}
            url={item.demo_unique_url}
          />
        ))}
      </div>
    </div>
  );
}

Summary.propTypes = {
  clientName: string.isRequired,
  published: arrayOf(shape({})).isRequired,
};

export default Summary;
