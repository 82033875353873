import React from 'react';
import NumberFormat from 'react-number-format';
import metricsService from 'app/Services/MetricsService';
import SummaryCard from '../SummaryCard';
import useCardState from '../SummaryCard/useCardState';

function TopPageViewsPerSession() {
  const state = useCardState(metricsService.topPageviewsPerSession);

  return (
    <SummaryCard
      data={state.metrics.map((item) => ({
        id: item.demo_id,
        label: item.demo_name,
        value: (
          <NumberFormat
            defaultValue={0}
            decimalScale={2}
            displayType="text"
            value={item.pageviews_per_session}
          />
        ),
      }))}
      error={state.error}
      isLoading={state.isLoading}
      onSelect={state.loadMetrics}
      title="Top pageviews per session"
    />
  );
}

export default TopPageViewsPerSession;
