export const DemoStatus = {
  ARCHIVED: 'ARCHIVED',
  UNARCHIVED: 'UNARCHIVED',
  DRAFT: 'DRAFT',
  COMPLETE: 'COMPLETE',
  IN_USE: 'IN_USE',
};

export const ExperienceType = {
  GUIDED: 'GUIDED',
  NON_GUIDED: 'NON_GUIDED',
};

export const TourType = {
  TEMPLATE: 'template',
  TOUR: 'tour',
};
