import { useState } from 'react';
import safeInvoke from 'utils/safeInvoke';

function useSyncSource(service, handlers = {}) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [error, setError] = useState(undefined);

  const sync = () => {
    setIsSyncing(true);
    service({
      onSuccess: () => {
        setIsSyncing(false);
        setError(false);
        safeInvoke(handlers.onSuccess);
      },
      onError: () => {
        setIsSyncing(false);
        setError(true);
        safeInvoke(handlers.onError);
      },
    });
  };

  return {
    isSyncing,
    error,
    sync,
  };
}

export default useSyncSource;
