import { join } from 'lodash';

const transformToFilterString = (filterFields) => {
  const filtersMap = filterFields || {};
  const filterString = Object.keys(filtersMap)
    .filter((key) => filtersMap[key] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(filtersMap[key])}`,
    )
    .join('&');

  return filterString;
};

const transformToQueryString = (
  queryInput,
  searchFieldList,
  filterFieldList,
  options,
) => {
  const queries = queryInput
    ? `${searchFieldList.join(`=${queryInput}&`)}=${queryInput}`
    : '';

  const filter = transformToFilterString(filterFieldList);

  const sort = options.sort
    ? `sort=${options.sort
        .map((sortOption) => `${sortOption.field}:${sortOption.order}`)
        .join(',')}`
    : '';
  const page = options.page ? `page=${options.page}` : '';
  const limit = options.limit ? `limit=${options.limit}` : '';
  const querystring = join(
    [queries, filter, sort, limit, page].filter((e) => e !== ''),
    '&',
  );

  return `?${querystring}`;
};

export default transformToQueryString;
