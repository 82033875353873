import React, { useContext, useMemo } from 'react';
import { bool, func, shape } from 'prop-types';
import { Formik, Field } from 'formik';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as yup from 'yup';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import TextEditor from 'common/Editors/TextEditor';

import ThemeContext from '../../ThemeStyle/ThemeContext';
import PlacementInput from '../PlacementInput';
import Texts from './texts';
import './styles.scss';

const validationSchema = yup.object({
  description: yup.string().nullable(),
  imageUrl: yup.string().nullable(),
  event: yup.string().required().oneOf(['click', 'timer', 'commentary']),
});

const INITIAL = {
  description: null,
  placement: 'below',
  event: 'click',
  transitionTime: 2,
  header: null,
  footer: null,
};

function HotspotForm({ value, show, onSuccess, onCancel }) {
  const close = () => {
    onCancel();
  };

  const handleOnSubmit = (form) => {
    const submittedForm = form;
    onSuccess(submittedForm);
    close();
  };

  const { theme } = useContext(ThemeContext);

  const editorStyle = useMemo(() => {
    const background = `background: ${theme.background};`;
    const color = `color: ${theme.color};`;
    const fontFamily = `font-family: ${theme.fontFamily};`;
    const fontSize = `font-size: ${theme.fontSize};`;

    return `body { ${background} ${color} ${fontFamily} ${fontSize} }`;
  }, [theme]);

  return (
    <Modal show={show} onHide={close} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{Texts.title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          ...INITIAL,
          ...value,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label
                        htmlFor="description"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span className="mr-3">{Texts.guideLabel}</span>
                        <PlacementInput
                          name="placement"
                          value={values.placement}
                          onChange={handleChange}
                          label={Texts.placementLabel}
                        />
                      </Form.Label>
                      <TextEditor
                        name="description"
                        initialValue={values.description}
                        setFieldValue={setFieldValue}
                        style={editorStyle}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Group as={Row}>
                  <Form.Label column sm={2} htmlFor="header">
                    {Texts.header}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="header"
                      type="text"
                      value={values.header}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={2} htmlFor="footer">
                    {Texts.footer}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="footer"
                      type="text"
                      value={values.footer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Form.Group>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="event">
                        {Texts.eventLabel}
                      </Form.Label>
                      <div>
                        <Form.Label className="mr-3">
                          <Field
                            className="mr-1"
                            type="radio"
                            name="event"
                            value="click"
                          />
                          <span>Click</span>
                        </Form.Label>
                        <Form.Label className="mr-3">
                          <Field
                            className="mr-1"
                            type="radio"
                            name="event"
                            value="commentary"
                          />
                          <span>Commentary</span>
                        </Form.Label>
                        <Form.Label htmlFor>
                          <Field
                            className="mr-1"
                            type="radio"
                            name="event"
                            value="timer"
                          />
                          <span>Timer</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          className="timer-select"
                          disabled={values.event !== 'timer'}
                          name="transitionTime"
                          value={values.transitionTime}
                          onChange={handleChange}
                          size="sm"
                          custom
                        >
                          {[...Array(10).keys()].map((i) => (
                            <option value={i + 1}>{i + 1}s</option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <AcceptCancelButton
                  acceptLabel={Texts.editLabel}
                  onCancel={close}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

HotspotForm.propTypes = {
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
  show: bool,
  value: shape({}),
};

HotspotForm.defaultProps = {
  show: false,
  value: {
    description: null,
    event: 'click',
    transitionTime: 2,
    header: null,
    footer: null,
  },
};

export default HotspotForm;
