import React, { useState, useMemo } from 'react';
import { string, func, shape, arrayOf, node } from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import Form from 'react-bootstrap/Form';
import { PlusCircleFill } from 'react-bootstrap-icons';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Expandable from 'common/Animations/Expandable';
import ExpandedMenuItem from 'common/ExpandedMenuItem';
import PrimitiveButton from 'common/Buttons/Primitive';
import FormatMenu from 'common/Editors/TextEditor/FormatMenu';

import ThemeStyle from '../../ThemeStyle';
import stylejss from './stylejss';
import DraggableStep from './DraggableStep';
import DescriptionItemButton from './DescriptionItemButton';
import Texts from './texts';
import './styles.scss';

const FORMAT = 'format';
const STEPS = 'steps';

function StepsInput({
  tour,
  onAddStep,
  onEditIntro,
  onEditConclusion,
  onToggleFlowArrowNavigation,
  onRearrangeStep,
  onSelectedStep,
  onDeleteStep,
}) {
  const [expanded, setExpanded] = useState({
    [FORMAT]: false,
    [STEPS]: true,
  });
  const flow = tour.getCurrentFlow();
  const { name: flowName, stepIds = [] } = flow;
  const { stepId: activeStep } = tour.getHead();
  const steps = tour.getSteps();

  const flowSteps = useMemo(
    () => {
      return stepIds
        .filter((itemId) => steps[itemId])
        .map((itemId, index) => (
          <DraggableStep
            key={itemId}
            activeStep={activeStep}
            draggableId={itemId}
            index={index}
            value={steps[itemId]}
            onSelected={onSelectedStep}
            onDelete={onDeleteStep}
          />
        ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tour],
  );

  const handleStepDrop = ({ destination, draggableId, source }) => {
    if (destination === null) {
      return;
    }

    onRearrangeStep(draggableId, source.index, destination.index);
  };

  const handleToggleFormat = (status) => {
    setExpanded({ ...expanded, [FORMAT]: status });
  };

  const handleToggleSteps = (status) => {
    setExpanded({ ...expanded, [STEPS]: status });
  };

  return (
    <div className="steps-input-control">
      {flowName && (
        <div className="d-flex justify-content-end align-items-center mb-2">
          <div className="mr-2">{Texts.arrowNavigation}</div>
          <Form.Switch
            id="arrow-keys-nav"
            label=""
            checked={!!flow.arrowNavigation}
            onChange={onToggleFlowArrowNavigation}
          />
        </div>
      )}
      <Expandable
        expanded={expanded[FORMAT]}
        onToggle={handleToggleFormat}
        className=""
        trigger={
          <ExpandedMenuItem active={expanded[FORMAT]} label="Formatting" />
        }
        content={
          <div className="px-1 py-2">
            <ThemeStyle>
              {(context) => (
                <FormatMenu onChange={context.setValue} value={context.value} />
              )}
            </ThemeStyle>
          </div>
        }
      />
      <Expandable
        expanded={expanded[STEPS]}
        onToggle={handleToggleSteps}
        className=""
        trigger={<ExpandedMenuItem active={expanded[STEPS]} label="Steps" />}
        content={
          flowName && (
            <ThemeStyle style={stylejss}>
              <div className="steps-wrapper">
                <DescriptionItemButton
                  className="step-list-intro"
                  onClick={onEditIntro}
                  label="Create Introduction"
                  value={flow}
                  description={get(flow, 'intro') && 'Introduction'}
                />
                <div className="step-droppable-container">
                  <DragDropContext onDragEnd={handleStepDrop}>
                    <Droppable droppableId="steps-droppable">
                      {(provided) => (
                        <div
                          className="step-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {flowSteps}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <PrimitiveButton
                  className={cx('add-step-button cursor-pointer px-2 py-2')}
                  onClick={onAddStep}
                >
                  <div className="add-step-icon d-inline-block mr-2">
                    <PlusCircleFill />
                  </div>
                  <span>Add Step</span>
                </PrimitiveButton>
                <DescriptionItemButton
                  className="step-list-conclusion"
                  onClick={onEditConclusion}
                  label="Create Closure"
                  description={get(flow, 'conclusion') && 'Conclusion'}
                  value={flow}
                />
              </div>
            </ThemeStyle>
          )
        }
      />
    </div>
  );
}

StepsInput.propTypes = {
  flowOptions: arrayOf({ label: string, value: string }),
  onAddStep: func.isRequired,
  onDeleteStep: func.isRequired,
  onEditConclusion: func.isRequired,
  onEditIntro: func.isRequired,
  onRearrangeStep: func.isRequired,
  onSelectedStep: func.isRequired,
  onToggleFlowArrowNavigation: func.isRequired,
  statusComponent: node.isRequired,
  tour: shape({}).isRequired,
};

StepsInput.defaultProps = {
  flowOptions: [],
};

export default StepsInput;
