import { get } from 'lodash';

import Texts from './texts';

export function handleFormErrors(error, formikBag) {
  if (get(error, 'response.status') === 422) {
    formikBag.setErrors(get(error, 'response.data', {}));
  } else {
    formikBag.setErrors({
      internalServerError: Texts.internalServerError,
    });
  }
}
