import { get } from 'lodash';

function toPercentage(base, parent) {
  const converted = (base * 100) / parent;
  const result = converted ? `${converted}%` : `${base}px`;
  return result;
}

function toPixel(base, parent) {
  const converted = (base * parent) / 100;
  return converted;
}

function getRefDimensions(ref) {
  return {
    height: get(ref, 'current.offsetHeight', 0),
    width: get(ref, 'current.offsetWidth', 0),
  };
}

function useRelativeToParentConversions(
  parentRef,
  { width: baseWidth, height: baseHeight },
  percentageHandler,
) {
  const convertToPercentage = (coords) => {
    const { x, y } = coords;
    const width = coords.width ? parseFloat(coords.width) : baseWidth;
    const height = coords.height ? parseFloat(coords.height) : baseHeight;
    const { width: parentWidth, height: parentHeight } = getRefDimensions(
      parentRef,
    );
    const convertedWidth = toPercentage(width, parentWidth);
    const convertedHeight = toPercentage(height, parentHeight);
    const convertedX = toPercentage(x, parentWidth);
    const convertedY = toPercentage(y, parentHeight);
    const convertedCoords = {
      ...coords,
      x: convertedX,
      y: convertedY,
      width: convertedWidth,
      height: convertedHeight,
    };
    return percentageHandler(convertedCoords);
  };

  const convertToPixel = (coords) => {
    if ((coords.width || '').includes('px')) {
      return coords;
    }
    const { x, y } = coords;
    const xFloat = parseFloat(x);
    const yFloat = parseFloat(y);
    const width = coords.width ? parseFloat(coords.width) : 100;
    const height = coords.height ? parseFloat(coords.height) : 100;
    const { width: parentWidth, height: parentHeight } = getRefDimensions(
      parentRef,
    );
    const convertedWidth = toPixel(width, parentWidth);
    const convertedHeight = toPixel(height, parentHeight);
    const convertedX = toPixel(xFloat, parentWidth);
    const convertedY = toPixel(yFloat, parentHeight);
    const convertedCoords = {
      ...coords,
      x: convertedX,
      y: convertedY,
      width: `${convertedWidth}px`,
      height: `${convertedHeight}px`,
    };
    return convertedCoords;
  };

  return {
    convertToPercentage,
    convertToPixel,
  };
}

export default useRelativeToParentConversions;
