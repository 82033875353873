/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import Button from 'common/Buttons/Button';
import DataTable from 'common/DataTable';
import SortableHeader from 'common/DataTable/SortableHeader';
import SearchInput from 'common/Inputs/Search';
import OverlayLink from 'common/Overlay/OverlayLink';
import { useToasts } from 'common/ActionToast';
import embeddablesRouterMap from 'app/Embeddables/EmbeddablesRouterMap';

import useQueryString from 'utils/useQueryString';
import Texts from './texts';
import './styles.scss';
import useDemosState from './useDemosState';
import { EVENTS, messageParent } from '../MessagePublisher';

const buildMessageData = (demo, publishedDemo) => ({
  experience_url: publishedDemo.demo_unique_url,
  demo: demo.name,
  client: {
    id: publishedDemo.client.external_id,
    name: publishedDemo.client.name,
  },
});

function DemosEmbeddable() {
  const toasts = useToasts();
  const history = useHistory();
  const query = useQueryString();
  const clientId = query.get('client_id');
  const demosState = useDemosState(clientId);

  const publishDemo = (demo) => {
    const handlers = {
      onSuccess: (publishedDemo) => {
        messageParent({
          message: EVENTS.PUBLISHED_DEMO_SUCCESS,
          data: buildMessageData(demo, publishedDemo),
        });
        history.push(
          embeddablesRouterMap.publishedDemoDetails.getPath(
            demo,
            publishedDemo,
          ),
        );
      },
      onError: () => {
        toasts.onAdd(
          demo.id,
          Texts.errorSharingTitle,
          Texts.errorSharingDescription,
        );
      },
    };

    demosState.publish(demo, handlers);
  };

  // Only for dev purposes
  const postDevMessage = (demo) => {
    messageParent({
      message: 'DEV_MESSAGE',
      data: { demo: demo.name, demo_unique_url: demo.demo_unique_url },
    });
  };

  return (
    <div className="p-4" id="demo-library">
      {toasts.elements}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div>
            <SearchInput
              placeholder="Search for demos"
              name="demoSearch"
              onChange={demosState.search}
              value={demosState.query.qs}
            />
          </div>
        </div>
      </div>

      <div>
        <DataTable
          onLimitChange={demosState.changeLimit}
          onPageChange={demosState.changePage}
          onReload={demosState.reload}
          total={get(demosState, 'query.result.total', 0)}
          page={demosState.query.page}
          limit={demosState.query.limit}
          isFetching={demosState.query.isLoading}
          hasError={demosState.query.error}
        >
          <thead>
            <tr>
              <SortableHeader
                onChange={demosState.changeSort}
                value="name"
                current={demosState.query.sort}
              >
                Demo Name
              </SortableHeader>
              <th>Demo Script</th>
              <th />
              <th />
            </tr>
          </thead>
          {!demosState.query.isLoading && (
            <tbody>
              {demosState.allDemos.map((demo) => (
                <tr key={demo.id}>
                  <td className="bold">
                    <span>{demo.name}</span>
                  </td>
                  <td>
                    <OverlayLink
                      displayText={Texts.demoScript}
                      key={demo.id}
                      url={demo.script_url}
                    />
                  </td>
                  <td>
                    {demo.shared ? (
                      <OverlayLink
                        displayText={demo.hash_id}
                        key={demo.id}
                        url={demo.demo_unique_url}
                      />
                    ) : (
                      <Button
                        variant="primary"
                        className="mr-2"
                        onClick={publishDemo}
                        value={demo}
                      >
                        {Texts.shareLabel}
                      </Button>
                    )}
                  </td>
                  <td>
                    {demo.shared && (
                      <Button
                        variant="primary"
                        className="mr-2"
                        onClick={postDevMessage}
                        value={demo}
                      >
                        Create Activity
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </DataTable>
      </div>
    </div>
  );
}

export default DemosEmbeddable;
