import http from 'utils/Http';

import { handleFormErrors } from './utils';

const buildUrl = (suffix) => `api/v1/accounts${suffix ? `${suffix}` : ''}`;

class AccountService {
  changePassword = async (changePasswordForm, formikBag, responseHandlers) => {
    try {
      await http.post({
        url: buildUrl(`/change_password`),
        body: changePasswordForm,
      });

      responseHandlers.onSuccess();
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default new AccountService();
