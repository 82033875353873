const Texts = {
  clientNameLabel: 'Client Name',
  demoNameLabel: 'Demo',
  demoTypes: 'Demo Type(s)',
  tagsLabel: 'Tags',
  acceptLabel: 'Publish',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  editTitle: 'Edit Demo',
  createTitle: 'Publish Demo',
  requiredError: (name) => `${name} is required`,
  GUIDED: 'Guided',
  NON_GUIDED: 'Non-Guided',
  publishedTitle: 'Demo published',
  finish: 'Finish',
  summaryTitle: (demoName) => `${demoName} Demo Receipt`,
  publishedDescription: (name) => `Published demo for ${name}`,
};

export default Texts;
