import { useState, useCallback } from 'react';

const useMutation = (service, handlers) => {
  const [inProcess, setProcess] = useState(false);
  const [error, setError] = useState(undefined);
  const mutate = useCallback(
    async (params) => {
      try {
        setProcess(true);
        await service(params, handlers);
        setProcess(false);
        setError(undefined);
      } catch (responseError) {
        setProcess(false);
        setError(responseError);
      }
    },
    // disable as initialResults ref is not kept
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [service, setProcess],
  );
  return {
    mutate,
    inProcess,
    error,
  };
};

export default useMutation;
