import React from 'react';
import { func, shape, string } from 'prop-types';
import Form from 'react-bootstrap/Form';
import { merge } from 'utils/immutable';

const Checkbox = ({ itemLabel, option, ...props }) => {
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = !props.value[option];
    const updatedProp = { [option]: newValue };
    const updatedValue = merge(updatedProp, props.value);

    props.onChange(name, updatedValue);
  };
  const { value } = props;

  return (
    <Form.Check
      {...props}
      type="checkbox"
      onChange={handleChange}
      value={value[option]}
      checked={value[option]}
      label={itemLabel}
    />
  );
};

Checkbox.propTypes = {
  itemLabel: string,
  name: string,
  onChange: func.isRequired,
  option: string.isRequired,
  value: shape({}).isRequired,
};

Checkbox.defaultProps = {
  itemLabel: '',
  name: '',
};

export default Checkbox;
