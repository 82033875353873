import React from 'react';
import { func, node, any } from 'prop-types';

function PrimitiveButton({ children, onClick, value, ...rest }) {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(value);
  };

  return (
    <div
      {...rest}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex="0"
    >
      {children}
    </div>
  );
}

PrimitiveButton.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
  value: any,
};

PrimitiveButton.defaultProps = {
  value: null,
};

export default PrimitiveButton;
