import React from 'react';
import { ChatSquareDotsFill } from 'react-bootstrap-icons';
import { any, string, func } from 'prop-types';
import cx from 'classnames';
import PrimitiveButton from 'common/Buttons/Primitive';

const inlineStepDecor = 'd-inline-block mr-2 icon-option';
const itemsClasses = 'step-option cursor-pointer px-2 py-3';

const inlineIcon = cx(inlineStepDecor, 'icon-option');

function DescriptionItemButton({
  label,
  description,
  className,
  value,
  onClick,
  ...rest
}) {
  return (
    <PrimitiveButton
      {...rest}
      value={value}
      className={cx(itemsClasses, className)}
      onClick={onClick}
    >
      <div className={cx(inlineIcon, description && 'bg-secondary')}>
        <ChatSquareDotsFill className={cx(description && 'text-white')} />
      </div>
      <span>{description || label}</span>
    </PrimitiveButton>
  );
}

DescriptionItemButton.propTypes = {
  className: string,
  description: string,
  label: string.isRequired,
  onClick: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

DescriptionItemButton.defaultProps = {
  className: null,
  description: null,
  value: null,
};

export default DescriptionItemButton;
