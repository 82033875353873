import React from 'react';
import { func, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import session from 'utils/Session';
import routerMap from 'app/RouterMap';

function PrivateRoute({ component: Component, requiredRole, ...rest }) {
  const token = session.get('token');
  const isAuthenticated = token;

  const getRoute = (props) => {
    if (!isAuthenticated) {
      return <Redirect to={routerMap.login.getPath()} />;
    }

    if (requiredRole && !session.hasRole(requiredRole)) {
      return <Redirect to={routerMap.dash.getPath()} />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={(props) => getRoute(props)} />;
}

PrivateRoute.propTypes = {
  component: func.isRequired,
  requiredRole: string,
};

PrivateRoute.defaultProps = {
  requiredRole: undefined,
};

export default PrivateRoute;
