const TARGET_ORIGIN_ALLOW_ALL = '*';

export const EVENTS = {
  INIT_ERROR: 'INIT_ERROR',
  PUBLISHED_DEMO_SUCCESS: 'PUBLISHED_DEMO_SUCCESS',
};

export function messageParent(message) {
  window.parent.postMessage(message, TARGET_ORIGIN_ALLOW_ALL);
}
