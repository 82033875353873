function toFlowOptions(tourMap) {
  const flows = tourMap.getFlows();
  const flowIds = tourMap.getFlowIds();
  const flowOptions = flowIds.map((id) => {
    const match = flows[id];
    const options = {
      label: match.name,
      value: match,
    };
    return options;
  });

  return flowOptions;
}

export default toFlowOptions;
