/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unused-modules */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { any, func, node, string } from 'prop-types';
import cx from 'classnames';
import ClickOutside from 'common/ClickOutside';
import './styles.scss';

const FloatingMenuState = ({ children }) => {
  const [show, setShow] = useState(false);
  const close = () => setShow(false);
  const open = () => setShow(true);
  const api = { open, close, show };
  return (
    <ClickOutside onClickOutside={close}>
      <div>{children(api)}</div>
    </ClickOutside>
  );
};

FloatingMenuState.propTypes = {
  children: func.isRequired,
};

const FloatingMenu = ({ children }) => {
  return (
    <div className="demo-popover-menu-wrapper">
      <div className="demo-popover-menu">{children}</div>
    </div>
  );
};

FloatingMenu.propTypes = {
  children: node.isRequired,
};

const FloatinMenuOption = ({ children, onClick, value, className }) => {
  const handleClick = () => onClick(value);
  return (
    <div
      onClick={handleClick}
      className={cx(className, 'cursor-pointer pl-3 pr-5 py-1 ')}
    >
      {children}
    </div>
  );
};

FloatinMenuOption.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func.isRequired,
  value: any,
};

FloatinMenuOption.defaultProps = {
  value: undefined,
  className: '',
};

export { FloatingMenuState, FloatinMenuOption };
export default FloatingMenu;
