import React from 'react';
import { hot } from 'react-hot-loader';
import routerMap from 'app/RouterMap';
import embeddablesRouterMap from 'app/Embeddables/EmbeddablesRouterMap';
import DashboardView from 'app/Views/Dashboard';
import LibraryView from 'app/Views/Library';
import LoginView from 'app/Views/Login';
import UserManagementView from 'app/Views/UserManagement';
import ExperienceEditorView from 'app/Views/ExperienceEditor';
import PublishedDemosView from 'app/Views/PublishedDemos';
import ForgotUsernameView from 'app/Views/ForgotUsername';
import ResetPasswordView from 'app/Views/ResetPassword';
import ReportsView from 'app/Views/Reports';
import UserProfileView from 'app/Views/UserProfile';

import InitEmbed from 'app/Embeddables/InitEmbed';
import DemosEmbeddable from 'app/Embeddables/Demos';
import EmbedDocs from 'app/Embeddables/Docs';
import PublishedDemoDetailsEmbeddable from 'app/Embeddables/PublishedDemoDetails';

import NotFound from 'common/Routes/NotFound';
import PrivateRoute from 'common/Routes/PrivateRoute';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function Main() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path={routerMap.dash.path}
            component={DashboardView}
          />
          <PrivateRoute
            exact
            path={routerMap.library.path}
            component={LibraryView}
          />
          <PrivateRoute
            exact
            path={routerMap.demo.path}
            component={PublishedDemosView}
          />
          <PrivateRoute
            exact
            path={routerMap.editor.path}
            component={ExperienceEditorView}
          />
          <PrivateRoute
            exact
            path={routerMap.settings.path}
            component={UserManagementView}
            requiredRole="admin"
          />
          <PrivateRoute
            exact
            path={routerMap.reports.path}
            component={ReportsView}
          />
          <PrivateRoute
            exact
            path={routerMap.userProfile.path}
            component={UserProfileView}
          />
          <Route exact path={routerMap.login.path} component={LoginView} />
          <Route
            exact
            path={routerMap.forgotUsername.path}
            component={ForgotUsernameView}
          />
          <Route
            exact
            path={routerMap.resetPassword.path}
            component={ResetPasswordView}
          />

          <Route
            exact
            path={embeddablesRouterMap.init.path}
            component={InitEmbed}
          />
          <Route
            exact
            path={embeddablesRouterMap.demos.path}
            component={DemosEmbeddable}
          />
          <Route
            exact
            path={embeddablesRouterMap.publishedDemoDetails.path}
            component={PublishedDemoDetailsEmbeddable}
          />
          <Route
            exact
            path={embeddablesRouterMap.docs.path}
            component={EmbedDocs}
          />
          <NotFound />
        </Switch>
      </Router>
    </div>
  );
}

export default hot(module)(Main);
