import React, { useState, useEffect } from 'react';
import { bool, func, shape } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import DemoSelection from './components/DemoSelection';
import DemoDetails from './components/DemoDetails';
import AddDemoSteps from './constants';
import Texts from './texts';

function DemoWizard({ demo, show, onCancel, onSubmit }) {
  const [activeStep, setActiveStep] = useState(AddDemoSteps.DEMO_SELECTION);
  const [demoForm, setDemoForm] = useState(demo);

  useEffect(() => {
    setActiveStep(
      demo.id ? AddDemoSteps.DEMO_DETAILS : AddDemoSteps.DEMO_SELECTION,
    );
    setDemoForm(demo);
  }, [demo.id, demo, show]);

  const nextStep = (step) => {
    setActiveStep(step);
  };

  const setDemoData = (demoData) => {
    setDemoForm({
      ...demoForm,
      ...demoData,
    });
  };

  return (
    <Modal size="md" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {demo.id ? Texts.editTitle : Texts.createTitle}
        </Modal.Title>
      </Modal.Header>
      {activeStep === AddDemoSteps.DEMO_SELECTION && (
        <DemoSelection next={nextStep} onDemoSelect={setDemoData} />
      )}
      {activeStep === AddDemoSteps.DEMO_DETAILS && (
        <DemoDetails
          next={nextStep}
          demoValues={demoForm}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
}

DemoWizard.propTypes = {
  demo: shape({}),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

DemoWizard.defaultProps = {
  show: false,
  demo: {
    id: null,
    name: null,
    is_ready: false,
    tags: [],
    product_type: 'WEB',
  },
};

export default DemoWizard;
