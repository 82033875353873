import React, { useMemo } from 'react';
import { func, string } from 'prop-types';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

import { Editor } from '@tinymce/tinymce-react';
import { FONT_FORMAT, FONT_SIZE_FORMAT } from './constants';

const INIT = {
  skin: false,
  content_css: false,
  content_style:
    'body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }',
  branding: false,
  statusbar: false,
  menubar: false,
  font_formats: FONT_FORMAT,
  fontsize_formats: FONT_SIZE_FORMAT,
  height: 200,
  plugins: 'paste',
  toolbar:
    'fontselect fontsizeselect forecolor backcolor removeformat | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | formatselect | undo redo',
  paste_as_text: true,
};

function TextEditor({ initialValue, name, setFieldValue, style }) {
  const handleEditorChange = (content) => {
    setFieldValue(name, content);
  };

  const initConfig = useMemo(
    () => ({
      ...INIT,
      ...(style && { content_style: style }),
    }),
    [style],
  );

  return (
    <Editor
      initialValue={initialValue}
      init={initConfig}
      onEditorChange={handleEditorChange}
    />
  );
}

TextEditor.propTypes = {
  initialValue: string,
  name: string.isRequired,
  setFieldValue: func.isRequired,
  style: string,
};

TextEditor.defaultProps = {
  initialValue: '',
  style: undefined,
};

export default TextEditor;
