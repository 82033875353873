import React, { useContext, useMemo } from 'react';
import { func, node, oneOfType } from 'prop-types';
import { isFunction } from 'lodash';

import { hexToRGBA } from 'utils/rgba';

import ThemeContext from './ThemeContext';

function ThemeStyle({ children, style }) {
  const { theme, onChange } = useContext(ThemeContext);
  const compiled = useMemo(() => {
    const isEmpty =
      !theme.color && !theme.background && !theme.fontSize && !theme.fontFamily;

    return !isEmpty
      ? style({
          ...theme,
          background: hexToRGBA(theme.background),
          color: hexToRGBA(theme.color),
        })
      : '';
  }, [style, theme]);
  return (
    <>
      {compiled && (
        <style type="text/css" data-theme>
          {compiled}
        </style>
      )}

      {isFunction(children)
        ? children({ value: theme, setValue: onChange })
        : children}
    </>
  );
}

ThemeStyle.propTypes = {
  children: oneOfType([func, node]).isRequired,
  style: func,
};

ThemeStyle.defaultProps = {
  style: () => {},
};

export default ThemeStyle;
