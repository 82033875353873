import React from 'react';
import { Formik } from 'formik';
import { bool, func } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import Texts from './texts';
import './styles.scss';

const validationSchema = yup.object({
  current_password: yup
    .string()
    .required(Texts.requiredError(Texts.currentPasswordLabel)),
  new_password: yup
    .string()
    .min(6, Texts.minPasswordLength)
    .required(Texts.requiredError(Texts.currentPasswordLabel)),
  new_password_confirmation: yup.string().when('new_password', {
    is: (val) => val && val.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('new_password')], Texts.passwordMustMatch)
      .required(Texts.requiredError(Texts.newPasswordConfirmationLabel)),
  }),
});

function ChangePasswordForm({ onCancel, onSubmit, show }) {
  return (
    <Modal size="sm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{Texts.changePasswordTitle}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          touched,
          errors,
          isSubmitting,
          values,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="current_password">
                        {Texts.currentPasswordLabel}
                      </Form.Label>
                      <Form.Control
                        name="current_password"
                        type="password"
                        value={values.current_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.current_password && errors.current_password
                        }
                      />
                      {touched.current_password && errors.current_password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.current_password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="new_password">
                        {Texts.newPasswordLabel}
                      </Form.Label>
                      <Form.Control
                        name="new_password"
                        type="password"
                        value={values.new_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.new_password && errors.new_password}
                      />
                      {touched.new_password && errors.new_password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.new_password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="new_password_confirmation">
                        {Texts.newPasswordConfirmationLabel}
                      </Form.Label>
                      <Form.Control
                        name="new_password_confirmation"
                        type="password"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.new_password_confirmation &&
                          errors.new_password_confirmation
                        }
                      />
                      {touched.new_password_confirmation &&
                        errors.new_password_confirmation && (
                          <Form.Control.Feedback type="invalid">
                            {errors.new_password_confirmation}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={Texts.changePasswordLabel}
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

ChangePasswordForm.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

ChangePasswordForm.defaultProps = {
  show: false,
};

export default ChangePasswordForm;
