import React from 'react';
import Form from 'react-bootstrap/Form';

function SwitchValue({ value, id, checked, onChange, disabled }) {
  const handleClick = () => onChange(value, checked);

  return (
    <Form.Switch
      id={id}
      label=""
      checked={checked}
      onChange={handleClick}
      disabled={disabled}
    />
  );
}

export default SwitchValue;
