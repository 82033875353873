import { debounce } from 'lodash';
import http from 'utils/Http';

const buildUrl = (suffix) =>
  `api/v1/guided_tours_templates${suffix ? `${suffix}` : ''}`;

class GuidedTourTemplateCRUD {
  constructor() {
    this.debouncedEdit = debounce(this.edit, 1000);
  }

  static mapGuidedTourTo = (guidedTour) => {
    return {
      id: guidedTour.tourId,
      tour: guidedTour.tour,
      demo_id: parseInt(guidedTour.tour.demoId, 10),
    };
  };

  static mapGuidedTourFrom = (serverGuidedTour) => {
    return {
      tourId: serverGuidedTour.id,
      tour: serverGuidedTour.props.tour,
    };
  };

  edit = async (guidedTourTemplate, responseHandlers) => {
    try {
      const { data: updatedTour } = await http.put({
        url: buildUrl(`/${guidedTourTemplate.tourId}`),
        body: GuidedTourTemplateCRUD.mapGuidedTourTo(guidedTourTemplate),
      });
      responseHandlers.onSuccess(updatedTour);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  byId = async (guidedTourTemplateId, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(`/${guidedTourTemplateId}`),
      });
      const guidedTour = GuidedTourTemplateCRUD.mapGuidedTourFrom(response);
      responseHandlers.onSuccess(guidedTour);
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new GuidedTourTemplateCRUD();
