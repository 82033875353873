import React from 'react';
import { func } from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';

import Texts from './texts';

const validationSchema = yup.object({
  email: yup.string().email(Texts.emailError).required(Texts.emailRequired),
});

function ResetPasswordForm({ onCancel, onSubmit }) {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
      }) => {
        return (
          <Form noValidate>
            <Form.Group>
              <Form.Label htmlFor="email">{Texts.emailLabel}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                disabled={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (touched.email || touched.atLeastOne) &&
                  (errors.email || errors.atLeastOne)
                }
              />
              {touched.email && errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {!isSubmitting && errors.invalidCredentials && (
              <SubmitErrorMessage>
                {errors.invalidCredentials}
              </SubmitErrorMessage>
            )}
            {!isSubmitting && errors.internalServerError && (
              <SubmitErrorMessage>
                {errors.internalServerError}
              </SubmitErrorMessage>
            )}
            <AcceptCancelButton
              isProcessing={isSubmitting}
              variant="primary"
              type="submit"
              cancelLabel={Texts.cancelButton}
              onCancel={onCancel}
              onAccept={handleSubmit}
            >
              {Texts.submitButton}
            </AcceptCancelButton>
          </Form>
        );
      }}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

export default ResetPasswordForm;
