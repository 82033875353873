import { changeRGBAOpacity } from 'utils/rgba';

const stylejss = ({ background, color, fontFamily }) => {
  const active = changeRGBAOpacity(background, 0.7);
  const inactive = changeRGBAOpacity(background, 0.5);
  return `
    .steps-input-control .step-option:hover,
    .steps-input-control .step-option.active {
      background-color: ${inactive};
      border-color: ${color};
      color: ${color};
    }

    .steps-input-control .step-list {
      font-family: ${fontFamily};
    }
    .steps-input-control .step-option.active{
      background-color: ${active};
    }

    .steps-input-control .step-number,
    .steps-input-control .add-step-button {
      color: #273444;
    }
    .steps-input-control .icon-option {
      background: ${inactive};
      color: ${color};
    }
  }
`;
};

export default stylejss;
