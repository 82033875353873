/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { any, func, string } from 'prop-types';
import cx from 'classnames';
import './styles.scss';

const NavItem = ({ activeNav, icon: Icon, value, onClick, label }) => {
  // uses includes since this nav items are for root level nav
  const isActive = activeNav === value;
  const handleClick = () => {
    onClick(value);
  };

  return (
    <div
      onClick={handleClick}
      className={cx(
        'sidebar-option my-2 d-flex align-items-center',
        isActive && 'active',
      )}
    >
      <div>
        <Icon className="icon" />
      </div>
      <div className="ml-3 label">{label}</div>
    </div>
  );
};

NavItem.propTypes = {
  activeNav: string.isRequired,
  icon: any.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
  value: string.isRequired,
};

export default NavItem;
