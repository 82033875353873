import React from 'react';
import { string } from 'prop-types';
import NumberFormat from 'react-number-format';

function PercentageFormatter({ value, prefix, suffix }) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      decimalScale="2"
      prefix={prefix}
      suffix={suffix}
    />
  );
}

PercentageFormatter.propTypes = {
  prefix: string,
  suffix: string,
  value: string.isRequired,
};

PercentageFormatter.defaultProps = {
  prefix: ' (',
  suffix: '%)',
};

export default PercentageFormatter;
