import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import embeddablesRouterMap from 'app/Embeddables/EmbeddablesRouterMap';
import useQueryString from 'utils/useQueryString';
import LoadingSpinner from 'common/LoadingSpinner';
import useInitEmbed from './useInitEmbed';
import { EVENTS, messageParent } from '../MessagePublisher';

function InitEmbed() {
  const history = useHistory();
  const query = useQueryString();
  const appId = query.get('app_id');

  const clientId = query.get('client_id');
  const clientName = query.get('client_name');
  const client = { external_id: clientId, name: clientName };

  const initEmbed = useInitEmbed(appId, client);

  useEffect(() => {
    if (initEmbed.isReady) {
      history.push(embeddablesRouterMap.demos.getPath(initEmbed.client.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEmbed.isReady]);

  useEffect(() => {
    if (initEmbed.hasError) {
      messageParent({
        message: EVENTS.INIT_ERROR,
        error: initEmbed.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEmbed.hasError]);

  return (
    <div>
      {initEmbed.isLoading && <LoadingSpinner />}
      {initEmbed.hasError && <div>{initEmbed.error.message}</div>}
    </div>
  );
}

export default InitEmbed;
