/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { get } from 'lodash';
import NumberFormat from 'react-number-format';
import ClientsDemosSelect from 'common/Filters/ClientsDemosSelect';
import RetryableErrorMessage from 'common/Message/RetryableError';
import metricsService from 'app/Services/MetricsService';
import useMetricsLoader from 'utils/useMetricsLoader';
import { to_HH_MM_SS } from 'utils/dates';
import VisitsAreaChart from './VisitsAreaChart';
import './styles.scss';

function KPIMetrics() {
  const state = useMetricsLoader(metricsService.kpiMetrics);
  const [clientId, setClientId] = useState(undefined);
  const [demoId, setDemoId] = useState(undefined);

  useEffect(() => {
    state.filter(clientId, demoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, demoId]);

  const onSelect = (client, demo) => {
    setClientId(get(client, 'id'));
    setDemoId(get(demo, 'id'));
  };

  const reload = () => state.filter(clientId, demoId);

  const uniqueVisits = get(state, 'metrics.kpi_metrics.unique_visitors', 0);

  return (
    <div className="card mt-3 mb-3 p-4">
      <div className="d-flex mb-4">
        <ClientsDemosSelect onClientChange={onSelect} onDemoChange={onSelect} />
      </div>
      <div>
        {state.isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <Spinner animation="border" />
          </div>
        )}
        {!state.isLoading && state.error && (
          <RetryableErrorMessage
            message="Something went wrong when fetching the KPI Metrics"
            onReload={reload}
          />
        )}
        {!state.error && (
          <div className="d-flex justify-content-between kpi-metrics">
            <div className="w-50 mt-4">
              <div className="d-flex mb-5">
                <div className="w-50">
                  <div>
                    <span>Visits</span>
                  </div>
                  <div>
                    <span className="metric-value">
                      {get(state, 'metrics.kpi_metrics.visits', 0)}
                    </span>
                  </div>
                </div>
                <div className="w-50">
                  <div>
                    <span>Page Views</span>
                  </div>
                  <div>
                    <span className="metric-value">
                      {get(state, 'metrics.kpi_metrics.pageviews', 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="w-50">
                  <div>
                    <span>Avg. Session Duration</span>
                  </div>
                  <div>
                    <span className="metric-value">
                      {to_HH_MM_SS(
                        get(
                          state,
                          'metrics.kpi_metrics.avg_session_duration',
                          0,
                        ),
                      )}
                    </span>
                  </div>
                </div>
                <div className="w-50">
                  <div>
                    <span>Unique Visits per Demo</span>
                  </div>
                  <div>
                    <span className="metric-value">
                      <NumberFormat
                        defaultValue={0}
                        decimalScale={uniqueVisits % 1 === 0 ? 0 : 2}
                        displayType="text"
                        value={uniqueVisits}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <h5>Visits by week of year</h5>
              <VisitsAreaChart
                data={get(state, 'metrics.visits_per_week', [])}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KPIMetrics;
