import React from 'react';
import BaseSelect from 'react-select/creatable';
import { func } from 'prop-types';

function CreateableSelect({ onChange, ...rest }) {
  const handleChange = (action, type) => {
    onChange(action, type);
  };
  return <BaseSelect {...rest} onChange={handleChange} />;
}

CreateableSelect.propTypes = {
  onChange: func.isRequired,
};

export default CreateableSelect;
