import { node, string } from 'react';

const SwitchCase = ({ caseValue, current, children }) => {
  return caseValue === current && children;
};

SwitchCase.propTypes = {
  caseValue: string,
  children: node,
  current: string,
};

export default SwitchCase;
