import http from 'utils/Http';

import safeInvoke from 'utils/safeInvoke';

const buildUrl = (suffix) => `api/v1/clients${suffix ? `${suffix}` : ''}`;

class ClientsService {
  static mapClientFrom = (serverClient) => {
    return {
      id: serverClient.id,
      name: serverClient.props.name,
      external_id: serverClient.props.external_id,
    };
  };

  fromExternal = async (externalClient, responseHandlers) => {
    try {
      const { data: client } = await http.post({
        url: buildUrl('/external'),
        body: externalClient,
      });
      safeInvoke(
        responseHandlers.onSuccess,
        ClientsService.mapClientFrom(client),
      );
    } catch (error) {
      safeInvoke(responseHandlers.onError, error);
    }
  };
}

export default new ClientsService();
