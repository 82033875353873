import React from 'react';
import metricsService from 'app/Services/MetricsService';
import SummaryCard from '../SummaryCard';
import useCardState from '../SummaryCard/useCardState';

function TopPageViews() {
  const state = useCardState(metricsService.topPageviews);

  return (
    <SummaryCard
      data={state.metrics.map((item) => ({
        id: item.demo_id,
        label: item.demo_name,
        value: item.pageviews,
        percentage: item.percentage,
      }))}
      error={state.error}
      isLoading={state.isLoading}
      onSelect={state.loadMetrics}
      title="Top pageviews"
      withBars
    />
  );
}

export default TopPageViews;
