import moment from 'moment';

export const toMoment = (date) =>
  date ? moment(date) : moment(new Date().toISOString());

export const to_MMDDYYYY = (date) => toMoment(date).format('MM/DD/YYYY');

export const to_MMDDYYYYHHMMA = (date) => toMoment(date).format('MM/DD/YYYY hh:mm A');

export const to_DD_MMMM_YYYY = (date) => toMoment(date).format('DD MMMM, YYYY');

export const monthsAgo = (months) => moment().subtract(months, 'months').toDate();

export const to_YYYY_MM_DD = (date) => toMoment(date).format('YYYY-MM-DD');

export const dates_To_YYYY_MM_DD = (dates) =>
  dates.map((date) => toMoment(date).format('YYYY-MM-DD'));

export const to_HH_MM_SS = (seconds) => {
  const secondsNum = parseInt(seconds || 0, 10);
  const hours = Math.floor(secondsNum / 3600);
  const minutes = Math.floor(secondsNum / 60) % 60;
  const totalSeconds = secondsNum % 60;

  return [hours, minutes, totalSeconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .join(':');
};
