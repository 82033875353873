import React, { useMemo } from 'react';
import { func, arrayOf, shape } from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';

function SimpleSelect({ value, options, onChange, ...rest }) {
  const selectedOption = useMemo(() => {
    if (!value) return undefined;
    return options.find((option) => option.value === value);
  }, [value, options]);

  const handleChange = (change) => {
    onChange(get(change, 'value', change));
  };

  return (
    <Select
      {...rest}
      placeholder="Select..."
      options={options}
      value={selectedOption}
      onChange={handleChange}
    />
  );
}

SimpleSelect.propTypes = {
  onChange: func.isRequired,
  options: arrayOf(shape({})),
  value: shape({}),
};

SimpleSelect.defaultProps = {
  value: undefined,
  options: [],
};

export default SimpleSelect;
