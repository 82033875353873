/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { BlockPicker as BasePicker } from 'react-color';
import { func, node, string } from 'prop-types';
import PrimitiveButton from 'common/Buttons/Primitive';
import { ChevronDown } from 'react-bootstrap-icons';

import { BASE_WIDTH, BASE_COLORS } from './constants';

import './styles.scss';

const popover = {
  position: 'absolute',
  zIndex: '2',
};

function Colorpicker({ onChange, value, trigger }) {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (change) => {
    onChange(change.hex);
    handleClose();
  };

  return (
    <div className="d-inline-block">
      <PrimitiveButton className="color-picker-input p-1" onClick={handleClick}>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {trigger}
            <ChevronDown className="picker-icon" />
          </div>
          <div
            className="color-picker-display mt-1"
            style={{ backgroundColor: value }}
          />
        </div>
      </PrimitiveButton>
      {show ? (
        <div style={popover}>
          {/* <PrimitiveButton style={cover} onClick={handleClose} /> */}
          <BasePicker
            colors={BASE_COLORS}
            onChangeComplete={handleChange}
            color={value}
            width={BASE_WIDTH}
            triangle="hide"
          />
        </div>
      ) : null}
    </div>
  );
}

Colorpicker.propTypes = {
  onChange: func.isRequired,
  trigger: node.isRequired,
  value: string.isRequired,
};

export default Colorpicker;
