import { useState, useEffect } from 'react';

import useMutation from 'utils/useMutation';
import useToggleState from 'utils/useToggleState';
import AuthenticateApp from 'app/Services/AuthenticateAppService';

const APP_ID_LENGTH = 32;

function useAuthApp(appId) {
  const authenticated = useToggleState();
  const [error, setError] = useState({
    hasError: false,
    message: undefined,
  });

  const handleError = (message) => {
    authenticated.turnOff();
    setError({
      hasError: true,
      message,
    });
  };

  const authenticateMutation = useMutation(AuthenticateApp, {
    onSuccess: () => authenticated.turnOn(),
    onError: () => {
      authenticated.turnOff();
      handleError('Error authorizing app');
    },
  });

  useEffect(() => {
    if (!appId) {
      handleError('app_id must be provided');
      return;
    }

    if (appId && appId.length != APP_ID_LENGTH) {
      handleError('A vallid app_id must be provided');
      return;
    }

    authenticateMutation.mutate(appId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  return {
    authenticated: authenticated.isActive,
    error,
  };
}

export default useAuthApp;
