import React from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import routerMap from 'app/RouterMap';
import DemoWizard from 'app/Forms/DemoWizard';
import useModalState from 'utils/useModalState';
import session from 'utils/Session';
import Texts from './texts';
import './styles.scss';
import useHeaderState from './useHeaderState';

function Header() {
  const history = useHistory();
  const demoModal = useModalState();
  const headerState = useHeaderState({
    listeners: {
      onSave: (createdDemo) => {
        history.push(routerMap.demo.getPath(createdDemo.id));
      },
      onSaveError: demoModal.close,
    },
  });
  const username = session.getUsername();

  return (
    <div>
      <DemoWizard
        onCancel={demoModal.close}
        onSubmit={headerState.addDemo}
        show={demoModal.show}
        demo={demoModal.value}
      />
      <Alert className="d-flex justify-content-between dashboard-header">
        <div className="mr-auto align-self-center">
          <span className="dashboard-username">
            {Texts.usernameLabel(username)}
          </span>
        </div>
        <div className="mr-4 align-self-center">
          <div className="total-demos-number">{headerState.totalDemos}</div>
          <div className="total-demos-title">{Texts.totalDemosLabel}</div>
        </div>
        <div className="ml-4 align-self-center">
          <Button size="md" onClick={demoModal.open}>
            {Texts.addDemoLabel}
          </Button>
        </div>
      </Alert>
    </div>
  );
}

export default Header;
