import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import routerMap from 'app/RouterMap';
import Http404 from 'app/Views/Http404';
import session from 'utils/Session';

function NotFound({ ...rest }) {
  const token = session.get('token');
  const isAuthenticated = token;

  return isAuthenticated ? (
    <Route {...rest} component={Http404} />
  ) : (
    <Redirect to={routerMap.dash.getPath()} />
  );
}

export default NotFound;
