import React from 'react';
import { Formik } from 'formik';
import { bool, func, shape } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import Texts from './texts';
import './styles.scss';

const validationSchema = yup.object({
  username: yup.string().required(Texts.requiredError(Texts.usernameLabel)),
  email: yup
    .string()
    .required(Texts.requiredError(Texts.emailLabel))
    .email(Texts.invalidEmail),
  role: yup.string().required(Texts.requiredError(Texts.roleLabel)),
});

function UserForm({ show, onCancel, onSubmit, user }) {
  return (
    <Modal size="sm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {user.id ? Texts.editTitle : Texts.createTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={user}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          isSubmitting,
          values,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="username">
                        {Texts.usernameLabel}
                      </Form.Label>
                      <Form.Control
                        name="username"
                        type="text"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.username && errors.username}
                      />
                      {touched.username && errors.username && (
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="email">
                        {Texts.emailLabel}
                      </Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && errors.email}
                      />
                      {touched.email && errors.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="role">{Texts.roleLabel}</Form.Label>
                      <Form.Control
                        as="select"
                        name="role"
                        type="role"
                        value={values.role}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={user.id ? Texts.editLabel : Texts.acceptLabel}
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

UserForm.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
  user: shape({}),
};

UserForm.defaultProps = {
  show: false,
  user: {
    id: null,
    username: '',
    email: '',
    role: 'user',
  },
};

export default UserForm;
