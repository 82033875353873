import React, { useState } from 'react';
import CreateableSelect from 'common/Inputs/CreateableSelect';
import Button from 'react-bootstrap/Button';
import { DashCircleFill, Pencil } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { func, shape, string, arrayOf } from 'prop-types';
import PrimitiveButton from 'common/Buttons/Primitive';
import useToggleState from 'utils/useToggleState';
import Texts from './texts';
import './styles.scss';

function FlowInput({
  current,
  flowOptions,
  onDeleteFlow,
  onEditFlow,
  onSelectedFlow,
}) {
  const [flowNameInput, setFlowName] = useState();
  const toggleEditFlow = useToggleState();
  const { name: flowName } = current;

  const handleEditClick = () => {
    setFlowName(flowName);
    toggleEditFlow.turnOn();
  };

  const handleFlowInputChange = (e) => {
    setFlowName(e.target.value);
  };

  const handleSaveName = () => {
    onEditFlow(flowNameInput);
    toggleEditFlow.turnOff();
  };

  return (
    <div className="flow-input p-2">
      <Form.Group className="mb-1">
        <div className="d-flex justify-content-between mb-2">
          {toggleEditFlow.isActive ? (
            <Form.Control
              type="text"
              value={flowNameInput}
              onChange={handleFlowInputChange}
            />
          ) : (
            <Form.Label className="mr-2">
              {Texts.activeFlowLabel} {flowName || ''}
            </Form.Label>
          )}
          {toggleEditFlow.isActive && (
            <Button className="ml-1" onClick={handleSaveName}>
              Save
            </Button>
          )}
          {flowName && !toggleEditFlow.isActive && (
            <div className="d-flex buttons-wrapper">
              <PrimitiveButton onClick={handleEditClick}>
                <Pencil className="text-primary flow-button mr-1" />
              </PrimitiveButton>
              {flowOptions.length > 1 && (
                <PrimitiveButton onClick={onDeleteFlow}>
                  <DashCircleFill className="text-primary flow-button" />
                </PrimitiveButton>
              )}
            </div>
          )}
        </div>
        {!toggleEditFlow.isActive && (
          <CreateableSelect
            placeholder={
              current ? Texts.selectPlaceHolder : Texts.emptySelectPlaceHolder
            }
            options={flowOptions}
            value={current ? { label: current.name, value: current } : null}
            onChange={onSelectedFlow}
          />
        )}
      </Form.Group>
    </div>
  );
}

FlowInput.propTypes = {
  current: shape({ name: string }).isRequired,
  flowOptions: arrayOf({ label: string, value: string }),
  onDeleteFlow: func.isRequired,
  onEditFlow: func.isRequired,
  onSelectedFlow: func.isRequired,
};

FlowInput.defaultProps = {
  flowOptions: [],
};

export default FlowInput;
