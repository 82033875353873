const Texts = {
  sourcesTitle: 'Third-Party Sources',
  marvelAppTitle: 'Marvel App',
  syncLabel: 'Sync',
  syncInProgressLabel: 'Sync in progress...',
  successfulSync: (source) => `Successful sync of ${source}.`,
  errorSync: (source) => `Could not sync ${source}.`,
};

export default Texts;
