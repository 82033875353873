import React from 'react';
import { bool, shape, func } from 'prop-types';
import { Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import TextEditor from 'common/Editors/TextEditor';
import Texts from './texts';

const INITIAL = {
  intro: '',
  conclusion: '',
};

function IntroForm({ value, show, onSuccess, onCancel, isConclusion }) {
  const propName = isConclusion ? 'conclusion' : 'intro';
  const handleOnSubmit = (form) => {
    onSuccess(form);
    onCancel();
  };

  return (
    <Modal show={show} onHide={onCancel} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {isConclusion ? Texts.conclusion : Texts.intro}
        </Modal.Title>
      </Modal.Header>
      <Formik initialValues={value || INITIAL} onSubmit={handleOnSubmit}>
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <TextEditor
                      name={propName}
                      initialValue={values[propName]}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={false}
                  acceptLabel={Texts.editLabel}
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

IntroForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isConclusion: bool,
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
  show: bool,
  // eslint-disable-next-line react/require-default-props
  value: shape({}),
};

IntroForm.defaultProps = {
  show: false,
};

export default IntroForm;
