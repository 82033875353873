/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { BoxArrowRight } from 'react-bootstrap-icons';

import Button from 'common/Buttons/Button';
import useQueryString from 'utils/useQueryString';
import Texts from './texts';
import './styles.scss';

import { messageParent } from '../MessagePublisher';

function PublishedDemoDetailsEmbeddable() {
  const query = useQueryString();
  const demoName = query.get('demo_name');
  const demoUniqueUrl = query.get('unique_url');
  const clientName = query.get('client_name');

  // Only for dev purposes
  const postDevMessage = () => {
    messageParent({
      message: 'DEV_MESSAGE',
      data: { demo: demoName, demo_unique_url: demoUniqueUrl },
    });
  };

  return (
    <div className="p-4" id="published-demo-details">
      <div>
        <div>
          <h5>{Texts.publishedDemoLabel}</h5>
        </div>
        <div className="mb-2 d-flex">
          <div className="w-25">{Texts.clientLabel}</div>
          <div>{clientName}</div>
        </div>
        <div className="mb-2 d-flex">
          <div className="w-25">{Texts.demoNameLabel}</div>
          <div>{demoName}</div>
        </div>
        <div className="mb-2 d-flex">
          <div className="w-25">{Texts.guidedLabel}</div>
          <div>
            <a href={demoUniqueUrl} target="_blank" rel="noopener noreferrer">
              {demoUniqueUrl}
            </a>
            <BoxArrowRight className="ml-2" color="royalblue" />
          </div>
        </div>

        <div className="mb-2 d-flex">
          <div className="w-25" />
          <div>
            <Button variant="primary" className="mr-2" onClick={postDevMessage}>
              Create Activity
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default PublishedDemoDetailsEmbeddable;
