import React, { useEffect, useMemo, useRef, useState } from 'react';
import { func, number, string } from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import uuid from 'utils/uuid';
import ThemeStyle from '../../ThemeStyle';
import { HOTSPOT_EVENTS, PLACEMENT } from '../../constants';
import ResizableDescription from './components/ResizableDescription';
import stylesjss from './stylejss';
import './styles.scss';

function HotspotPreview({
  description,
  descriptionHeight,
  descriptionWidth,
  event,
  footer,
  header,
  height,
  id,
  updateHotspot,
  placement,
  width,
  x,
  y,
}) {
  const [show, setShow] = useState(false);
  const [size, setSize] = useState({
    width: descriptionWidth,
    height: descriptionHeight,
  });
  const targetRef = useRef(null);

  useEffect(() => {
    setShow(true);
  }, []);

  const overlayKey = useMemo(() => {
    return uuid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, height, width, placement, size]);

  const handleSizeUpdate = (newSize) => {
    updateHotspot({
      id,
      descriptionWidth: newSize.width,
      descriptionHeight: newSize.height,
    });
    setSize(newSize);
  };

  return (
    <ThemeStyle style={stylesjss}>
      <div ref={targetRef} style={{ height }} className="hotspot-preview">
        {description && (
          <Overlay
            key={overlayKey}
            show={show}
            target={targetRef.current}
            placement={placement}
            container={targetRef.current}
          >
            <Popover transition style={size}>
              <ResizableDescription
                description={description}
                event={event}
                footer={footer}
                header={header}
                placement={placement}
                size={size}
                updateSize={handleSizeUpdate}
              />
            </Popover>
          </Overlay>
        )}
      </div>
    </ThemeStyle>
  );
}

HotspotPreview.propTypes = {
  description: string,
  descriptionHeight: number,
  descriptionWidth: number,
  event: string,
  footer: string,
  header: string,
  height: string,
  id: string.isRequired,
  placement: string,
  updateHotspot: func.isRequired,
  width: string,
  x: string,
  y: string,
};

HotspotPreview.defaultProps = {
  description: null,
  descriptionHeight: undefined,
  descriptionWidth: undefined,
  event: HOTSPOT_EVENTS.CLICK,
  footer: null,
  header: null,
  height: '0%',
  placement: PLACEMENT.TOP,
  width: '0%',
  x: '0',
  y: '0',
};

export default HotspotPreview;
