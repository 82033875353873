/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import uuid from 'utils/uuid';
import getTourService from './getTourService';
import TourMap from './TourMap';
import toFlowOptions from './toFlowOption';

const forceUpdate = (data) => ({
  ...data,
  version: Math.random(),
});

function useTourState(referenceValues, listeners) {
  const [reference] = useState({
    demoId: referenceValues.demoId,
    tourId: referenceValues.tourId,
    type: referenceValues.type,
    productType: referenceValues.productType,
  });
  const [tourMap, setTourMap] = useState(() => TourMap.toTourMap());
  const [flowOptions, setFlowOptions] = useState([]);
  const tourService = getTourService(referenceValues.type);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    tourService.byId(referenceValues.tourId, {
      onSuccess: (res) => {
        if (res.tour !== null) {
          const serverTour = res.tour;
          const newTourMap = TourMap.toTourMap(serverTour);
          setTourMap(newTourMap);
        }
      },
      onError: () => {
        listeners.onSaveFailure();
      },
    });
  }, []);

  useEffect(() => {
    const newOptions = toFlowOptions(tourMap);
    setFlowOptions(newOptions);
  }, [tourMap]);

  const onSave = () => {
    setIsSaving(true);
    const serverTour = {
      meta: {},
      demoId: reference.demoId,
      parentTemplateId: null,
      flowIds: tourMap.getFlowIds(),
      flows: tourMap.getFlows(),
      steps: tourMap.getSteps(),
      hotspots: tourMap.getHotspots(),
      format: tourMap.getFormat(),
      version: uuid(),
    };
    tourService.debouncedEdit(
      { tourId: reference.tourId, tour: serverTour },
      {
        onSuccess: () => {
          setIsSaving(false);
          listeners.onSave();
        },
        onError: () => {
          setIsSaving(false);
          listeners.onSaveFailure();
        },
      },
    );
  };

  const updateTour = (updatedTourMap) => {
    setTourMap(forceUpdate(updatedTourMap));
    onSave();
  };

  const changeHead = (newHead) => {
    tourMap.changeHead(newHead);
    setTourMap(tourMap);
  };

  const changeHeadByStep = (step) => {
    tourMap.changeHeadByStep(step);
    setTourMap({ ...tourMap });
  };

  const changeFormat = (format) => {
    tourMap.updateFormat(format);
    updateTour(tourMap);
  };

  // move this to sidebar component
  const changeFlow = (flow, meta) => {
    const { action } = meta || {};

    if (action === 'select-option') {
      tourMap.changeHeadByFlow(flow.value);
    } else {
      const isCreate = flow.__isNew__;
      if (isCreate) {
        tourMap.addFlow({ ...flow, name: flow.value });
      } else {
        tourMap.updateFlow(flow);
      }
    }
    updateTour(tourMap);
  };

  const editCurrentFlowName = (name) => {
    const currentFlow = tourMap.getCurrentFlow();
    const updatedFlow = {
      ...currentFlow,
      name,
    };
    tourMap.updateFlow(updatedFlow);
    updateTour(tourMap);
  };

  const deleteCurrentFlow = () => {
    const currentFlow = tourMap.getCurrentFlow();
    tourMap.deleteFlow(currentFlow);
    updateTour(tourMap);
  };

  const toggleCurrentFlowArrowNavigation = () => {
    const currentFlow = tourMap.getCurrentFlow();
    const updatedFlow = {
      ...currentFlow,
      arrowNavigation: !currentFlow.arrowNavigation,
    };
    tourMap.updateFlow(updatedFlow);
    updateTour(tourMap);
  };

  const changeStep = (stepForm) => {
    const { id: stepId } = stepForm;
    const isCreate = !stepId;
    if (isCreate) {
      tourMap.addStep(stepForm);
    } else {
      tourMap.updateStep(stepForm);
    }
    updateTour(tourMap);
  };

  const deleteStep = (step) => {
    tourMap.deleteStep(step);
    updateTour(tourMap);
  };

  const changeHotspotData = (hotspot) => {
    tourMap.updateHotspotData(hotspot);
    updateTour(tourMap);
  };

  const changeHotspot = (hotspot) => {
    const isCreate = !hotspot.id;
    if (isCreate) {
      tourMap.addHotspot(hotspot);
    } else {
      tourMap.updateHotspot(hotspot);
    }
    updateTour(tourMap);
  };

  const rearrangeStep = (stepId, sourceIndex, newIndex) => {
    tourMap.rearrangeStep(stepId, sourceIndex, newIndex);
    updateTour(tourMap);
  };

  const mergeHotspot = (hotspot) => {
    tourMap.mergeHotspot(hotspot);
    updateTour(tourMap);
  };

  return {
    tour: tourMap,
    isEmpty: !tourMap.getHead().flowId,
    flowOptions,
    changeFlow,
    editCurrentFlowName,
    deleteCurrentFlow,
    toggleCurrentFlowArrowNavigation,
    changeStep,
    deleteStep,
    changeHead,
    changeHeadByStep,
    changeHotspot,
    changeHotspotData,
    changeFormat,
    onSave,
    rearrangeStep,
    mergeHotspot,
    isSaving,
  };
}

export default useTourState;
