import React from 'react';
import Shell from 'app/Shells/Management';
import TopPageViews from './components/TopPageViews';
import TopPageViewsPerSession from './components/TopPageViewsPerSession';
import TopShares from './components/TopShares';
import KPIMetrics from './components/KPIMetrics';

import Header from './components/Header';

function Dashboard() {
  return (
    <Shell page="Dashboard">
      <div id="dashboard">
        <Header />
        <div className="d-flex justify-content-between">
          <TopPageViews />
          <TopPageViewsPerSession />
          <TopShares />
        </div>
        <KPIMetrics />
      </div>
    </Shell>
  );
}

export default Dashboard;
