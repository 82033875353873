import React, { useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { get } from 'lodash';
import ScreenSearch from 'app/Views/ScreenSearch';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import Texts from './texts';
import './styles.scss';

function StepForm({ value, show, screenOptions, onSuccess, onCancel }) {
  const [imageUrl, setImage] = useState(get(value, 'imageUrl', null));

  const handleSelectedImage = (image) => {
    setImage(image.imageUrl);
  };

  const close = () => {
    onCancel();
    setTimeout(() => setImage(null), 250);
  };

  const handleSubmit = () => {
    const submittedForm = { imageUrl };
    onSuccess(submittedForm);
    close();
  };

  return (
    <Modal show={show} onHide={close} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{Texts.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-2">
          <Form.Label htmlFor="description">{Texts.screenLabel}</Form.Label>
          <ScreenSearch
            elementClassName="selection-screen-list"
            onSelected={handleSelectedImage}
            screens={screenOptions}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AcceptCancelButton
          disabled={!imageUrl}
          acceptLabel={Texts.editLabel}
          onCancel={close}
          onAccept={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

StepForm.propTypes = {
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
  screenOptions: arrayOf(shape({})).isRequired,
  show: bool,
  value: shape({}),
};

StepForm.defaultProps = {
  show: false,
  value: {
    description: null,
  },
};

export default StepForm;
