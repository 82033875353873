/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { get } from 'lodash';
import { Formik } from 'formik';
import { func, shape } from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';

import filtersService from 'app/Services/FiltersService';
import SubmitErrorMessage from 'common/Message/SubmitError';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import MultiCreatableSelect from 'common/Inputs/MultiCreatableSelect';
import useFilterLoader from 'utils/useFilterLoader';
import Texts from './texts';

const validationSchema = yup.object({
  name: yup.string().required(Texts.requiredError(Texts.demoNameLabel)),
  script_url: yup
    .string()
    .url(Texts.invalidUrl)
    .required(Texts.requiredError(Texts.demoScriptUrlLabel)),
  click_demo_url: yup
    .string()
    .url(Texts.invalidUrl)
    .required(Texts.requiredError(Texts.clickDemoUrlLabel)),
});

function DemoDetails({ demoValues, onCancel, onSubmit }) {
  const tagOptionsLoader = useFilterLoader(true, filtersService.tags);
  const tagOptions = get(tagOptionsLoader, 'options', []);

  return (
    <div>
      <Formik
        initialValues={demoValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          isSubmitting,
          values,
          setFieldValue,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="name">
                        {Texts.demoNameLabel}
                      </Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.name && errors.name}
                      />
                      {touched.name && errors.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="script_url">
                        {Texts.demoScriptUrlLabel}
                      </Form.Label>
                      <Form.Control
                        name="script_url"
                        type="text"
                        value={values.script_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.script_url}
                      />
                      {errors.script_url && (
                        <Form.Control.Feedback type="invalid">
                          {errors.script_url}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="click_demo_url">
                        {Texts.clickDemoUrlLabel}
                      </Form.Label>
                      <Form.Control
                        name="click_demo_url"
                        type="text"
                        value={values.click_demo_url}
                        isInvalid={errors.click_demo_url}
                        readOnly
                      />
                      {errors.click_demo_url && (
                        <Form.Control.Feedback type="invalid">
                          {errors.click_demo_url}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="tags">{Texts.tagsLabel}</Form.Label>
                      <MultiCreatableSelect
                        allowMultipleOptions
                        labelField="name"
                        name="tags"
                        options={tagOptions}
                        setFieldValue={setFieldValue}
                        value={values.tags}
                        valueField="id"
                      />
                      {touched.tags && errors.tags && (
                        <Form.Control.Feedback type="invalid">
                          {errors.tags}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={
                    demoValues.id ? Texts.editLabel : Texts.acceptLabel
                  }
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

DemoDetails.propTypes = {
  demoValues: shape({}).isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

export default DemoDetails;
