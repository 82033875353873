import React from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import Select from 'react-select/async';
import './styles.scss';

const styles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderRadius: 'none',
      backgroundColor: state.isFocused
        ? 'rgb(241, 243, 244)'
        : 'rgb(248, 249, 250)',
      ':hover': {
        backgroundColor: 'rgb(241, 243, 244)',
      },
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
};

function AsyncSelect({
  label,
  defaultOptions,
  loadOptions,
  onChange,
  ...rest
}) {
  return (
    <div>
      {label && <small className="small-label">{label}</small>}
      <Select
        {...rest}
        styles={styles}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={onChange}
      />
    </div>
  );
}

AsyncSelect.propTypes = {
  defaultOptions: arrayOf(shape({})),
  label: string,
  loadOptions: func.isRequired,
  onChange: func.isRequired,
};

AsyncSelect.defaultProps = {
  label: undefined,
  defaultOptions: [],
};

export default AsyncSelect;
