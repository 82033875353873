const Texts = {
  usernameLabel: 'Username',
  emailLabel: 'Email',
  roleLabel: 'Role',
  acceptLabel: 'Add User',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  editTitle: 'Edit User',
  createTitle: 'Add New User',
  createUserTrigger: 'Add User',
  requiredError: (name) => `${name} is required`,
  invalidEmail: 'Invalid email',
};

export default Texts;
