import React from 'react';
import { bool, string, func } from 'prop-types';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

import Image from 'common/Image';
import cx from 'classnames';

import ScreenType from '../../types/Screen';
import Texts from './texts';
import './styles.scss';

function ScreenCard({ active, className, onClick, value }) {
  const handleNewTab = () => {
    window.open(value.imageUrl);
  };
  const handleClick = () => onClick(value || {});
  return (
    <div className={cx('screen-card mb-2 d-flex align-items-end', className)}>
      <div>
        <div className="d-flex justify-content-between text-left">
          <span className="screen-card-name">{value.name}</span>
          <span className="cursor-pointer open-icon">
            <BoxArrowUpRight onClick={handleNewTab} />
          </span>
        </div>
        <div className="mb-1 ">
          <div
            className={cx(
              'screen-card-menu-container px-3 d-flex justify-content-center align-items-center rounded',
              active && 'active',
            )}
          >
            {active ? (
              <Button
                variant="info"
                onClick={handleClick}
                className="card-menu-button"
                size="sm"
              >
                {Texts.clear}
              </Button>
            ) : (
              <Button
                className="card-menu-button"
                size="sm"
                onClick={handleClick}
              >
                {Texts.select}
              </Button>
            )}
          </div>
          <Image
            className="screen-image-container img-thumbnail"
            src={value.imageUrl}
          />
        </div>
      </div>
    </div>
  );
}

ScreenCard.propTypes = {
  active: bool,
  className: string,
  onClick: func.isRequired,
  value: ScreenType.isRequired,
};

ScreenCard.defaultProps = {
  active: null,
  className: null,
};

export default ScreenCard;
