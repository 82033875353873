import { useState, useMemo } from 'react';

function useMultipleModalState(statusList) {
  const [modal, setModal] = useState({
    status: null,
    meta: null,
  });

  const openHandlers = useMemo(() => {
    const handlers = statusList.reduce((accum, status) => {
      // eslint-disable-next-line no-param-reassign
      accum[status] = (value) => {
        setModal({
          status,
          meta: value,
        });
      };
      return accum;
    }, {});

    return handlers;
  }, [statusList]);

  const closeModal = () => {
    setModal({ value: null, meta: null });
  };

  return {
    ...modal,
    closeModal,
    open: openHandlers,
  };
}

export default useMultipleModalState;
