const texts = {
  title: 'Edit Guide',
  guideLabel: 'Message',
  header: 'Header',
  footer: 'Footer',
  placementLabel: 'Placement',
  eventLabel: 'Type',
};

export default texts;
