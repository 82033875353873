import React, { useEffect, useState, useCallback } from 'react';
import { number, shape, string } from 'prop-types';
import { Text } from 'recharts';

function EllipsisAxisTick({ maxLines, payload: { value }, ...rest }) {
  const [text, setText] = useState(value);
  const [suffix, setSuffix] = useState('');

  useEffect(() => {
    setText(value);
  }, [value]);

  const measuredRef = useCallback(
    (node) => {
      if (node === null) {
        return;
      }
      let { wordsByLines } = node.state;
      let biggestLine = wordsByLines.reduce((a, b) =>
        a.width > b.width ? a : b,
      );
      let tempText = text;
      const tempSuffix =
        wordsByLines.length > maxLines || biggestLine.width > rest.width
          ? '…'
          : '';

      while (wordsByLines.length > maxLines || biggestLine.width > rest.width) {
        tempText = tempText.slice(0, -1);
        wordsByLines = node.getWordsByLines(
          {
            ...rest,
            children: tempText + tempSuffix,
          },
          true,
        );
        biggestLine = wordsByLines.reduce((a, b) =>
          a.width > b.width ? a : b,
        );
      }

      if (tempText !== text) {
        setText(tempText);
        setSuffix(tempSuffix);
      }
    },
    [maxLines, rest, text],
  );

  return (
    <g>
      <Text {...rest} ref={measuredRef}>
        {text + suffix}
      </Text>
      <title>{value}</title>
    </g>
  );
}

EllipsisAxisTick.propTypes = {
  maxLines: number,
  payload: shape({
    value: string,
  }),
};

EllipsisAxisTick.defaultProps = {
  maxLines: 2,
  payload: {},
};

export default EllipsisAxisTick;
