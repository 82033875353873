import http from 'utils/Http';

const buildUrl = (id, suffix) =>
  `api/v1/demos/${id}/editors${suffix ? `${suffix}` : ''}`;
class DemoEditorService {
  static mapEditorFrom = (serverEditor) => {
    const { username, email, id: userId } = serverEditor.userEditor;
    const editor = {
      id: serverEditor.id,
      username,
      email,
      editorId: userId,
    };
    return editor;
  };

  static mapListFrom = (editors = []) => {
    return editors.map(DemoEditorService.mapEditorFrom);
  };

  static mapAvailableUsersListFrom = (users = []) => {
    return users.map((option) => {
      return {
        label: option.username,
        value: option.id,
      };
    });
  };

  byDemoId = (demoId) => async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(demoId, query),
      });
      const mappedEditors = response.data.map(DemoEditorService.mapEditorFrom);
      responseHandlers.onSuccess({
        ...response,
        data: mappedEditors,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  getAvailableEditorsForDemo = (demoId) => async (query) => {
    try {
      const { data: response } = await http.get({
        url: buildUrl(demoId, `/available${query}`),
      });
      const mappedUsers = DemoEditorService.mapAvailableUsersListFrom(
        response.data,
      );
      return mappedUsers;
    } catch (error) {
      return error;
    }
  };

  add = async ({ demoId, editorsIds }, responseHandlers) => {
    try {
      await http.post({
        url: buildUrl(demoId),
        body: {
          editorsIds,
        },
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  remove = async (id, responseHandlers) => {
    try {
      await http.delete({
        url: `api/v1/editors/${id}`,
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new DemoEditorService();
