import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function VisitsAreaChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="0.5 0.5" />
        <Legend />
        <XAxis dataKey="week_number" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="visits"
          stroke="#54d3a2"
          fill="#cbf2e3"
          name="Visits per calendar week"
        />
        <Legend />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default VisitsAreaChart;
