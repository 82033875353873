/* eslint-disable camelcase */
import React from 'react';
import { number, string } from 'prop-types';
import Image from 'react-bootstrap/Image';
import { Link as LinkIcon } from 'react-bootstrap-icons';
import { to_HH_MM_SS } from 'utils/dates';
import useDemoStatsState from './useDemoStatsState';
import './styles.scss';

function DemoStats({ demoId, imageUrl, link }) {
  const demoStatsState = useDemoStatsState(demoId);

  return (
    <div className="d-flex justify-content-between demo-stats align-items-center">
      <div className="d-flex align-items-center">
        <div className="stats-image-wrapper img-thumbnail mr-3">
          <Image src={imageUrl} className="demo-image" />
        </div>
        <LinkIcon className="icon mr-3" size={20} />
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </div>
      <div className="d-flex">
        <Stat value={demoStatsState.metrics.shares || 0} label="Shares" />
        <Stat value={demoStatsState.metrics.visits || 0} label="Visits" />
        <Stat
          value={demoStatsState.metrics.pageviews || 0}
          label="Page Views"
        />
        <Stat
          value={to_HH_MM_SS(demoStatsState.metrics.avg_session_duration)}
          label="Avg. Session Duration"
        />
        <Stat
          value={demoStatsState.metrics.unique_visitors || 0}
          label="Unique Visitors"
        />
      </div>
    </div>
  );
}

function Stat({ value, label }) {
  return (
    <div className="d-flex flex-column align-items-center ml-4">
      <h2>{value}</h2>
      <span className="stat-label">{label}</span>
    </div>
  );
}

DemoStats.propTypes = {
  demoId: number.isRequired,
  imageUrl: string.isRequired,
  link: string.isRequired,
};

Stat.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

export default DemoStats;
