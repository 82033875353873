import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import reportsService from 'app/Services/ReportsService';
import useReportsState from './useReportsState';

function useClientsTopPageviews(clientId, demoId, startDate, endDate) {
  const clientsTopPageviews = useReportsState(
    reportsService.topClientsPageviews,
  );

  const shouldShowByFilters = useMemo(() => {
    const noFilter = !clientId && !demoId;
    const filteringByDemo = !clientId && !!demoId;

    return noFilter || filteringByDemo;
  }, [clientId, demoId]);

  const shouldShowByData = useMemo(() => {
    return !isEmpty(clientsTopPageviews.metrics);
  }, [clientsTopPageviews.metrics]);

  useEffect(() => {
    if (shouldShowByFilters) {
      clientsTopPageviews.filter(clientId, demoId, startDate, endDate);
    }
  }, [shouldShowByFilters, clientId, demoId, startDate, endDate]);

  const load = () => {
    clientsTopPageviews.load(clientId, demoId, startDate, endDate);
  };

  return {
    data: clientsTopPageviews.metrics,
    isLoading: clientsTopPageviews.isLoading,
    error: clientsTopPageviews.error,
    show: shouldShowByFilters && shouldShowByData,
    load,
  };
}

export default useClientsTopPageviews;
