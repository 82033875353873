export const add = (collection, item) => {
  const updatedCollection = (collection || []).slice();
  updatedCollection.push(item);
  return updatedCollection;
};

export const removeByIndex = (collection, index) => {
  const updatedCollection = collection.filter((item, itemIndex) => {
    return index !== itemIndex;
  });
  return updatedCollection;
};

export const removeByProp = (collection, value, prop) => {
  const updatedCollection = collection.filter((item) => {
    return item[prop] !== value;
  });
  return updatedCollection;
};

/**
 * Moves item of the collection from the currentIndex to the newIndex
 *
 * @param {array} collection
 * @param {type} item
 * @param {number} currentIndex
 * @param {number} newIndex
 */
export const move = (collection, item, currentIndex, newIndex) => {
  const updatedCollection = (collection || []).slice();

  updatedCollection.splice(currentIndex, 1);
  updatedCollection.splice(newIndex, 0, item);

  return updatedCollection;
};
