import { useState, useEffect } from 'react';
import { get } from 'lodash';

import useToggleState from 'utils/useToggleState';
import useAuthApp from './useAuthApp';
import useLoadClient from './useLoadClient';

function useInitEmbed(appId, client) {
  const isLoading = useToggleState(true);
  const [error, setError] = useState({
    hasError: false,
    message: undefined,
  });

  const authApp = useAuthApp(appId);
  const clientInit = useLoadClient(client);

  const handleError = (message) => {
    isLoading.turnOff();
    setError({
      hasError: true,
      message,
    });
  };

  const hasError = (init) => get(init, 'error.hasError');

  const errorMessage = (init) => get(init, 'error.message');

  useEffect(() => {
    if (authApp.authenticated) {
      clientInit.loadClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authApp.authenticated]);

  useEffect(() => {
    if (hasError(authApp)) {
      handleError(errorMessage(authApp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authApp.error]);

  useEffect(() => {
    if (clientInit.loaded) {
      isLoading.turnOff();
      setError({ hasError: false, message: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInit.loaded]);

  useEffect(() => {
    if (hasError(clientInit)) {
      handleError(errorMessage(clientInit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInit.error]);

  return {
    isLoading: isLoading.isActive && !error.hasError,
    isReady: !isLoading.isActive && !error.hasError,
    hasError: !isLoading.isActive && error.hasError,
    error,
    client: clientInit.client,
  };
}

export default useInitEmbed;
