import React from 'react';
import { oneOfType, string, node } from 'prop-types';
import Card from 'react-bootstrap/Card';
import logo from 'logo.svg';
import Texts from './texts';
import './styles.scss';

function Guest({ page, description, children }) {
  return (
    <div className="guest-view">
      <div className="guest-bg" />
      <Card className="my-2 guest-frame border-none">
        <Card.Header className="guest-header border-none">
          <Card.Img
            variant="top"
            src={logo}
            className="guest-logo"
            alt="logo"
          />
          <h4 className="title">{Texts.appName}</h4>
        </Card.Header>
        <Card.Body>
          <h4 className="form-title">{page}</h4>
          {description && <p>{description}</p>}
          <div className="mx-1 mt-4">{children}</div>
        </Card.Body>
      </Card>
    </div>
  );
}

Guest.propTypes = {
  children: node.isRequired,
  description: string,
  page: oneOfType([string, node]).isRequired,
};

Guest.defaultProps = {
  description: null,
};

export default Guest;
