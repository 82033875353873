/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Shell from 'app/Shells/Management';
import ClientsDemosSelect from 'common/Filters/ClientsDemosSelect';
import DataTable from 'common/DataTable';
import SortableHeader from 'common/DataTable/SortableHeader';
import RetryableErrorMessage from 'common/Message/RetryableError';
import LoadingSpinner from 'common/LoadingSpinner';
import OverlayLink from 'common/Overlay/OverlayLink';
import { monthsAgo, dates_To_YYYY_MM_DD, to_HH_MM_SS } from 'utils/dates';
import { get } from 'lodash';
import TopBarChart from './components/TopBarChart';
import PageviewsAreaChart from './components/PageviewsAreaChart';
import PercentageFormatter from './components/PercentageFormatter';
import useClientsTopPageviews from './useClientsTopPageviews';
import useDemosTopPageviews from './useDemosTopPageviews';
import usePageviews from './usePageviews';
import usePublishedDemosState from './usePublishedDemosState';
import Texts from './texts';
import './styles.scss';

function ReportsView() {
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [selectedDemo, setSelectedDemo] = useState(undefined);

  const clientId = get(selectedClient, 'id', undefined);
  const demoId = get(selectedDemo, 'id', undefined);

  const minDate = monthsAgo(6);
  const now = new Date();
  const [dates, setDates] = useState([minDate, now]);

  const topClients = useClientsTopPageviews(clientId, demoId, ...dates);
  const topDemos = useDemosTopPageviews(clientId, demoId, ...dates);
  const pageviews = usePageviews(clientId, demoId, ...dates);

  const publishedDemos = usePublishedDemosState(
    clientId,
    demoId,
    ...dates_To_YYYY_MM_DD(dates),
  );

  const onSelect = (client, demo) => {
    setSelectedClient(client);
    setSelectedDemo(demo);
  };

  const onDateChange = (selectedDates) => {
    const range = selectedDates || [minDate, now];
    setDates(range);
  };

  const publishedTotals = get(publishedDemos, 'data.totals', {});

  const handleClientBarClick = ({ client_id, client_name }) => {
    setSelectedClient({ id: client_id, name: client_name });
  };

  const handleDemoBarClick = ({ demo_id, demo_name }) => {
    setSelectedDemo({ id: demo_id, name: demo_name });
  };

  return (
    <Shell page="Reports">
      <div id="reports">
        <div className="d-flex">
          <ClientsDemosSelect
            onClientChange={onSelect}
            onDemoChange={onSelect}
            client={selectedClient}
            demo={selectedDemo}
          />
          <DateRangePicker
            className="date-range-selector"
            onChange={onDateChange}
            value={dates}
            minDate={minDate}
            maxDate={now}
          />
        </div>
        <div className="d-flex justify-content-between mt-4 mb-4">
          {topClients.show && (
            <div className="card w-50 mr-4 p-3">
              {topClients.isLoading && <LoadingSpinner />}
              {!topClients.isLoading && topClients.error && (
                <RetryableErrorMessage
                  message="Something went wrong when fetching the Top Clients"
                  onReload={topClients.load}
                />
              )}
              {!topClients.isLoading && !topClients.error && topClients.show && (
                <div>
                  <div className="d-flex flex-column">
                    <span className="chart-title">{Texts.topClients}</span>
                    <span className="chart-subtitle">{Texts.byViews}</span>
                  </div>
                  <TopBarChart
                    data={topClients.data}
                    barDataKey="total_pageviews"
                    dataKeyY="client_name"
                    onBarClick={handleClientBarClick}
                  />
                </div>
              )}
            </div>
          )}

          {topDemos.show && (
            <div className="card w-50 p-3">
              {topDemos.isLoading && <LoadingSpinner />}
              {!topDemos.isLoading && topDemos.error && (
                <RetryableErrorMessage
                  message="Something went wrong when fetching the Top Demos"
                  onReload={topDemos.load}
                />
              )}
              {!topDemos.isLoading && !topDemos.error && topDemos.show && (
                <div>
                  <div className="d-flex flex-column">
                    <span className="chart-title">{Texts.topDemos}</span>
                    <span className="chart-subtitle">{Texts.byViews}</span>
                  </div>
                  <TopBarChart
                    data={topDemos.data}
                    barDataKey="total_pageviews"
                    dataKeyY="demo_name"
                    onBarClick={handleDemoBarClick}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {pageviews.show && (
          <div className="card pr-5 pt-3 pb-3 mb-4 p-3">
            {pageviews.isLoading && <LoadingSpinner />}
            {!pageviews.isLoading && pageviews.error && (
              <RetryableErrorMessage
                message="Something went wrong when fetching the Pageviews"
                onReload={pageviews.load}
              />
            )}
            {!pageviews.isLoading && !pageviews.error && pageviews.show && (
              <div className="card pr-5 pt-3 pb-3 mb-4 p-3">
                <span className="chart-title">{Texts.pageviews}</span>
                <PageviewsAreaChart data={pageviews.data} />
              </div>
            )}
          </div>
        )}

        <div className="card">
          <DataTable
            onLimitChange={publishedDemos.changeLimit}
            onPageChange={publishedDemos.changePage}
            onReload={publishedDemos.reload}
            total={get(publishedDemos, 'query.result.total', 0)}
            page={publishedDemos.query.page}
            limit={publishedDemos.query.limit}
            isFetching={publishedDemos.query.isLoading}
            hasError={publishedDemos.query.error}
          >
            <thead>
              <tr>
                <th>Page</th>
                <SortableHeader
                  onChange={publishedDemos.changeSort}
                  value="name"
                  current={publishedDemos.query.sort}
                >
                  Demo Name
                </SortableHeader>
                <SortableHeader
                  onChange={publishedDemos.changeSort}
                  value="clientName"
                  current={publishedDemos.query.sort}
                >
                  Client Name
                </SortableHeader>
                <SortableHeader
                  onChange={publishedDemos.changeSort}
                  value="pageviews"
                  current={publishedDemos.query.sort}
                >
                  Pageviews
                </SortableHeader>
                <SortableHeader
                  onChange={publishedDemos.changeSort}
                  value="uniquePageviews"
                  current={publishedDemos.query.sort}
                >
                  Unique Pageviews
                </SortableHeader>
                <SortableHeader
                  onChange={publishedDemos.changeSort}
                  value="avgSession"
                  current={publishedDemos.query.sort}
                >
                  Avg. Time on Page
                </SortableHeader>
              </tr>
            </thead>
            {!publishedDemos.isLoading && (
              <tbody className="published-demos-totals">
                <tr>
                  <td />
                  <td />
                  <td />
                  <td>{get(publishedTotals, 'pageviews', 0)}</td>
                  <td>{get(publishedTotals, 'unique_pageviews', 0)}</td>
                  <td>
                    {to_HH_MM_SS(get(publishedTotals, 'avg_time_on_page', 0))}
                  </td>
                </tr>
                {get(publishedDemos, 'data.publishedDemos', []).map(
                  (demo, index) => (
                    <tr key={demo.id}>
                      <td>
                        {`${index + 1}. `}
                        <OverlayLink
                          displayText={demo.hash_id}
                          url={demo.demo_unique_url}
                        />
                      </td>
                      <td>{demo.name}</td>
                      <td>{demo.client_name}</td>
                      <td>
                        {get(demo, 'pageviews', 0)}
                        <span>
                          <PercentageFormatter
                            value={get(demo, 'pageviews_percentage', 0)}
                          />
                        </span>
                      </td>
                      <td>
                        {get(demo, 'unique_pageviews', 0)}
                        <span>
                          <PercentageFormatter
                            value={get(demo, 'unique_pageviews_percentage', 0)}
                          />
                        </span>
                      </td>
                      <td>{to_HH_MM_SS(get(demo, 'avg_time_on_page', 0))}</td>
                    </tr>
                  ),
                )}
              </tbody>
            )}
          </DataTable>
          {publishedDemos.isLoading && (
            <div className="d-flex justify-content-center align-item-center">
              <div>
                <div className="tmp-loader mb-2">{Texts.fetching}</div>
                <Spinner className="text-primary" animation="border" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Shell>
  );
}

export default ReportsView;
