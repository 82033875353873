import React, { useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import Row from 'react-bootstrap/Row';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import SearchInput from 'common/Inputs/Search';
import { uniform } from 'utils/strings';
import safeInvoke from 'utils/safeInvoke';

import ScreenCard from './components/ScreenCard';

import ScreenType from './types/Screen';
import './styles.scss';

const mapQueryResult = (item, query) => {
  const match = query === '' || uniform(item.name).includes(uniform(query));
  return {
    ...item,
    hide: !match,
  };
};

function ScreenSearch({ screens, onSelected, className, elementClassName }) {
  const [selected, setSelected] = useState({});
  const [state, setState] = useState({
    query: '',
    options: screens,
  });

  const handleSearchChange = (value) => {
    // mapping instead of filtering for to avoid image reloading
    const updatedOptions =
      value != null && value != undefined
        ? state.options.map((item) => mapQueryResult(item, value))
        : state.options;
    setState({
      query: value,
      options: updatedOptions,
    });
  };

  const handleClearSelected = () => {
    safeInvoke(onSelected, {});
    setSelected({});
  };

  const handleSelected = (selectedScreen) => {
    safeInvoke(onSelected, selectedScreen);
    setSelected(selectedScreen);
  };

  return (
    <div className={cx('screen-search', className)}>
      <SearchInput
        size="sm"
        disabled={!isEmpty(selected)}
        placeholder="Search"
        name="screenSearchInput"
        className="screen-search-input"
        onChange={handleSearchChange}
        value={!isEmpty(selected) ? selected.name : state.query}
      />
      <Row
        className={cx(
          isEmpty(selected) &&
            'd-flex justify-content-between mt-3 mx-0 px-2 align-items center screen-list',
          elementClassName,
          !isEmpty(selected) &&
            'd-flex mt-3 mb-2 justify-content-center align-items-center',
        )}
      >
        {!isEmpty(selected) ? (
          <ScreenCard
            className="option-selected"
            active
            value={selected}
            onClick={handleClearSelected}
          />
        ) : (
          state.options.map((item) => (
            <ScreenCard
              className={cx(item.hide && 'hide-card-option')}
              key={item.imageUrl + item.name}
              value={item}
              active={(item.name || '') === selected.name}
              onClick={handleSelected}
            />
          ))
        )}
      </Row>
    </div>
  );
}

ScreenSearch.propTypes = {
  className: string,
  elementClassName: string,
  onSelected: func.isRequired,
  screens: arrayOf(ScreenType),
};

ScreenSearch.defaultProps = {
  className: null,
  elementClassName: null,
  screens: [],
};

export default ScreenSearch;
